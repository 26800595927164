import { useEffect } from "react";
import isAdmin from "../../../../../common/isAdmin";
import supabaseClient from "../../../../../common/supabaseClient";
import { useLessonsStore } from "../../../../../store/lessonsStore";
import useMountedWaitAsyncEffect from "../../../../../hooks/useMountedWaitAsyncEffect";
import supabaseDrawingClient from "../../../../../common/supabaseDrawingClient";

export const getDataFromPayloadDrawingStroke = (obj: any): any => {
  if (obj?.type === "freedraw") {
    return {
      ...obj,
      groupIds: [],
      pressures: [],
    };
  }
  return obj || null;
};

const useAdminControlRealtime = () => {
  const authUser = useLessonsStore((state) => state.authUser);
  const isTeacherLesson = useLessonsStore((state) => state.isTeacherLesson);

  const setIsUserBoardOpen = useLessonsStore((state) => state.setIsUserBoardOpen);
  const userBoardText = useLessonsStore((state) => state.userBoardText);
  // console.log("🚀 ~ userBoardText:", userBoardText);
  const setUserBoardText = useLessonsStore((state) => state.setUserBoardText);
  const adminControlReadingSentenceIndex = useLessonsStore(
    (state) => state.adminControlReadingSentenceIndex,
  );
  const setAdminControlReadingSentenceIndex = useLessonsStore(
    (state) => state.setAdminControlReadingSentenceIndex,
  );
  const setActiveVideoId = useLessonsStore((state) => state.setActiveVideoId);
  const setAdminControlRepeatingIndex = useLessonsStore(
    (state) => state.setAdminControlRepeatingIndex,
  );
  const setStudentWordsToRead = useLessonsStore((state) => state.setStudentWordsToRead);
  const studentWordsToRead = useLessonsStore((state) => state.studentWordsToRead);
  const setShowDrawingBoard = useLessonsStore((state) => state.setShowDrawingBoard);

  const setDrawingStrokes = useLessonsStore((state) => state.setDrawingStrokes);
  const drawingStrokes = useLessonsStore((state) => state.drawingStrokes);
  const drawingStrokesStudent = useLessonsStore((state) => state.drawingStrokes2);
  const setDrawingStrokesStudent = useLessonsStore((state) => state.setDrawingStrokes2);
  const drawingRealtimeElements = useLessonsStore((state) => state.drawingRealtimeElements);
  const setDrawingRealtimeElements = useLessonsStore((state) => state.setDrawingRealtimeElements);
  const drawingClearId = useLessonsStore((state) => state.drawingClearId);
  const setDrawingClearId = useLessonsStore((state) => state.setDrawingClearId);
  const excalidrawAPI = useLessonsStore((state) => state.excalidrawAPI);
  const drawingStudentForceUpdateId = useLessonsStore((state) => state.drawingStudentUpdateId);
  // console.log("🚀 ~ drawingStudentForceUpdateId:", drawingStudentForceUpdateId);
  const setDrawingStudentForceUpdateId = useLessonsStore(
    (state) => state.setDrawingStudentUpdateId,
  );

  const updateAdminControl = async () => {
    if (!authUser || isAdmin(authUser.id)) return;

    const { data } = await supabaseClient
      .from("admin_control")
      .select("*, user(*)")
      .eq("id", 0)
      .eq("user", authUser.id)
      .maybeSingle();
    console.log("updateAdminControl data", data);

    if (data) {
      setIsUserBoardOpen(data.board_is_open);
      setShowDrawingBoard(data.drawing_board_is_open);
      setUserBoardText(data.board_text);
      setIsForceReloadInterval(data.force_reload);
    }
  };

  const isForceReloadInterval = useLessonsStore((state) => state.isForceReloadInterval);
  const setIsForceReloadInterval = useLessonsStore((state) => state.setIsForceReloadInterval);

  useEffect(() => {
    let interval: any = null;
    if (isTeacherLesson && isForceReloadInterval) {
      interval = setInterval(updateAdminControl, 2000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isTeacherLesson, isForceReloadInterval]);

  useEffect(() => {
    updateAdminControl();
  }, [authUser]);

  useEffect(() => {
    const channel = supabaseDrawingClient
      .channel("room11")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "drawing_strokes" },
        async (payload) => {
          console.log("🚀 ~ payload:", payload);
          // console.log("🚀 ~ payload:", payload);
          if (authUser && !isAdmin(authUser.id) && isTeacherLesson) {
            const normalizeData = getDataFromPayloadDrawingStroke((payload.new as any).data);
            // console.log("🚀 ~ normalizeData:", typeof normalizeData);
            if (
              (!normalizeData || normalizeData.userId === authUser.id) &&
              payload.eventType !== "DELETE"
            )
              return;

            if (payload.eventType === "INSERT" && payload.new.data.userId !== authUser.id) {
              setDrawingRealtimeElements([...drawingRealtimeElements, normalizeData]);
            } else if (payload.eventType === "UPDATE" && payload.new.data.userId !== authUser.id) {
              setDrawingRealtimeElements([
                ...drawingRealtimeElements.filter((s: any) => s.id !== payload.new.id),
                normalizeData,
              ]);
            } else if (payload.eventType === "DELETE") {
              console.log("🚀 ~ payload:", payload);
              setDrawingRealtimeElements([
                ...drawingRealtimeElements.filter((s: any) => s.id !== payload.old.id),
              ]);
            }
            // console.log("🚀 ~ payload:", payload);
          }
        },
      )
      .subscribe();
    return () => {
      channel.unsubscribe();
    };
  }, [authUser, isTeacherLesson, drawingRealtimeElements]);

  // useEffect(() => {
  //   const channel = supabaseClient
  //     .channel("room13")
  //     .on(
  //       "postgres_changes",
  //       { event: "*", schema: "public", table: "drawing_strokes" },
  //       async (payload) => {
  //         if (authUser && !isAdmin(authUser.id) && isTeacherLesson) {
  //           if (payload.eventType === "INSERT" && payload.new.data.userId !== authUser.id) {
  //             setDrawingRealtimeElements([...drawingRealtimeElements, payload.new.data]);
  //           } else if (payload.eventType === "UPDATE" && payload.new.data.userId !== authUser.id) {
  //             setDrawingRealtimeElements([
  //               ...drawingRealtimeElements.filter((s: any) => s.id !== payload.new.id),
  //               payload.new.data,
  //             ]);
  //           } else if (payload.eventType === "DELETE") {
  //             setDrawingRealtimeElements([
  //               ...drawingRealtimeElements.filter((s: any) => s.id !== payload.old.id),
  //             ]);
  //           }
  //           console.log("🚀 ~ payload:", payload);
  //         }
  //       },
  //     )
  //     .subscribe();
  //   return () => {
  //     channel.unsubscribe();
  //   };
  // }, [authUser, isTeacherLesson, drawingRealtimeElements]);

  useEffect(() => {
    const channel = supabaseClient
      .channel("room9")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "admin_control" },
        async (payload) => {
          // console.log("🚀 ~ payload:", payload.new);
          if (!isAdmin(authUser?.id)) {
            console.log("🚀 ~ payload:", payload.new);
          }

          if (
            (!isAdmin(authUser?.id) &&
              // only for current user but not for admin
              authUser?.id === payload.new.user &&
              // we have only one element in the db table
              payload.new.id === 0 &&
              // has already reloaded
              isTeacherLesson !== payload.new.is_teacher_lesson) ||
            // reload the page if the user is changed
            // to change isTeacherLesson prop in the state
            (!isAdmin(authUser?.id) && authUser?.id !== payload.new.user && isTeacherLesson)
          ) {
            window.location.reload();
          }

          const needToUpdateStudentDrawingBoard =
            drawingStudentForceUpdateId !== payload.new?.drawing_student_update_id;
          const needToUpdateForceReload = isForceReloadInterval !== payload.new?.force_reload;

          const needToUpdateStudentState =
            !isAdmin(authUser?.id) && authUser?.id === payload.new?.user && payload.new?.id === 0;
          console.log("🚀 ~ needToUpdateStudentState:", needToUpdateStudentState);

          if (needToUpdateStudentState && needToUpdateStudentDrawingBoard) {
            const { data: allStrokes } = await supabaseDrawingClient
              .from("drawing_strokes")
              .select();
            console.log("try to update drawingStudentForceUpdateId");
            setDrawingStudentForceUpdateId(payload.new.drawing_student_update_id);
            console.log("🚀 ~ allStrokes:", allStrokes);
            setDrawingRealtimeElements([]);

            if (excalidrawAPI) {
              const sceneData = {
                elements: [],
                appState: { ...excalidrawAPI.getAppState() },
              };
              if (excalidrawAPI.updateScene) {
                excalidrawAPI.updateScene(sceneData);
              }
              setDrawingRealtimeElements([]);

              if (allStrokes) {
                const elements = allStrokes
                  .map((s) => {
                    const normalizeData = getDataFromPayloadDrawingStroke(s.data) || {};
                    return normalizeData;
                  })
                  .filter(Boolean);
                setDrawingRealtimeElements(elements);
                // const sceneData = {
                //   elements: elements,
                //   appState: { ...excalidrawAPI.getAppState() },
                // };
                // if (excalidrawAPI.updateScene) {
                //   excalidrawAPI.updateScene(sceneData);
                // }
              }
            }
          }
          if (needToUpdateStudentState && needToUpdateForceReload) {
            setIsForceReloadInterval(payload.new.force_reload);
          }

          if (needToUpdateStudentState && !needToUpdateStudentDrawingBoard) {
            setIsUserBoardOpen(payload.new.board_is_open);
            setShowDrawingBoard(payload.new.drawing_board_is_open);
            if (drawingClearId !== payload.new.drawing_clear_id) {
              setDrawingClearId(payload.new.drawing_clear_id);
              setDrawingRealtimeElements([]);
              if (excalidrawAPI) {
                const sceneData = {
                  elements: [],
                  appState: { ...excalidrawAPI.getAppState() },
                };
                if (excalidrawAPI.updateScene) {
                  excalidrawAPI.updateScene(sceneData);
                }
                setDrawingRealtimeElements([]);
              }
            }
            if (!studentWordsToRead?.length) {
              setStudentWordsToRead(payload.new.words_to_read);
            }
            if (payload.new.new_repeat_index) {
              setAdminControlRepeatingIndex(payload.new.new_repeat_index);
            }
          }

          const needToUpdateReadingSentenceIndex =
            payload.new?.new_reading_sentence_index &&
            adminControlReadingSentenceIndex !== payload.new.new_reading_sentence_index;
          // console.log(
          //   "--------------",
          //   adminControlReadingSentenceIndex,
          //   payload.new.new_reading_sentence_index,
          // );
          if (needToUpdateReadingSentenceIndex) {
            setAdminControlReadingSentenceIndex(payload.new.new_reading_sentence_index);
          }

          const needToUpdateBoardText =
            !isAdmin(authUser?.id) &&
            authUser?.id === payload.new.user &&
            payload.new.id === 0 &&
            !isForceReloadInterval;

          if (needToUpdateBoardText) setUserBoardText(payload.new.board_text);

          if (
            !isAdmin(authUser?.id) &&
            authUser?.id === payload.new.user &&
            payload.new.id === 0 &&
            payload.new.active_video_id
          ) {
            setActiveVideoId(payload.new.active_video_id);
          }
        },
      )
      .subscribe();
    return () => {
      channel.unsubscribe();
    };
  }, [
    authUser,
    drawingClearId,
    studentWordsToRead,
    excalidrawAPI,
    isTeacherLesson,
    isForceReloadInterval,
    drawingStudentForceUpdateId,
    adminControlReadingSentenceIndex,
  ]);
};

export default useAdminControlRealtime;
