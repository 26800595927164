import { useParams } from "react-router-dom";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import {
  Fragment,
  PropsWithChildren,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import supabaseClient from "../../../common/supabaseClient";
import {
  Book,
  Chapter,
  FunctionGetWordsWithAdditionalReturn,
  HoverDetailsWords,
  Word,
} from "../../../types/supabase";
import Drawer from "../../Unknown/Drawer";
import Box from "../../Unknown/Box";
import FullScreenLoader from "../../Unknown/FullScreenLoader";
import { useLessonsStore } from "../../../store/lessonsStore";
import CopyNewWordsForGemini from "../../Admin/CopyNewWordsForGemini";
import isAdmin from "../../../common/isAdmin";
import Typography from "../../Unknown/Typography";
import WordTranscription from "../../Word/WordTranscription";
import ExampleItem from "../ExampleItem";
import json from "./json";
import { useFloating, autoUpdate } from "@floating-ui/react";
import { Button, Container, IconButton } from "@mui/material";
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverDescription,
  PopoverHeading,
  PopoverTrigger,
} from "./Popover";
import WordAudio from "../../Audio/WordAudio";
import NewWordTranscription from "../../Admin/NewWordTranscription";
import UkrainianWord from "../../Sentence/UkrainianWord";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import book1 from "./books/book1";
import book2 from "./books/book2";
import book3 from "./books/book3";
import book4 from "./books/book4";
import book5 from "./books/book5";
import book6 from "./books/book6";
import book7 from "./books/book7";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import wait from "../../../common/wait";
import map from "./books/map";
import ModalCloseButton from "../../Unknown/ModalCloseButton";
import Loader from "../../Unknown/Loader";
import AdminModalChange from "../../Unknown/AdminModalChange";

import "react-contexify/ReactContexify.css";
import HoverDetailsWord from "../../Word/HoverDetailsWord";
import HoverDetails from "../../Word/HoverDetails";

const additionals = {
  "Mr.Ms.": '"Mr. Jones", "Mrs. Jones", "Miss May", "Ms. Ann"',
};

const HarryPotterChapter = () => {
  const params = useParams();
  console.log("🚀 ~ params:", params);
  const [chapter, setChapter] = useState<Book>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const explainText = useLessonsStore((state) => state.explainText);
  const setExplainText = useLessonsStore((state) => state.setExplainText);
  const setGlobalWords = useLessonsStore((state) => state.setGlobalWords);
  const globalWords = useLessonsStore((state) => state.globalWords);
  const [explainWord, setExplainWord] = useState<Word | null>(null);

  const [open, setOpen] = useState(false);

  const updateExistingWord = (currentWordName: string) => {
    const existingWord = globalWords.find(
      (word) => currentWordName && word.name === currentWordName,
    );
    if (existingWord) setExplainWord(existingWord);
  };

  const handleDetailsOpen = async (currentWordName: string) => {
    const existingWord = globalWords.find(
      (word) => currentWordName && word.name === currentWordName,
    );
    console.log("🚀 ~ existingWord:", existingWord);

    if (existingWord) {
      setExplainWord(existingWord);
      setIsDrawerOpen(true);
      // setExplainText(combineText());
      // setIsLoading(false);
    } else {
      setExplainWord(null);
      setIsLoading(true);
      setIsDrawerOpen(true);
      // setExplainText(combineText());

      // if (!currentWordName) {
      //   console.error("No name in:", currentWordName);
      // }
      const { data } = await supabaseClient
        .from("words")
        .select()
        .eq("name", currentWordName)
        .single();

      if (data) {
        setExplainWord(data);
        setGlobalWords([data]);
      }
    }

    setIsLoading(false);
    // if (explainText.join(",") === combineText().join(",")) {
    //             setIsDrawerOpen(true);
    //           } else {
    //           }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<HoverDetailsWords[]>([]);
  console.log("🚀 ~ currentPageData:", currentPageData);

  const fetchPageWordsWithAdditional = async (): Promise<
    FunctionGetWordsWithAdditionalReturn[]
  > => {
    const { data } = await supabaseClient
      .rpc("get_words_with_additional", {
        book_id: "harry-potter-and-the-sorcerer-stone",
        page_number: 1,
      })
      .returns<FunctionGetWordsWithAdditionalReturn[]>();

    return data!;
  };

  useMountedWaitAsyncEffect(async () => {
    console.log("🚀 ~ params:", params);
    if (params.bookId && currentPage) {
      const data = await fetchPageWordsWithAdditional();
      if (data) setCurrentPageData(data);
    }
  }, [currentPage, params.bookId]);

  useMountedWaitAsyncEffect(async () => {
    const channel = supabaseClient
      .channel("room4")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "words" },
        async (payload) => {
          console.log("🚀 ~ payload:", payload.new);
          if (payload.new) {
            setGlobalWords([payload.new as any]);
            const data = await fetchPageWordsWithAdditional();
            if (data) setCurrentPageData(data);
          }
        },
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, [chapter]);

  useMountedWaitAsyncEffect(async () => {
    // if variant is changed
    const channel = supabaseClient
      .channel("room6")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "harry_potter_words" },
        async (payload) => {
          if (payload.new) {
            const data = await fetchPageWordsWithAdditional();
            if (data) setCurrentPageData(data);
          }
        },
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, [chapter]);

  useEffect(() => {
    if (explainWord) updateExistingWord(explainWord.name);
  }, [globalWords, explainWord]);

  // const handleItemClick = ({ id, event, props }: any) => {
  //   switch (id) {
  //     case "copy":
  //       console.log(event, props);
  //       break;
  //     case "cut":
  //       console.log(event, props);
  //       break;
  //     //etc...
  //   }
  // };

  if (!currentPageData.length) return <Loader />;

  return (
    <Container maxWidth="sm">
      <HoverDetails words={currentPageData} />

      {/* {drawer} */}
    </Container>
  );
};

export default HarryPotterChapter;
