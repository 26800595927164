import { Box, Button, Modal, Typography } from "@mui/material";
import ReactPlayer from "react-player";
import { addDays, addYears } from "date-fns";
import { memo, useCallback, useEffect, useState } from "react";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../store/lessonsStore";
import { ExtendedUserSentence } from "../../../types/supabase";
import MenuLayout from "../../Layout/MenuLayout";
import Loader from "../../Unknown/Loader";
import WhiteBoxWrap from "../../Unknown/WhiteBoxWrap";
import RepetitionUserSentence from "../RepetitionUserSentence";
import VStack from "../../Unknown/VStack";
import InfoIcon from "@mui/icons-material/Info";
import ModalCloseButton from "../../Unknown/ModalCloseButton";
import AdminComponentIndicator from "../../Unknown/AdminComponentIndicator";
import HStack from "../../Unknown/HStack";
import isAdmin from "../../../common/isAdmin";
import { ListeningSentences } from "./ListeningSentences";

const RepetitionUserSentenceScreen: React.FC = () => {
  const [count, setCount] = useState(0);
  const authUser = useLessonsStore((state) => state.authUser);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getTodayWordsCount = useCallback(async () => {
    if (!authUser) return;

    const { count: spSentenceCount } = await supabaseClient
      .from("user-sentences")
      .select("*", { count: "exact", head: true })
      .like("id", `${authUser.uuid}-%`)
      // .is("sentences.verified_words", true)
      .lte("repeat", addDays(new Date().setHours(0, 0, 0, 0), 1).toISOString());

    if (spSentenceCount && (spSentenceCount < count || (count === 0 && spSentenceCount >= 2)))
      setCount(spSentenceCount);
  }, [authUser, count]);

  const [sentences, setSentences] = useState<ExtendedUserSentence[] | null>(null);

  useMountedWaitAsyncEffect(async () => {
    if (sentences === null && authUser) {
      const { data: spSentences } = await supabaseClient
        .from("user-sentences")
        .select("*, sentences(id, ua, en_audio_url, verified_words)")
        .like("id", `${authUser.uuid}-%`)
        .order("repeat")
        .order("en")
        // .is("sentences.verified_words", true)
        .limit(45)
        .returns<ExtendedUserSentence[]>();

      if (spSentences) setSentences(spSentences);
    }
  }, [sentences, authUser]);

  const getNewSentence = async (): Promise<ExtendedUserSentence | null> => {
    if (!sentences || !authUser) return null;

    const rowSentences = Array.from(new Set(sentences.map((w) => w.en)));
    const { data: spSentences } = await supabaseClient
      .from("user-sentences")
      .select("*, sentences(id, ua, en_audio_url, verified_words)")
      .like("id", `${authUser.uuid}-%`)
      .order("repeat")
      .order("en")
      // .range(+period, +period + 1)
      .not("en", "in", `(${rowSentences.join(",")})`)
      .limit(1)
      .returns<ExtendedUserSentence[]>()
      .single();

    if (spSentences && rowSentences.includes(spSentences.en)) {
      const { data: spSentences } = await supabaseClient
        .from("user-sentences")
        .select("*, sentences(id, ua, en_audio_url, verified_words)")
        .like("id", `${authUser.uuid}-%`)
        .order("repeat")
        .order("en")
        .not("en", "in", `(${rowSentences.join(",")})`)
        .limit(46)
        .returns<ExtendedUserSentence[]>();

      const [sentence] = spSentences?.filter((s) => !rowSentences.includes(s.en)) || [];
      return sentence;
    }
    return spSentences as ExtendedUserSentence;
  };

  const goToNextSlide = async (word: string, period: string) => {
    if (!sentences) return;

    const [currentWord, ...restSentences] = sentences;

    const rowSentences = Array.from(new Set(sentences.map((w) => w.en)));

    if (period === "never" && authUser) {
      setSentences(restSentences);
      if (count) setCount((prev) => prev - 1);

      const result = await supabaseClient.rpc("update_user_sentece", {
        userprop: authUser.id,
        input_sentence: sentence.en,
        period: addYears(new Date(), 100),
      });

      const spSentence = await getNewSentence();

      if (spSentence && !rowSentences.includes(spSentence.en)) {
        setSentences((prev) => [...restSentences, spSentence]);
      }
    } else if (period.includes("d") && authUser) {
      setSentences(restSentences);
      if (count) setCount((prev) => prev - 1);

      await supabaseClient.rpc("update_user_sentece", {
        userprop: authUser.id,
        input_sentence: sentence.en,
        period: addDays(new Date(), +period.replace("d", "")),
      });

      const spSentence = await getNewSentence();

      if (spSentence && !rowSentences.includes(spSentence.en)) {
        setSentences((prev) => [...restSentences, spSentence]);
      }
    } else if (authUser) {
      setSentences(
        restSentences.reduce<ExtendedUserSentence[]>((prev, cW, index) => {
          const updated = [...prev];
          if (index + 1 === +period) updated.push(currentWord);
          updated.push(cW);
          return updated;
        }, []),
      );

      // const { data: spSentence } = await supabaseClient
      //   .from("user-sentences")
      //   .select("*, sentences(ua, en_audio_url)")
      //   .like("id", `${authUser.uuid}-%`)
      //   .order("repeat")
      //   .order("en")
      //   .range(+period, +period + 1)
      //   .not("en", "in", `(${restSentences.map((w) => w.en).join(",")})`)
      //   .limit(1)
      //   .returns<ExtendedUserSentence[]>()
      //   .single();

      // if (spSentence) {
      //   await supabaseClient
      //     .from("user-sentences")
      //     .update({
      //       repeat: addSeconds(new Date(), +period),
      //     })
      //     .like("id", `${authUser.uuid}-%`);
      // }
    }

    getTodayWordsCount();
  };

  useEffect(() => {
    if (!sentences) getTodayWordsCount();
  }, [sentences, getTodayWordsCount]);

  if (sentences?.length === 0)
    return (
      <MenuLayout>
        <Box px={2} textAlign="center" sx={{ backgroundColor: "white" }}>
          <Typography variant="h3" py={20}>
            Поки що нема речень для повторення
          </Typography>
        </Box>
      </MenuLayout>
    );

  const [sentence] = sentences || [];

  if (!sentence)
    return (
      <MenuLayout>
        <WhiteBoxWrap>
          <Loader />
        </WhiteBoxWrap>
      </MenuLayout>
    );

  // if (sentences && sentences.length < 20) {
  //   return (
  //     <MenuLayout>
  //       <WhiteBoxWrap>
  //         <VStack px={4} textAlign="center">
  //           <Typography>Недостатня кількість речень для повторення.</Typography>
  //           <Typography>
  //             Після проходження 1-2 уроків, речення автоматично добавляться сюди
  //             та буде можливість їх повторювати.
  //           </Typography>
  //         </VStack>
  //       </WhiteBoxWrap>
  //     </MenuLayout>
  //   );
  // }

  return (
    <MenuLayout>
      <Box sx={{ backgroundColor: "white" }}>
        {isAdmin(authUser?.id) && <ListeningSentences sliderId="124924923434" />}
        {/* <AdminTranslateWithWoordhunter title={word} />
            <AdminTranslateWithGoogle title={word} /> */}
        {Boolean(count) && (
          <HStack justifyContent="center" pt={2}>
            <AdminComponentIndicator title="RepetitionUserSentenceScreen" />
            <Typography textAlign="center">Залишилось повторити: {count}</Typography>
          </HStack>
        )}
        {/* <VStack alignItems="flex-end">
          <Button color="info" onClick={handleOpen}>
            <InfoIcon />
            &nbsp; Як це працює?
          </Button>
        </VStack> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              p: 4,
              overflow: "hidden",
              maxWidth: {
                xs: "100%",
                sm: "550px",
                md: "720px",
              },
              height: {
                xs: "auto",
                sm: "310px",
                md: "405px",
              },
              paddingBottom: {
                xs: "52%",
                sm: 0,
              },
              bgcolor: "background.paper",
            }}
          >
            <ModalCloseButton onClose={handleClose} />
            <ReactPlayer
              controls
              width="100%"
              height="100%"
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}
              url="https://youtu.be/4mz2_bXVvdU?cc_load_policy=0"
              playing
            />
          </Box>
        </Modal>
        {!Boolean(count) && (
          <Typography textAlign="center" pt={2}>
            На сьогодні всі речення повторені, але ти можеш продовжити&nbsp;🚀
          </Typography>
        )}
        {sentence && (
          <RepetitionUserSentence
            sentence={sentence}
            wordsCount={sentences!.length}
            goToNextSlide={goToNextSlide}
          />
        )}
      </Box>
      {/* {!sentence && <LastSentenceItem grammar nextButtonText="" />} */}
    </MenuLayout>
  );
};

export default memo(RepetitionUserSentenceScreen);
