import { Box, Link, Drawer, Typography } from "@mui/material";
import { PropsWithChildren, useCallback, useState } from "react";
import isAdmin from "../../../../common/isAdmin";
import supabaseClient from "../../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../../store/lessonsStore";
import { Chapter, Word } from "../../../../types/supabase";
import CopyNewWordsForGemini from "../../../Admin/CopyNewWordsForGemini";
import FullScreenLoader from "../../../Unknown/FullScreenLoader";
import WordTranscription from "../../../Word/WordTranscription";
import ExampleItem from "../../ExampleItem";
import VStack from "../../../Unknown/VStack";
import { Link as RouteLink, useLocation, useParams } from "react-router-dom";
import Loader from "../../../Unknown/Loader";

const Explain: React.FC<PropsWithChildren & { onClick: () => void }> = ({ onClick, children }) => {
  return (
    <Box
      component="span"
      onClick={onClick}
      sx={{
        cursor: "pointer",
        display: "inline-block",
        fontFamily: "Nunito Sans",
        fontWeight: 600,
        "&:hover": {
          textDecoration: "underline",
        },
      }}
    >
      {children}
    </Box>
  );
};

const MovieTitle: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Typography
      variant="h1"
      sx={{
        fontFamily: "Nunito Sans",
        fontOpticalSizing: "auto",
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: 40,
        marginBottom: 5,
      }}
    >
      {title}
    </Typography>
  );
};

const ChapterTitle: React.FC<{ number: string }> = ({ number }) => {
  return (
    <Typography
      variant="h3"
      sx={{
        textTransform: "uppercase",
        fontFamily: "Nunito Sans",
        fontOpticalSizing: "auto",
        fontWeight: 700,
        fontStyle: "normal",
        marginBottom: 5,
      }}
    >
      chapter {number}
    </Typography>
  );
};

const ChapterSubTitle: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Typography
      variant="h2"
      sx={{
        textTransform: "uppercase",
        fontWeight: 700,
        fontFamily: "Nunito Sans",
        fontOpticalSizing: "auto",
        fontStyle: "normal",
        marginBottom: 5,
      }}
    >
      {text}
    </Typography>
  );
};

const HarryPotterPhilosophersStone = () => {
  const [isLoading, setIsLoading] = useState(true);

  // const prevExplainText = usePrevious(explainText)
  const location = useLocation();
  const params = useParams();

  const [chapters, setChapters] = useState<Chapter[]>();

  useMountedWaitAsyncEffect(async () => {
    if (params.bookId) {
      const { data } = await supabaseClient
        .from("chapters")
        .select()
        .eq("book_id", params.bookId)
        .order("chapter_number")
        .returns<Chapter[]>()
        .throwOnError();

      if (data) {
        setChapters(data);
        setIsLoading(false);
      }
    }
  }, [params?.bookId]);

  if (isLoading) return <Loader />;

  return (
    <>
      <VStack>
        <Box component="ol">
          {chapters?.map((chapter) => (
            <Typography key={chapter.chapter_id} variant="h3" gutterBottom component="li">
              <Link component={RouteLink} to={chapter.chapter_id}>
                {chapter.chapter_title}
              </Link>
            </Typography>
          ))}
        </Box>
      </VStack>
      {/* <MovieTitle title="Harry Potter and the Sorcerer's Stone" /> */}
    </>
  );
};

export default HarryPotterPhilosophersStone;
