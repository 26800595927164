import { useState } from "react";
import Box from "../../../Box";
import TextField from "../../../TextField";
import HStack from "../../../HStack";
import { useLessonsStore } from "../../../../../store/lessonsStore";
import isAdmin from "../../../../../common/isAdmin";
import LoadingButton from "@mui/lab/LoadingButton";
import supabaseClient from "../../../../../common/supabaseClient";
import { Typography } from "@mui/material";

const AddNewRule = () => {
  const [question, setQuestion] = useState("");
  const [lesson, setLesson] = useState(1);
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const authUser = useLessonsStore((state) => state.authUser);

  if (!isAdmin(authUser?.id)) return null;

  return (
    <HStack>
      <Typography variant="h2">Add new rule</Typography>
      <TextField
        color="primary"
        value={question}
        fullWidth
        multiline={true}
        placeholder="Question"
        onChange={(event) => {
          setQuestion(event.target.value);
        }}
        type="text"
        variant="outlined"
      />
      <TextField
        color="primary"
        value={answer}
        fullWidth
        multiline={true}
        placeholder="Answer"
        onChange={(event) => {
          setAnswer(event.target.value);
        }}
        type="text"
        variant="outlined"
      />
      <TextField
        color="primary"
        value={lesson}
        sx={{ width: "50px" }}
        multiline={true}
        onChange={(event) => {
          setLesson(+event.target.value);
        }}
        type="text"
        variant="outlined"
      />
      <LoadingButton
        loading={loading}
        variant="contained"
        onClick={async () => {
          setLoading(true);
          const result = await supabaseClient.from("english_rules").insert({
            question,
            answer,
            lesson,
          });
          if (!result.error) {
            setAnswer("");
            setQuestion("");
          }
          setLoading(false);
        }}
      >
        S
      </LoadingButton>
    </HStack>
  );
};

export default AddNewRule;
