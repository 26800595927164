const storageBaseUrl = "https://storage.googleapis.com/leeearning.appspot.com/";
const storageEnDirectory = "enAudio";

const gcVoices = [
  "en-US-News-L",
  "en-US-Standard-F",
  "en-US-Standard-G",
  "en-US-Standard-H",
  "en-US-Standard-J",
];

export const skipWords = ["/", "not", "1–10"];

const myNewWords = ["to shape", "destiny", "vase", "clay"];

const drawerWidth = 240;
const footerHeight = 50;
const appBarHeight = 48;

const storageEnAduioDirectory = "https://storage.googleapis.com/leeearning.appspot.com/enAudio/";
const storageUaAduioDirectory = "https://storage.googleapis.com/leeearning.appspot.com/uaAudio/";
export const userHomeWorkCurrentDay = "user-homework-current-day";

export const wordsWithoutImages = [
  // "fingers",
  // "fleece",
  // "white",
  // "snow",
  // "school",
  // "many",
  // ---
  "wag",
  "you're",
  "much",
  "away",
  "now",
  "by",
  "but",
  "said",
  "when",
  "go away",
  "come",
  "again",
  "another",
  "all",
  "stomp",
  "wiggle",
  "that's",
  "make",
  "please",
  "was",
  "can't",
  "your",
  "that",
  "went",
  "sure",
  "follow",
  "followed",
  "her",
  "against",
  "rule",
  "at",
  //
  "let",
  "get",
  "does",
  "don't",
  "worry",
  "every",
  "day",
  "play",
  "sleep",
  "store",
  "zoo",
  "today",
  "hare",
  "with",
  "boy",
  "plays",
  "had",
  "he",
  "she",
  "understand",
  "understands",
  "me",
  "short",
  "text",
  "side",
  "outside",
  "hurry up",
  "put",
  "put on",
  "I'm",
  "they're",
  "he's",
  "she's",
  "we's",
  "they",
  "want",
  "wants",
  "very",
  "over",
  "there",
  "its",
  "of",
  "needs",
  "be",
  "mine",
  "mile",
  "as",
  "I",
  "am",
  "he",
  "we",
  "here",
  "west",
  "this",
  "is",
  "man",
  "sat",
  "the",
  "fat",
  "look",
  "too",
  "you",
  "are",
  "lady",
  "lip",
  "lips",
  "have",
  "has",
  "need",
];

const verbs = [
  "want",
  "do",
  "know",
  "need",
  "see",
  "sleep",
  "call",
  "happen",
  "hug",
  "have",
  "like",
  "help",
  "plan",
  "visit",
  "taste",
  "eat",
  "try",
  "get",
  "be",
  "go",
  "meet",
  "work",
  "talk",
  "tell",
  "watch",
  "buy",
  "drink",
  "think",
  "listen",
  "sing",
  "understand",
];

export const wordsWithDifferentPronunciation = ["produce", "live"];

export const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
  // backgroundColor: "rgba(0, 0, 0, 0) !important",
};

// const alwaysUpperWords = [
//   "I",
//   "English",
//   "I'll",
//   "Ukraine",
//   "Netherlands",
//   "X-ray",
//   "I'm",
//   "I've",
//   "Lisa",
//   "I'd",
//   "Celsius",
//   "Monday",
//   "Thursday",
//   "Tuesday",
//   "Wednesday",
//   "Friday",
//   "Sunday",
//   "Saturday",
//   "March",
//   "Brazil",
// ];

const bindTopics: Record<string, string> = {
  introduction: "introduction",
  introductions: "introduction",
  "introductions, nicknames": "introduction",
  "introducing oneself": "introduction",
  "family upbringing": "family",
};
const reverseBindTopics = Object.entries(bindTopics).reduce<Record<string, string[]>>(
  (prev, [key, value]) => {
    return {
      ...prev,
      [value]: Array.from(new Set([...(prev[value] || []), key])),
    };
  },
  {},
);

const adminUserId = "shevsky1488";

const thirdPersonVerbs = [
  "wants",
  "does",
  "knows",
  "happens",
  "watchs",
  "thinks",
  "needs",
  "sees",
  "sleeps",
  "calls",
  "hugs",
  "has",
  "likes",
  "helps",
  "plans",
  "visits",
  "tastes",
  "eats",
  "tries",
  "gets",
  "is",
  "are",
  "am",
  "goes",
  "meets",
  "works",
  "talks",
  "tells",
  "buys",
  "sings",
  "drinks",
  "listens",
  "understands",
];

const mainTopics = [
  "job",
  "time",
  "cafe",
  "food",
  "store",
  "street",
  "family",
  "request",
  "language",
  "education",
  "interests",
  "introduction",
];

const wordsToSkip = ["–", "b"];

export const fullScreenModalStyle = {
  position: "absolute" as "absolute",
  top: "25px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 400,
  overflow: "auto",
  maxHeight: "calc(100% - 50px)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export {
  verbs,
  wordsToSkip,
  gcVoices,
  mainTopics,
  myNewWords,
  bindTopics,
  drawerWidth,
  adminUserId,
  footerHeight,
  appBarHeight,
  storageBaseUrl,
  // alwaysUpperWords,
  thirdPersonVerbs,
  reverseBindTopics,
  storageEnDirectory,
  storageUaAduioDirectory,
  storageEnAduioDirectory,
};
