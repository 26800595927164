import { Button } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import supabaseClient from "../../common/supabaseClient";
import { useLessonsStore } from "../../store/lessonsStore";
import { useNavigate } from "react-router-dom";

const LogoutButton = () => {
  const setSession = useLessonsStore((state) => state.setSession);
  const navigate = useNavigate();

  return (
    <Button
      variant="outlined"
      sx={{ color: "black", display: "flex", gap: 2 }}
      onClick={async () => {
        await supabaseClient.auth.signOut();
        setSession(null);
        navigate("/");
      }}
    >
      Вийти <LogoutIcon />
    </Button>
  );
};

export default LogoutButton;
