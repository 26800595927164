import Box from "@mui/material/Box";

const lesson18: any = {
  letters: [],
  number: 18,
  storyIds: [
    1850, // thank you, you're welcome
    1846, // spin around
    // 1868, 1751, 1600,
    // 1698, // cat sat
    // 1589, // daddy finger
    // 1725, // jingle bells
    // 1699, // dad had a fan
    // 1753,
    // 1752, // I like to hop
  ],
  poemIds: [
    1850, // thank you, you're welcome
    1846, // spin around
    // 1861, // make a circle
    // 1752, // I like to hop
    // 1599, // rain, go away
    // 1751,
    // 1868,
    // 1826,
    // 1828,
    // 1589,
    // 1600,
  ],
  newLettersCount: 0,
  newSounsCount: 0,
  title: <Box>Thank you very much</Box>,
  sounds: [
    "Oo(ɒ)",
    "Nn",
    "Tt",
    "Ee(e)",
    "Bb",
    "Dd",
    "Ll",
    "Ii(i)",
    "Pp",
    "Ss(s)",
    "Ss(z)",
    "Zz",
    "Aa(æ)",
    "Hh",
    "Vv",
    "Kk",
    "Aa(æ)",
    "Hh",
    "Vv",
    "Kk",
    "Mm",
    "Ff",
    "Gg",
    "Mm",
    "Ff",
    "Gg",
    "Cc(k)",
    "Rr",
    "oo(uː)",
    "ee(i:)",
    "th(ð)",
    "oo(ʊ)",
    "ea(i:)",
    "sh(ʃ)",
    "Ww",
    "Oo(əʊ)",
    "Ee(ɪ)",
    "j(dʒ)",
    "Uu(ʌ)",
    "Yy(j)",
    "Yy(i)",
    "oy(ɔɪ)",
    "ay(eɪ)",
  ],
  newSounds: [],
  words: [
    // "he",
    // "she",
    // "want",
    // "climb",
    // "can",
    // "do",
    // "can't",
    // "had",
    // // "lamb",
    // "its",
    // "fleece",
    // "was",
    // "white",
    // // "as",
    // // "snow",
    // // "and",
    // // "everywhere",
    // "that",
    // "went",
    // "sure",
    // // "go",
    // "follow",
    // "followed",
    // "her",
    // // "school",
    // "one",
    // "day",
    // "against",
    // "rule",
    // "made",
    // "child",
    // "children",
    // "laugh",
    // "play",
    // "see",
    // "in",
    // "at",
    //
    // "fast",
    // "slow",
    // "runs",
    // "very",
    // "jump",
    // "high",
    // "sun",
    // "low",
    // "plane",
    // "ring",
    // "here",
    // "there",
    // "glass",
    // "need",
    // "dishes",
    // "want",
    // "animal",
    // "run",
    // "every",
    // "sleep",
    // "store",
    // "zoo",
    // "today",
    // "hare",
    // "with",
    // "boy",
    // "plays",
    // "walk",
    // "understand",
    // "next",
    // "do",
    // "hot",
    // "climb",
    // "tree",
    // "bag",
    // "eat",
    // "sheep",
    // "geese",
    // "man",
    // "box",
    // "boxes",
    // "fox",
    // "foxes",
    // "mix",
    // "mixer",
    // "six",
    // "text",
    // "taxi",
    // "read",
    // "problem",
    // "they",
    // "animals",
    // "cup",
    // "understands",
    // "me",
    // "wash",
    // "water",
    // "wants",
    // "swan",
    // "cold",
    // "red",
    // "van",
    // "jeep",
    // "jam",
    // "trash",
    // "he",
    // "has",
    // "she",
    // "shop",
    // "we",
    // "likes",
    // "park",
    // "fish",
    // "climbs",
    // "needs",
    // "am",
    // "hamster",
    // "are",
    // "lambs",
    // "be",
    // "rat",
    // "mat",
    // "look",
    // "sat",
    // "too",
    // "fat",
    // "coffee",
    // "tea",
    // "cat",
    // "this",
    // "root",
    // "roots",
    // "seven",
    // "ran",
    // "room",
    // "pink",
    // "banana",
    // "cats",
    // "on",
    // "green",
    // "goose",
    // "plan",
    // "bedroom",
    // "bananas",
    // "pan",
    // "pans",
    // "fan",
    // "bike",
    // "five",
    // "kite",
    // "bikes",
    // "lime",
    // "life",
    // "time",
    // "map",
    // "maps",
    // "big",
    // "dog",
    // "dogs",
    // "pig",
    // "milk",
    // "is",
    // "hi",
    // "test",
    // "bed",
    // "ten",
    // "sport",
    // "leg",
    // "sister",
    // "continent",
    // "egg",
    // "car",
    // "star",
    // "end",
    // "no",
    // "note",
    // "stone",
    // "poet",
    // "arm",
    // "art",
    // "card",
    // "apple",
    // "hand",
    // "hat",
    // "yes",
    // "yard",
    // "hobby",
    // "Mary",
    // "hello",
    // "letter",
    // "dad",
    // "daddy",
    // "fact",
    // "family",
    // "frog",
    // "nose",
    // "rose",
    // "roses",
    // "mom",
    // "mommy",
    // "finger",
    // "song",
    // "I",
    // "tiger",
    // "spider",
    // "winter",
    // "spring",
    // "hop",
    // "can",
    // "England",
    // "summer",
    // "butter",
    // "up",
    // "the",
    // "bank",
    // "book",
    // "notebook",
    // "spoon",
    // "wood",
    // "brother",
    // "group",
    // "soup",
    // "you",
    // "where",
    // "baby",
    // "lady",
    // "name",
    // "cow",
    // "how",
    // "down",
    // "two",
    // `your`,
    // "daddy",
    // "mommy",
    // "where",
    // "baby",
    // "how",
    // "song",
    // "finger",
    // "fingers",
    // "pens",
    // "with",
    // "many",
    // "here",
    // "there",
    // "had",
    // "its",
    // "was",
    // "as",
    // "sure",
    // "rule",
    // "went",
    // "that",
    // "her",
    // "laugh",
    // "climb",
    // "birth",
    // "birthday",
    // "was",
    // "this",
    // "follow",
    // "home",
    // "want",
    // "does",
    // "laugh",
    // "yard",
    // "it",
    // "pet",
    // "big",
    // "need",
    // "very",
    // "fast",
    // "her",
    // "ten",
    // "child",
    // "man",
    // "pan",
    // "pen",
    // "can",
    // "has",
    // "fish",
    // "blue",
    // "wet",
    // "net",
    // "get",
    // "let",
    // "jet",
    // "vet",
    // "you",
    // "does",
    // "went",
    // "don't",
    // "worry",
    // "happy",
    // "fly",
    // "plane",
    // "run",
    // "runs",
    // "look",
    // "penny",
    // "daughters",
    // "sons",
    // "buns",
    // "your",
    // "cross",
    // "many",
    // "cook",
    // "what",
    // "street",
    // "bake",
    // "give",
    // "them",
    // "people",
    // "two",
    // "rain",
    // "away",
    // "go away",
    // "come",
    // "again",
    // "another",
    // "all",
    // "our",
    // "let's",
    // "make",
    // "circle",
    // "small",
    // "round",
    // "up",
    // "down",
    // "please",
    // "stand",
    // "your",
    // "arms",
    // "that",
    // "give",
    // "one",
    // "bun",
    // "two",
    // "jump",
    // "made",
    // "stone",

    // "hop",
    // "a lot",
    // "mop",
    // "rag",
    // "pot",
    // "pop",
    // "brown",
    // "wag",
    // "stop",
    // "rabbit",
    // "color",
    // "corn",
    // "read",
    // "reads",
    // "popcorn",
    // "rain",
    // "away",
    // "go away",
    // "come",
    // "again",
    // "another",
    // "all",
    // "our",
    // "let's",
    // "against",
    // "ring",
    // "here",
    // "there",
    // "was",
    // "rule",
    // "want",
    // "eat",
    // "today",
    // "tomorrow",
    // "cold",
    // "bag",
    "spin",
    "around",
    "try",
    "faster",
    "really",
    "thank",
    "you're",
    "welcome",
    "bless you",
    "ball",
    "text",
    "happy",
    "very",
    "much",
    "now",
    "come",
    "again",
    "read",
    "work",
    "don't",
    "bag",
    "in",
    "what",
    "color",
    "fast",
    "want",
    "went",
  ],
  speakingWords: [],
};

export default lesson18;
