import { Box, Button, Container, Link as MuiLink, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useLessonsStore } from "../../../store/lessonsStore";
import { Module, Sentence, Word } from "../../../types/supabase";
import MenuLayout from "../../Layout/MenuLayout";
import HStack from "../../Unknown/HStack";
import VStack from "../../Unknown/VStack";
import isAdmin from "../../../common/isAdmin";
import supabaseClient from "../../../common/supabaseClient";
import LoadingButton from "@mui/lab/LoadingButton";
import FindVowelLetter from "../UkTrainers/FindVowelLetter";
import FindConconantLetter from "../UkTrainers/FindConconantLetter";
import TrainersModalWithButton from "../UkTrainers/TrainersModalWithButton";
import { TrainersConfig } from "../UkTrainers/constants";
import { Nouns } from "../lesson-text-descriptions/Nouns";
import { UaDifferenceBetweenVoicedAndUnVoicedLetters } from "../lesson-text-descriptions/UaDifferenceBetweenVoicedAndUnVoicedLetters";
import { UaWordsSyllables } from "../lesson-text-descriptions/UaWordsSyllables";
import { EnWordsSyllables } from "../lesson-text-descriptions/EnWordsSyllables";
import HomeWorkScreen from "../../HomeWork/HomeWorkScreen";
import ItalicTitle from "../../Title/ItalicTitle";
import { UaThisIt } from "../lesson-text-descriptions/UaThisIt";
import { EnThisIt } from "../lesson-text-descriptions/EnThisIt";
import lesson11 from "./lessons/lesson11";
import lesson12 from "./lessons/lesson12";
import lesson13 from "./lessons/lesson13";
import lesson14 from "./lessons/lesson14";
import lesson15 from "./lessons/lesson15";
import lesson16 from "./lessons/lesson16";
import lesson17 from "./lessons/lesson17";
import lesson18 from "./lessons/lesson18";
import lesson19 from "./lessons/lesson19";
import lesson20 from "./lessons/lesson20";
import lesson21 from "./lessons/lesson21";
import lesson22 from "./lessons/lesson22";

export const sounds = {
  ɒ: [
    "Губи повинні бути розслаблені, не округлені і не розтягнуті.",
    "Язик повинен бути опущений до низу ротової порожнини, не торкаючись зубів.",
    'Рот повинен бути відкритий ширше, ніж для звука "а".',
    'Звук короткий, відкритий звук, схожий на українське "о", але з нижчим тоном.',
  ],
  n: [
    "Кінчик язика торкається альвеол: Альвеоли - це горбики за верхніми зубами. Кінчик язика повинен легко торкатися їх",
    'Повітря проходить через ніс: Під час вимови "n" повітря проходить через ніс, а не через рот.',
    "Голосові зв'язки вібрують: Звук \"n\" - це дзвінкий приголосний, тому голосові зв'язки вібрують під час його вимови.",
  ],
  t: [
    "Кінчик язика торкається альвеол: Альвеоли - це горбики за верхніми зубами. Кінчик язика повинен легко торкатися їх.",
    'Повітря затримується, а потім різко випускається: Під час вимови "t" повітря затримується за язиком, а потім різко випускається, створюючи звук "хлопка".',
    "Голосові зв'язки не вібрують: Звук \"t\" - це глухий приголосний, тому голосові зв'язки не вібрують під час його вимови.",
  ],
  e: [
    "Короткий звук",
    "Завжди наголошений",
    "Розслабте губи: Губи повинні бути розслаблені, не округлені і не розтягнуті.",
    "Середнє положення язика: Язик знаходиться в середньому положенні, не торкаючись ні зубів, ні піднебіння.",
    'Відкрийте рот: Рот відкривається трохи, менше, ніж для звуку "а", але більше, ніж для "і".',
    'Вимовте короткий звук: Вимовте короткий, чіткий звук, схожий на "е" в слові "сенс".',
  ],
  p: [
    "Змкнути губи, не дозволяючи повітрю виходити.",
    "Затримайти повітря за зімкнутими губами, створюючи тиск.",
    'Різко розімкнути губи, випускаючи повітря з "хлопком".',
    'Звук "p" - це глухий приголосний, тому голосові зв\'язки не вібрують під час його вимови.',
  ],
  r: [
    "Кінчик язика: Кінчик язика піднімається до альвеол (горбиків за верхніми зубами), але не торкається їх.",
    "Середня частина язика: Середня частина язика злегка вигинається догори, наближаючись до твердого піднебіння.",
    "Губи: Губи розслаблені, не округлені.",
    "Повітря: Повітря проходить вільно між язиком і піднебінням, не створюючи вібрації.",
    'Англійський звук "r" не є вібруючим, як український "р".',
    'Він вимовляється м\'яко, майже як "у" в слові "дуб".',
  ],
  s: [
    "Положення язика: Кінчик язика знаходиться за верхніми зубами, майже торкаючись альвеол (горбків за зубами).",
    "Повітряний потік: Повітря проходить крізь вузький простір між язиком і альвеолами, створюючи свистячий звук.",
    "Голосові зв'язки: Голосові зв'язки не вібрують, оскільки це глухий звук.",
  ],
  d: [
    "Положення язика: Кінчик язика притискається до альвеол (горбків за верхніми зубами).",
    "Повітряний потік: Повітря ненадовго блокується язиком, а потім різко випускається, створюючи проривний звук.",
    "Голосові зв'язки: Голосові зв'язки вібрують, оскільки це дзвінкий звук.",
  ],
  ɔː: [
    'Положення губ: Губи округлені, як при вимові "о", але трохи розтягнуті в сторони.',
    "Положення язика: Задня частина язика піднята до м'якого піднебіння.",
    'Тривалість: Звук вимовляється довго, приблизно вдвічі довше, ніж короткий звук "о".',
  ],
  i: [
    "Положення губ: Губи злегка розтягнуті в сторони.",
    'Положення язика: Середня частина язика піднята до піднебіння, але не так високо, як при вимові звуку "і".',
    "Тривалість: Звук вимовляється коротко.",
  ],
  g: [
    "Положення язика: Задня частина язика піднімається до м'якого піднебіння, блокуючи потік повітря.",
    "Повітряний потік: Повітря ненадовго блокується язиком, а потім різко випускається, створюючи проривний звук.",
    "Голосові зв'язки: Голосові зв'язки вібрують, оскільки це дзвінкий звук.",
  ],
  l: [
    "Положення язика: Кінчик язика притискається до альвеол (горбків за верхніми зубами).",
    "Повітряний потік: Повітря проходить по боках язика, оскільки його середина блокує прямий потік.",
    "Голосові зв'язки: Голосові зв'язки вібрують, оскільки це дзвінкий звук.",
  ],
  ə: [
    "Нейтральний звук: Це означає, що язик і губи перебувають у розслабленому, нейтральному положенні.",
    "Ненаголошений: Цей звук ніколи не буває наголошеним у слові.",
    "Короткий: Він вимовляється дуже коротко і нечітко.",
    "Приклади: about, taken, letter.",
  ],
};

export type ReadToLearnLetter = {
  letter: string;
  word: string;
  trn: string;
  isPrev?: boolean;
  sound: string;
  isHidden?: boolean;
};
export interface ILearnToReadLesson {
  number: number;
  words: string[];
  storyIds: number[] | { id: number; start: number; end: number }[];
  letters: ReadToLearnLetter[];
  alphabet?: string[];
  newLettersCount?: number;
  poemIds?: number[];
  bookImages?: string[];
  newSounsCount?: number;
  storyWithText?: boolean;
  sounds?: string[];
  speakingWords?: string[];
  sentenceImagesNames?: { en: string; ua: string }[];
  title?: any;
  newSounds?: string[];
  content?: any;
}
export const initialLessons: Record<string, ILearnToReadLesson> = {
  1: {
    letters: [
      { letter: "n", sound: "n", word: "not", trn: "|nɒt|" },
      { letter: "o", sound: "ɒ", word: "not", trn: "|nɒt|" },
      { letter: "t", sound: "t", word: "not", trn: "|nɒt|" },
      { letter: "p", sound: "p", word: "pet", trn: "|pet|" },
      { letter: "e", sound: "e", word: "pet", trn: "|pet|" },
      { letter: "e", sound: "ə", word: "sister", trn: "|ˈsɪstər|" },
      { letter: "c", sound: "k", word: "car", trn: "|kɑːr|" },
      { letter: "a", sound: "ɑː", word: "car", trn: "|kɑːr|" },
      { letter: "r", sound: "r", word: "car", trn: "|kɑːr|" },
      { letter: "b", sound: "b", word: "bed", trn: "|bed|" },
      { letter: "d", sound: "d", word: "bed", trn: "|bed|" },
      { letter: "s", sound: "s", word: "star", trn: "|stɑːr|" },
      { letter: "l", sound: "l", word: "little", trn: "|ˈlɪtl|" },
      { letter: "i", sound: "i", word: "little", trn: "|ˈlɪtl|" },
      { letter: "g", sound: "g", word: "egg", trn: "|eɡ|" },
      { letter: "o", sound: "ɔː", word: "sport", trn: "|spɔːrt|" },
    ],
    sounds: ["Oo(ɒ)", "Nn", "Tt", "Ee(e)", "Bb", "Dd", "Ll", "Ii(i)"],
    newLettersCount: 15,
    newSounsCount: 15,
    number: 1,
    title: (
      <>
        Літери <strong>n,o,t,e,b,d,l,i</strong>
      </>
    ),
    storyIds: [],
    words: ["not", "bed", "ten", "little", "bell", "bin", "den", "doll", "dot", "belt", "end"],
    speakingWords: ["not", "little", "bell", "doll", "belt"],
  },
  2.1: {
    letters: [],
    number: 2.1,
    storyIds: [],
    newLettersCount: 0,
    newSounsCount: 0,
    title: "Що таке іменник?",
    words: [],
    content: <Nouns />,
  },
  2.2: {
    letters: [],
    number: 2.2,
    storyIds: [],
    newLettersCount: 0,
    newSounsCount: 0,
    title: "Різниця між дзвінкими та глухими приголосними в українській мові",
    words: [],
    content: <UaDifferenceBetweenVoicedAndUnVoicedLetters />,
  },
  2.3: {
    letters: [],
    number: 2.3,
    storyIds: [],
    newLettersCount: 0,
    newSounsCount: 0,
    title: "Іменники. Множина.",
    words: [
      "pen",
      "top",
      "pet",
      "lip",
      "sit",
      "tennis",
      "lips",
      "beds",
      "best",
      // "boss",
      "dots",
      // "less",
      "nest",
      "pens",
      "pets",
      "bells",
      "dolls",
      "belts",
      "bins",
      "zip",
    ],
    sounds: ["Oo(ɒ)", "Nn", "Tt", "Ee(e)", "Bb", "Dd", "Ll", "Ii(i)", "Pp", "Ss(s)", "Ss(z)", "Zz"],
    newSounds: ["Pp", "Ss(s)", "Ss(z)", "Zz"],
    speakingWords: [
      "top",
      "sit",
      "tennis",
      "lips",
      "beds",
      "best",
      "dots",
      "nest",
      "bells",
      "dolls",
      "belts",
      "zip",
    ],
  },
  3.1: {
    letters: [],
    number: 3.1,
    storyIds: [],
    newLettersCount: 0,
    newSounsCount: 0,
    title: "Ділимо українські слова на склади",
    words: [],
    content: <UaWordsSyllables />,
  },
  3.2: {
    letters: [],
    number: 3.2,
    storyIds: [],
    newLettersCount: 0,
    newSounsCount: 0,
    title: "Ділимо англійські слова на склади",
    words: [],
    content: <EnWordsSyllables />,
  },
  3.3: {
    letters: [],
    number: 3.3,
    storyIds: [],
    newLettersCount: 0,
    newSounsCount: 0,
    title: (
      <Box>
        Літера <strong>i</strong>. Короткий та довгий звук.
      </Box>
    ),

    bookImages: [],
    sentenceImagesNames: [
      { en: "I have a bed.", ua: "Я маю ліжко." },
      { en: "I have a belt.", ua: "Я маю пояс." },
      { en: "I have a desk.", ua: "Я маю письмовий стіл." },
      { en: "I have a disk.", ua: "Я маю диск." },
      { en: "I have a hat.", ua: "Я маю капелюх." },
      { en: "I have a little bell.", ua: "Я маю маленького дзвіночка." },
      { en: "I have a little doll.", ua: "Я маю маленьку ляльку." },
      { en: "I have a pen.", ua: "Я маю ручку." },
      { en: "I have a pineapple.", ua: "Я маю ананас." },
      { en: "I have a van.", ua: "Я маю фургон." },
      { en: "I have an apple.", ua: "Я маю яблуко." },
      { en: "I have bells.", ua: "Я маю дзвіночки." },
      { en: "I have nine apples.", ua: "Я маю девʼять яблук." },
      { en: "I have pens.", ua: "Я маю ручки." },
      { en: "I have nine hats.", ua: "Я маю девʼять шапок." },
      { en: "I like apples.", ua: "Я люблю яблука." },
      { en: "I like it.", ua: "Я люблю це. (Мені це подобається)" },
      { en: "I like pineapples.", ua: "Я люблю ананаси." },
    ],
    sounds: [
      "Oo(ɒ)",
      "Nn",
      "Tt",
      "Ee(e)",
      "Bb",
      "Dd",
      "Ll",
      "Ii(i)",
      "Pp",
      "Ss(s)",
      "Ss(z)",
      "Zz",
      "Aa(æ)",
      "Hh",
      "Vv",
      "Kk",
    ],
    newSounds: ["Aa(æ)", "Hh", "Vv", "Kk"],
    words: [
      "bottle",
      "battle",
      "line",
      "nine",
      "apple",
      "lines",
      "pine",
      "apples",
      "bible",
      "pines",
      "hat",
      "have",
      "pineapple",
      "disk",
      "hats",
      "pineapples",
      "desk",
      "disks",
      "like",
      "van",
      "it",
    ],
    speakingWords: [
      "bottle",
      "battle",
      "line",
      "nine",
      "pine",
      "apple",
      "pineapple",
      "lines",
      "apples",
      "pines",
      "bible",
      "pineapples",
      "desk",
      "like",
      "van",
      "I have a bed.",
      "I have a belt.",
      "I have a desk.",
      "I have a hat.",
      "I have a little bell.",
      "I have a little doll.",
      "I have a pen.",
      "I have a pineapple.",
      "I have a van.",
      "I have an apple.",
      "I have bells.",
      "I have nine apples.",
      "I like apples.",
      "I like it.",
      "I like pineapples.",
    ],
  },
  4: {
    letters: [],
    number: 4,
    storyIds: [],
    newLettersCount: 0,
    newSounsCount: 0,
    title: (
      <Box>
        Літери&nbsp;<strong>m,&nbsp;f,&nbsp;g</strong>.
      </Box>
    ),

    bookImages: [],
    sentenceImagesNames: [
      { en: "I have a dog.", ua: "У мене є собака." },
      { en: "I have a lamb.", ua: "У мене є ягня." },
      { en: "I have five lambs.", ua: "У мене є п'ять ягнят." },
      { en: "I have a lime.", ua: "У мене є лайм." },
      { en: "I have five limes.", ua: "У мене є п'ять лаймів." },
      { en: "I have a kite.", ua: "У мене є повітряний змій." },
      { en: "I have a bike.", ua: "У мене є велосипед." },
      { en: "I have a map.", ua: "У мене є карта." },
      { en: "I have Google Maps.", ua: 'У мене є "Гугл Карти".' },
      { en: "I have a big kite.", ua: "У мене є великий повітряний змій." },
      { en: "I have a big lime.", ua: "У мене є великий лайм." },
      { en: "I have a big dog.", ua: "У мене є великий собака." },
      { en: "I have a pet. It is a lamb.", ua: "У мене є домашня тваринка. Це ягня." },
      { en: "I have a pet. It is a dog.", ua: "У мене є домашня тваринка. Це собака." },
      { en: "I have a pig. It is a big pig.", ua: "У мене є свинка. Це велика свинка." },
      { en: "I have a bike. It is a big bike.", ua: "Я маю велосипед. Це великий велосипед." },
    ],
    sounds: [
      "Oo(ɒ)",
      "Nn",
      "Tt",
      "Ee(e)",
      "Bb",
      "Dd",
      "Ll",
      "Ii(i)",
      "Pp",
      "Ss(s)",
      "Ss(z)",
      "Zz",
      "Aa(æ)",
      "Hh",
      "Vv",
      "Kk",
      "Aa(æ)",
      "Hh",
      "Vv",
      "Kk",
      "Mm",
      "Ff",
      "Gg",
    ],
    newSounds: ["Mm", "Ff", "Gg"],
    words: [
      "bike",
      "five",
      "kite",
      "bikes",
      "lime",
      "life",
      // "mine",
      // "mile",
      "time",
      "lamb",
      "map",
      "maps",
      "big",
      "dog",
      "dogs",
      "pig",
      "milk",
      "is",
      "hi",
    ],
    speakingWords: [
      "bike",
      "five",
      "kite",
      "bikes",
      "lime",
      "life",
      "time",
      "lamb",
      "map",
      "maps",
      "big",
      "dog",
      "dogs",
      "milk",
      "I have a bike. It is a big bike.",
      "I have a dog.",
      "I have a lamb.",
      "I have five lambs.",
      "I have a lime.",
      "I have a bike.",
      "I have a map.",
      "I have Google Maps.",
      "I have a big kite.",
      "I have a big lime.",
      "I have a big dog.",
      "I have a pet. It is a lamb.",
      "I have a pet. It is a dog.",
      "I have a pig. It is a big pig.",
    ],
  },
  5.1: {
    letters: [],
    number: 5.1,
    storyIds: [],
    newLettersCount: 0,
    newSounsCount: 0,
    title: (
      <>
        <strong>Займенники</strong> в українській мові
      </>
    ),
    words: [],
    content: <UaThisIt />,
  },
  5.2: {
    letters: [],
    number: 5.2,
    storyIds: [],
    newLettersCount: 0,
    newSounsCount: 0,
    title: (
      <>
        Різниця між <strong>this / it</strong>
      </>
    ),
    words: [],
    content: <EnThisIt />,
  },
  5.4: {
    letters: [],
    number: 5.4,
    storyIds: [1753],
    newLettersCount: 0,
    newSounsCount: 0,
    title: <Box>This, th, oo, ee</Box>,
    sounds: [
      "Oo(ɒ)",
      "Nn",
      "Tt",
      "Ee(e)",
      "Bb",
      "Dd",
      "Ll",
      "Ii(i)",
      "Pp",
      "Ss(s)",
      "Ss(z)",
      "Zz",
      "Aa(æ)",
      "Hh",
      "Vv",
      "Kk",
      "Aa(æ)",
      "Hh",
      "Vv",
      "Kk",
      "Mm",
      "Ff",
      "Gg",
      "Mm",
      "Ff",
      "Gg",
      "Cc(k)",
      "Rr",
      "oo(uː)",
      "ee(i:)",
      "th(ð)",
    ],
    newSounds: ["Cc(k)", "Rr", "oo(uː)", "ee(i:)", "th(ð)"],
    words: [
      "cat",
      "see",
      "tree",
      "this",
      "root",
      // "has",
      "roots",
      "seven",
      "ran",
      "and",
      "room",
      "bag",
      "need",
      "in",
      "pink",
      "red",
      "banana",
      "cats",
      "on",
      "green",
      "goose",
      "geese",
      "plan",
      "bedroom",
      "bananas",
      "pan",
      "pans",
      "man",
      "fan",
    ],
    speakingWords: [
      "I have a cat",
      "I see",
      "I see a tree",
      "this is a dog",
      // "this tree has roots",
      "a dog ran to a cat",
      "a dog and a cat",
      "I have a room",
      "I have a bag",
      "I need it",
      "a pink bag",
      "a red bag",
      "a big banana",
      "cats and dogs",
      "cats sit on a bed",
      "a green bag",
      "I have a goose",
      "I have seven geese",
      "I have a plan",
      "I have a big bedroom",
      "I have five bananas",
    ],
  },
  6: {
    letters: [],
    number: 6,
    storyIds: [1698],
    newLettersCount: 0,
    newSounsCount: 0,
    title: <Box>The, a, an</Box>,
    sounds: [
      "Oo(ɒ)",
      "Nn",
      "Tt",
      "Ee(e)",
      "Bb",
      "Dd",
      "Ll",
      "Ii(i)",
      "Pp",
      "Ss(s)",
      "Ss(z)",
      "Zz",
      "Aa(æ)",
      "Hh",
      "Vv",
      "Kk",
      "Aa(æ)",
      "Hh",
      "Vv",
      "Kk",
      "Mm",
      "Ff",
      "Gg",
      "Mm",
      "Ff",
      "Gg",
      "Cc(k)",
      "Rr",
      "oo(uː)",
      "ee(i:)",
      "th(ð)",
      "oo(ʊ)",
      "ea(i:)",
    ],
    newSounds: ["oo(ʊ)", "ea(i:)"],
    words: ["rat", "mat", "look", "sat", "too", "fat", "coffee", "hot", "tea", "eat"],
    speakingWords: [
      "I have a rat",
      "I have a mat",
      "the cat is fat",
      "the cat sat on the mat",
      "the cat is too fat",
      "the tea is too hot",
      "I like this coffee",
      "this coffee is hot",
      "I like green tea",
      "I like to eat apples",
    ],
  },
  7: {
    letters: [],
    number: 7,
    storyIds: [],
    newLettersCount: 0,
    newSounsCount: 0,
    title: <Box>He, she, be, we....</Box>,
    sounds: [
      "Oo(ɒ)",
      "Nn",
      "Tt",
      "Ee(e)",
      "Bb",
      "Dd",
      "Ll",
      "Ii(i)",
      "Pp",
      "Ss(s)",
      "Ss(z)",
      "Zz",
      "Aa(æ)",
      "Hh",
      "Vv",
      "Kk",
      "Aa(æ)",
      "Hh",
      "Vv",
      "Kk",
      "Mm",
      "Ff",
      "Gg",
      "Mm",
      "Ff",
      "Gg",
      "Cc(k)",
      "Rr",
      "oo(uː)",
      "ee(i:)",
      "th(ð)",
      "oo(ʊ)",
      "ea(i:)",
      "sh(ʃ)",
      "Ww",
      "Oo(əʊ)",
      "Ee(ɪ)",
      "j(dʒ)",
    ],
    newSounds: ["j(dʒ)", "sh(ʃ)", "Oo(əʊ)", "Ww", "Ee(ɪ)"],
    words: [
      "red",
      "eat",
      "van",
      "jeep",
      "jam",
      "trash",
      "sheep",
      "he",
      "has",
      "she",
      "go",
      "shop",
      "we",
      "likes",
      "park",
      "fish",
      "climb",
      "do",
      "climbs",
      "needs",
      "am",
      "hamster",
      "are",
      "lambs",
      "be",
    ],
    speakingWords: [
      "John has a jeep.",
      "John and Jan have a van and a jeep.",
      "This is a big jeep.",
      "Jan likes to eat jam.",
      "I like to eat jam.",
      "John likes to eat apple jam.",
      "A trash bin.",
      "A trash can.",
      "I have a pet. It is a sheep.",
      "He has a pet. It is a cat.",
      "She has a big cat.",
      "He has a fat cat.",
      "The tree has big roots.",
      "She has a pet. It is a rat.",
      "I like to go to the shop.",
      "He has a little cat.",
      "She has a big dog.",
      "He has a big green mat.",
      "We have a goose.",
      "She likes to go to the park.",
      "This man has a big dog.",
      "Dan has a fish.",
      "He likes to eat bananas.",
      "The tree has nine big roots.",
      "She likes to eat apples.",
      "We have nine geese.",
      "We like to go to the park.",
      "She has a cat. It likes to climb a tree.",
      "Cats like to climb a tree.",
      "Dan has a pet. It is a rat.",
      "We need to do it.",
      "She is in the park.",
      "She has a pet. It is a fish.",
      "Look. A cat climbs a tree.",
      "He needs a red pen.",
      "She is in the park.",
      "He likes green tea.",
      "I am in the park.",
      "She is in the shop.",
      "She needs to do it.",
      "Kate has a pet. It is a hamster.",
      "This is an apple tree.",
      "I am in the shop.",
      "She likes coffee.",
      "The man has a fan.",
      "He is in the shop.",
      "She and he like apples.",
      "She has a big bag.",
      "He is in the park.",
      "I have a little hamster. It likes to eat apples.",
      "She has a lamb.",
      "We are in the shop.",
      "This man has a kite.",
      "He needs a green pen and a red pen.",
      "He has five lambs.",
      "I like to be in the park.",
      "She likes hot tea.",
      "He needs to do it.",
      "He likes to be in the park.",
      "We are in the park.",
      "She has a pink bag.",
      "He likes hot coffee.",
      "He likes to eat apples and bananas.",
      "She has nine pens.",
    ],
  },
  8: {
    letters: [],
    number: 8,
    storyIds: [1699],
    newLettersCount: 0,
    newSounsCount: 0,
    title: <Box>w+a, of, no</Box>,
    sounds: [
      "Oo(ɒ)",
      "Nn",
      "Tt",
      "Ee(e)",
      "Bb",
      "Dd",
      "Ll",
      "Ii(i)",
      "Pp",
      "Ss(s)",
      "Ss(z)",
      "Zz",
      "Aa(æ)",
      "Hh",
      "Vv",
      "Kk",
      "Aa(æ)",
      "Hh",
      "Vv",
      "Kk",
      "Mm",
      "Ff",
      "Gg",
      "Mm",
      "Ff",
      "Gg",
      "Cc(k)",
      "Rr",
      "oo(uː)",
      "ee(i:)",
      "th(ð)",
      "oo(ʊ)",
      "ea(i:)",
      "sh(ʃ)",
      "Ww",
      "Oo(əʊ)",
      "Ee(ɪ)",
      "j(dʒ)",
      "Uu(ʌ)",
    ],
    newSounds: ["Uu(ʌ)"],
    words: [
      "hot",
      "climb",
      "tree",
      "bag",
      "eat",
      "sheep",
      "geese",
      "in",
      "man",
      "box",
      "boxes",
      "fox",
      "foxes",
      "mix",
      "mixer",
      "six",
      "text",
      "taxi",
      "next",
      "read",
      "problem",
      "they",
      "animal",
      "animals",
      "run",
      "want",
      "cup",
      // "of",
      "understand",
      "understands",
      "me",
      "walk",
      "wash",
      "dishes",
      "water",
      "glass",
      "wants",
      "swan",
      "cold",
    ],
    speakingWords: [
      "No problem.",
      "I don't have time. I have no time.",
      "I do not have a pen. I have no pen.",
      "I do not have an apple. I have no apple.",
      "We do not have time. We have no time.",
      "They do not have a van. They have no van.",
      "We do not have a jeep. We have no jeep.",
      "Cats and dogs are animals. Sheep and geese are animals too.",
      "I like to run in the park.",
      "I want a cup of tea.",
      "I understand you.",
      "I want a cup of coffee.",
      "She understands me.",
      "He understands me.",
      "This cup of coffee is too hot.",
      "We understand you.",
      "You understand me.",
      "This cup of coffee is too big.",
      "She likes to walk in the park.",
      "He needs to wash the dishes.",
      "I have water in the bag.",
      "I want an apple.",
      "This cup of tea is too hot.",
      "I want a glass of water.",
      "She wants to see a swan.",
      "This glass of water is too cold.",
      "I have a cold.",
      "I have a box.",
      "I don't have a box. I have no box.",
      "I have five boxes.",
      "I have a box of apples.",
      "This is a box of pineapples.",
      "This is a box of limes.",
      "I have a box in the van.",
      "I have a box in the jeep.",
      "This is a big box.",
      "This is a tea box.",
      "This is a coffee box.",
      "This is a box of tea.",
      "This is a box of coffee.",
      "I have six apples.",
      "I want to see a fox.",
      "Look. A fox ran.",
      "Look. A fox in a tree.",
      "Look. A fox climbs a tree.",
      "A fox is an animal.",
      "She has six foxes.",
      "I need to mix it.",
      "I need a mixer to mix it.",
      "I can read this text.",
      "I need to read this text.",
      "I need a taxi.",
      "This box is big.",
      "This text is too big.",
      "This box is big.",
    ],
  },
  9: {
    letters: [],
    number: 9,
    storyIds: [],
    newLettersCount: 0,
    newSounsCount: 0,
    title: <Box>ay, ey, oy</Box>,
    sounds: [
      "Oo(ɒ)",
      "Nn",
      "Tt",
      "Ee(e)",
      "Bb",
      "Dd",
      "Ll",
      "Ii(i)",
      "Pp",
      "Ss(s)",
      "Ss(z)",
      "Zz",
      "Aa(æ)",
      "Hh",
      "Vv",
      "Kk",
      "Aa(æ)",
      "Hh",
      "Vv",
      "Kk",
      "Mm",
      "Ff",
      "Gg",
      "Mm",
      "Ff",
      "Gg",
      "Cc(k)",
      "Rr",
      "oo(uː)",
      "ee(i:)",
      "th(ð)",
      "oo(ʊ)",
      "ea(i:)",
      "sh(ʃ)",
      "Ww",
      "Oo(əʊ)",
      "Ee(ɪ)",
      "j(dʒ)",
      "Uu(ʌ)",
      "Yy(j)",
      "Yy(i)",
      "oy(ɔɪ)",
      "ay(eɪ)",
    ],
    newSounds: ["Yy(j)", "Yy(i)", "oy(ɔɪ)", "ay(eɪ)"],
    words: [
      "every",
      "day",
      "play",
      "sleep",
      "store",
      "zoo",
      "today",
      "hare",
      "with",
      "boy",
      "plays",
      "want",
      "animal",
      "run",
      "walk",
      "dishes",
      "understand",
      "next",
      "need",
      "do",
    ],
    speakingWords: [
      "I eat apples every day.",
      "I play tennis every day.",
      "I sleep every day.",
      "I go to the store every day.",
      "We go to the zoo today.",
      "I need to do it today.",
      "We go to the park today.",
      "They have apples and pineapples.",
      "I want to see a hare in the zoo.",
      "I play with a dog every day.",
      "This boy has a dog.",
      "The boy plays with a dog.",
      "I like to play with a dog.",
    ],
  },
  10: {
    letters: [],
    number: 10,
    storyIds: [1725],
    poemIds: [1826],
    newLettersCount: 0,
    newSounsCount: 0,
    title: <Box>jingle bells</Box>,
    sounds: [
      "Oo(ɒ)",
      "Nn",
      "Tt",
      "Ee(e)",
      "Bb",
      "Dd",
      "Ll",
      "Ii(i)",
      "Pp",
      "Ss(s)",
      "Ss(z)",
      "Zz",
      "Aa(æ)",
      "Hh",
      "Vv",
      "Kk",
      "Aa(æ)",
      "Hh",
      "Vv",
      "Kk",
      "Mm",
      "Ff",
      "Gg",
      "Mm",
      "Ff",
      "Gg",
      "Cc(k)",
      "Rr",
      "oo(uː)",
      "ee(i:)",
      "th(ð)",
      "oo(ʊ)",
      "ea(i:)",
      "sh(ʃ)",
      "Ww",
      "Oo(əʊ)",
      "Ee(ɪ)",
      "j(dʒ)",
      "Uu(ʌ)",
      "Yy(j)",
      "Yy(i)",
      "oy(ɔɪ)",
      "ay(eɪ)",
    ],
    newSounds: [],
    words: [
      "understand",
      "summer",
      // "have",
      "need",
      "dishes",
      "everywhere",
      "there",
      "here",
      "where",
      "high",
      "low",
      // "plane",
      "jump",
      // "hop",
      "run",
      "in",
      "at",
      "every",
      "hot",
      "sun",
      "very",
      "it",
      "today",
      // "animal",
      "slow",
      "can",
      "ring",
      // "fast",
      "runs",
      // "glass",
      // "want",
    ],
    speakingWords: [
      "I can run fast.",
      "He can run fast.",
      "A hare can run fast.",
      "A hare is a fast animal.",
      "This dog is slow.",
      "A hamster is a slow animal.",
      "He runs every day. He is very fast.",
      "A cat can jump high.",
      "The sun is low.",
      "The sun is high.",
      "I can jump high.",
      "The plane is high.",
      "A cat can climb high.",
      "A cat can climb a high tree.",
      "The kite is high.",
      "The tree is high.",
      "The star is high.",
      "She runs in the park every day.",
      "I have a little bell. I can ring it fast and slow.",
      "She has a little bell. She can ring it high and low.",
      "Sit over here.",
      "Look over there.",
      "Stars are everywhere.",
      "This bell can ring.",
      "I run in the park every day.",
    ],
  },
  11: lesson11,
  12: lesson12,
  13: lesson13,
  14: lesson14,
  15: lesson15,
  16: lesson16,
  17: lesson17,
  18: lesson18,
  19: lesson19,
  20: lesson20,
  21: lesson21,
  22: lesson22,
  // 2: {
  //   letters: [
  //     { letter: "a", word: "hat", sound: "æ", trn: "|hæt|" },
  //     { letter: "m", word: "arm", sound: "m", trn: "|ɑːrm|" },
  //     { letter: "h", sound: "h", word: "hand", trn: "|hænd|" },
  //     { letter: "y", sound: "j", word: "yard", trn: "|jɑːrd|" },
  //     { letter: "o", sound: "əʊ", word: "stone", trn: "|stəʊn|" },
  //     { letter: "n", isPrev: true, sound: "n", word: "not", trn: "|nɒt|" },
  //     { letter: "o", isPrev: true, sound: "ɒ", word: "not", trn: "|nɒt|" },
  //     { letter: "t", isPrev: true, sound: "t", word: "not", trn: "|nɒt|" },
  //     { letter: "p", isPrev: true, sound: "p", word: "pet", trn: "|pet|" },
  //     { letter: "e", isPrev: true, sound: "e", word: "pet", trn: "|pet|" },
  //     { letter: "e", isPrev: true, sound: "ə", word: "sister", trn: "|ˈsɪstər|" },
  //     { letter: "c", isPrev: true, sound: "k", word: "car", trn: "|kɑːr|" },
  //     { letter: "a", isPrev: true, sound: "ɑː", word: "car", trn: "|kɑːr|" },
  //     { letter: "r", isPrev: true, sound: "r", word: "car", trn: "|kɑːr|" },
  //     { letter: "b", isPrev: true, sound: "b", word: "bed", trn: "|bed|" },
  //     { letter: "d", isPrev: true, sound: "d", word: "bed", trn: "|bed|" },
  //     { letter: "s", isPrev: true, sound: "s", word: "star", trn: "|stɑːr|" },
  //     { letter: "l", isPrev: true, sound: "l", word: "little", trn: "|ˈlɪtl|" },
  //     { letter: "i", isPrev: true, sound: "i", word: "little", trn: "|ˈlɪtl|" },
  //     { letter: "g", isPrev: true, sound: "g", word: "egg", trn: "|eɡ|" },
  //     { letter: "o", isPrev: true, sound: "ɔː", word: "sport", trn: "|spɔːrt|" },
  //   ],
  //   number: 2,
  //   storyIds: [1697],
  //   // alphabet: [

  //   // ],
  //   newLettersCount: 3,
  //   newSounsCount: 5,
  //   words: [
  //     "no",
  //     "go",
  //     "note",
  //     "stone",
  //     "poet",
  //     "arm",
  //     "art",
  //     "card",
  //     "cat",
  //     "apple",
  //     "hand",
  //     "hat",
  //     "had",
  //     "lamb",
  //     "yes",
  //     "yard",
  //     "hobby",
  //     "Mary",
  //   ],
  // },
  // 3: {
  //   letters: [
  //     { isHidden: true, letter: "", word: "art", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "card", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "no", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "note", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "apple", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "lamb", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "yes", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "Mary", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "poet", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "go", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "hobby", sound: "", trn: "" },
  //     { letter: "f", word: "frog", sound: "f", trn: "|frɒɡ|" },
  //     { letter: "n", word: "ring", sound: "ŋ", trn: "|rɪŋ|" },
  //     { letter: "s", word: "rose", sound: "z", trn: "|rəʊz|" },
  //     { letter: "a", isPrev: true, word: "hat", sound: "æ", trn: "|hæt|" },
  //     { letter: "m", isPrev: true, word: "arm", sound: "m", trn: "|ɑːrm|" },
  //     { letter: "h", isPrev: true, sound: "h", word: "hand", trn: "|hænd|" },
  //     { letter: "y", isPrev: true, sound: "j", word: "yard", trn: "|jɑːrd|" },
  //     { letter: "o", isPrev: true, sound: "əʊ", word: "stone", trn: "|stəʊn|" },
  //     { letter: "n", isPrev: true, sound: "n", word: "not", trn: "|nɒt|" },
  //     { letter: "o", isPrev: true, sound: "ɒ", word: "not", trn: "|nɒt|" },
  //     { letter: "t", isPrev: true, sound: "t", word: "not", trn: "|nɒt|" },
  //     { letter: "p", isPrev: true, sound: "p", word: "pet", trn: "|pet|" },
  //     { letter: "e", isPrev: true, sound: "e", word: "pet", trn: "|pet|" },
  //     { letter: "e", isPrev: true, sound: "ə", word: "sister", trn: "|ˈsɪstər|" },
  //     { letter: "c", isPrev: true, sound: "k", word: "car", trn: "|kɑːr|" },
  //     { letter: "a", isPrev: true, sound: "ɑː", word: "car", trn: "|kɑːr|" },
  //     { letter: "r", isPrev: true, sound: "r", word: "car", trn: "|kɑːr|" },
  //     { letter: "b", isPrev: true, sound: "b", word: "bed", trn: "|bed|" },
  //     { letter: "d", isPrev: true, sound: "d", word: "bed", trn: "|bed|" },
  //     { letter: "s", isPrev: true, sound: "s", word: "star", trn: "|stɑːr|" },
  //     { letter: "l", isPrev: true, sound: "l", word: "little", trn: "|ˈlɪtl|" },
  //     { letter: "i", isPrev: true, sound: "i", word: "little", trn: "|ˈlɪtl|" },
  //     { letter: "g", isPrev: true, sound: "g", word: "egg", trn: "|eɡ|" },
  //     { letter: "o", isPrev: true, sound: "ɔː", word: "sport", trn: "|spɔːrt|" },
  //   ],
  //   number: 3,
  //   storyIds: [
  //     {
  //       id: 1589,
  //       start: 10,
  //       end: 20,
  //     },
  //   ],
  //   newLettersCount: 1,
  //   newSounsCount: 3,
  //   words: [
  //     "hello",
  //     "letter",
  //     "dad",
  //     "daddy",
  //     "as",
  //     "and",
  //     "in",
  //     "fact",
  //     "family",
  //     "frog",
  //     "dog",
  //     "cats",
  //     "dogs",
  //     "nose",
  //     "rose",
  //     "roses",
  //     "mom",
  //     "mommy",
  //     "finger",
  //     "ring",
  //     "song",
  //   ],
  // },
  // 4: {
  //   letters: [
  //     { isHidden: true, letter: "", word: "art", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "card", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "no", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "note", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "apple", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "lamb", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "yes", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "Mary", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "poet", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "go", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "hobby", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "mom", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "mommy", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "dog", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "hello", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "nose", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "as", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "and", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "fact", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "family", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "cats", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "daddy", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "mommy", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "finger", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "song", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "cats", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "roses", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "big", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "continent", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "red", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "leg", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "tennis", sound: "", trn: "" },
  //     { letter: "u", word: "run", sound: "ʌ", trn: "|rʌn|" },
  //     { letter: "w", word: "winter", sound: "w", trn: "|ˈwɪntər|" },
  //     { letter: "e", word: "he", sound: "i:", trn: "|hiː|" },
  //     { letter: "e", word: "here", sound: "ɪ", trn: "|hɪr|" },
  //     { letter: "i", word: "time", sound: "aɪ", trn: "|taɪm|" },
  //     { letter: "f", isPrev: true, word: "frog", sound: "f", trn: "|frɒɡ|" },
  //     { letter: "n", isPrev: true, word: "ring", sound: "ŋ", trn: "|rɪŋ|" },
  //     { letter: "s", isPrev: true, word: "rose", sound: "z", trn: "|rəʊz|" },
  //     { letter: "a", isPrev: true, word: "hat", sound: "æ", trn: "|hæt|" },
  //     { letter: "m", isPrev: true, word: "arm", sound: "m", trn: "|ɑːrm|" },
  //     { letter: "h", isPrev: true, sound: "h", word: "hand", trn: "|hænd|" },
  //     { letter: "y", isPrev: true, sound: "j", word: "yard", trn: "|jɑːrd|" },
  //     { letter: "o", isPrev: true, sound: "əʊ", word: "stone", trn: "|stəʊn|" },
  //     { letter: "n", isPrev: true, sound: "n", word: "not", trn: "|nɒt|" },
  //     { letter: "o", isPrev: true, sound: "ɒ", word: "not", trn: "|nɒt|" },
  //     { letter: "t", isPrev: true, sound: "t", word: "not", trn: "|nɒt|" },
  //     { letter: "p", isPrev: true, sound: "p", word: "pet", trn: "|pet|" },
  //     { letter: "e", isPrev: true, sound: "e", word: "pet", trn: "|pet|" },
  //     { letter: "e", isPrev: true, sound: "ə", word: "sister", trn: "|ˈsɪstər|" },
  //     { letter: "c", isPrev: true, sound: "k", word: "car", trn: "|kɑːr|" },
  //     { letter: "a", isPrev: true, sound: "ɑː", word: "car", trn: "|kɑːr|" },
  //     { letter: "r", isPrev: true, sound: "r", word: "car", trn: "|kɑːr|" },
  //     { letter: "b", isPrev: true, sound: "b", word: "bed", trn: "|bed|" },
  //     { letter: "d", isPrev: true, sound: "d", word: "bed", trn: "|bed|" },
  //     { letter: "s", isPrev: true, sound: "s", word: "star", trn: "|stɑːr|" },
  //     { letter: "l", isPrev: true, sound: "l", word: "little", trn: "|ˈlɪtl|" },
  //     { letter: "i", isPrev: true, sound: "i", word: "little", trn: "|ˈlɪtl|" },
  //     { letter: "g", isPrev: true, sound: "g", word: "egg", trn: "|eɡ|" },
  //     { letter: "o", isPrev: true, sound: "ɔː", word: "sport", trn: "|spɔːrt|" },
  //   ],
  //   number: 4,
  //   storyIds: [
  //     // {
  //     //   id: 1589,
  //     //   start: 10,
  //     //   end: 20,
  //     // },
  //   ],
  //   bookImages: [
  //     "/image-pdf-books/book-3/image-book-3-0.jpg",
  //     "/image-pdf-books/book-3/image-book-3-1.jpg",
  //     "/image-pdf-books/book-3/image-book-3-2.jpg",
  //     "/image-pdf-books/book-3/image-book-3-3.jpg",
  //     "/image-pdf-books/book-3/image-book-3-4.jpg",
  //     "/image-pdf-books/book-3/image-book-3-5.jpg",
  //     "/image-pdf-books/book-3/image-book-3-6.jpg",
  //     "/image-pdf-books/book-3/image-book-3-7.jpg",
  //     "/image-pdf-books/book-3/image-book-3-8.jpg",
  //     "/image-pdf-books/book-3/image-book-3-9.jpg",
  //     "/image-pdf-books/book-3/image-book-3-10.jpg",
  //   ],
  //   newLettersCount: 2,
  //   newSounsCount: 5,
  //   words: [
  //     "I",
  //     "hi",
  //     "tiger",
  //     "spider",
  //     "time",
  //     "winter",
  //     "spring",
  //     "hop",
  //     "can",
  //     "am",
  //     "he",
  //     "we",
  //     "here",
  //     "England",
  //     "summer",
  //     "run",
  //     "butter",
  //     "up",
  //     "cup",
  //   ],
  // },
  // 5: {
  //   letters: [
  //     { isHidden: true, letter: "", word: "this", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "is", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "rat", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "the", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "sat", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "on", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "mat", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "look", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "too", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "fat", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "park", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "bank", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "room", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "bedroom", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "book", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "notebook", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "spoon", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "wood", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "art", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "card", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "no", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "note", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "apple", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "lamb", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "yes", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "Mary", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "poet", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "go", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "hobby", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "mom", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "mommy", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "dog", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "hello", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "nose", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "as", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "and", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "fact", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "family", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "cats", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "daddy", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "mommy", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "finger", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "song", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "cats", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "roses", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "big", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "continent", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "red", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "leg", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "tennis", sound: "", trn: "" },
  //     { letter: "k", word: "park", sound: "k", trn: "|pɑːrk|" },
  //     { letter: "th", word: "this", sound: "ð", trn: "|ðɪs|" },
  //     { letter: "oo", word: "look", sound: "ʊ", trn: "|lʊk|" },
  //     { letter: "oo", word: "too", sound: "uː", trn: "|tuː|" },
  //     { letter: "u", isPrev: true, word: "run", sound: "ʌ", trn: "|rʌn|" },
  //     { letter: "w", isPrev: true, word: "winter", sound: "w", trn: "|ˈwɪntər|" },
  //     { letter: "e", isPrev: true, word: "he", sound: "i:", trn: "|hiː|" },
  //     { letter: "e", isPrev: true, word: "here", sound: "ɪ", trn: "|hɪr|" },
  //     { letter: "i", isPrev: true, word: "time", sound: "aɪ", trn: "|taɪm|" },
  //     { letter: "f", isPrev: true, word: "frog", sound: "f", trn: "|frɒɡ|" },
  //     { letter: "n", isPrev: true, word: "ring", sound: "ŋ", trn: "|rɪŋ|" },
  //     { letter: "s", isPrev: true, word: "rose", sound: "z", trn: "|rəʊz|" },
  //     { letter: "a", isPrev: true, word: "hat", sound: "æ", trn: "|hæt|" },
  //     { letter: "m", isPrev: true, word: "arm", sound: "m", trn: "|ɑːrm|" },
  //     { letter: "h", isPrev: true, sound: "h", word: "hand", trn: "|hænd|" },
  //     { letter: "y", isPrev: true, sound: "j", word: "yard", trn: "|jɑːrd|" },
  //     { letter: "o", isPrev: true, sound: "əʊ", word: "stone", trn: "|stəʊn|" },
  //     { letter: "n", isPrev: true, sound: "n", word: "not", trn: "|nɒt|" },
  //     { letter: "o", isPrev: true, sound: "ɒ", word: "not", trn: "|nɒt|" },
  //     { letter: "t", isPrev: true, sound: "t", word: "not", trn: "|nɒt|" },
  //     { letter: "p", isPrev: true, sound: "p", word: "pet", trn: "|pet|" },
  //     { letter: "e", isPrev: true, sound: "e", word: "pet", trn: "|pet|" },
  //     { letter: "e", isPrev: true, sound: "ə", word: "sister", trn: "|ˈsɪstər|" },
  //     { letter: "c", isPrev: true, sound: "k", word: "car", trn: "|kɑːr|" },
  //     { letter: "a", isPrev: true, sound: "ɑː", word: "car", trn: "|kɑːr|" },
  //     { letter: "r", isPrev: true, sound: "r", word: "car", trn: "|kɑːr|" },
  //     { letter: "b", isPrev: true, sound: "b", word: "bed", trn: "|bed|" },
  //     { letter: "d", isPrev: true, sound: "d", word: "bed", trn: "|bed|" },
  //     { letter: "s", isPrev: true, sound: "s", word: "star", trn: "|stɑːr|" },
  //     { letter: "l", isPrev: true, sound: "l", word: "little", trn: "|ˈlɪtl|" },
  //     { letter: "i", isPrev: true, sound: "i", word: "little", trn: "|ˈlɪtl|" },
  //     { letter: "g", isPrev: true, sound: "g", word: "egg", trn: "|eɡ|" },
  //     { letter: "o", isPrev: true, sound: "ɔː", word: "sport", trn: "|spɔːrt|" },
  //   ],
  //   number: 5,
  //   storyWithText: true,
  //   storyIds: [
  //     {
  //       id: 1698,
  //       start: 10,
  //       end: 174,
  //     },
  //   ],
  //   bookImages: [
  //     // "/image-pdf-books/book-3/image-book-3-0.jpg",
  //     // "/image-pdf-books/book-3/image-book-3-1.jpg",
  //     // "/image-pdf-books/book-3/image-book-3-2.jpg",
  //     // "/image-pdf-books/book-3/image-book-3-3.jpg",
  //     // "/image-pdf-books/book-3/image-book-3-4.jpg",
  //     // "/image-pdf-books/book-3/image-book-3-5.jpg",
  //     // "/image-pdf-books/book-3/image-book-3-6.jpg",
  //     // "/image-pdf-books/book-3/image-book-3-7.jpg",
  //     // "/image-pdf-books/book-3/image-book-3-8.jpg",
  //     // "/image-pdf-books/book-3/image-book-3-9.jpg",
  //     // "/image-pdf-books/book-3/image-book-3-10.jpg",
  //   ],
  //   newLettersCount: 1,
  //   newSounsCount: 4,
  //   words: [
  //     "this",
  //     "is",
  //     "rat",
  //     "the",
  //     "sat",
  //     "on",
  //     "mat",
  //     "look",
  //     "too",
  //     "fat",
  //     "park",
  //     "bank",
  //     "room",
  //     "bedroom",
  //     "book",
  //     "notebook",
  //     "spoon",
  //     "wood",
  //   ],
  // },
  // 6: {
  //   letters: [
  //     { isHidden: true, letter: "", word: "this", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "is", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "rat", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "the", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "sat", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "on", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "mat", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "look", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "too", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "fat", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "park", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "bank", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "room", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "bedroom", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "book", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "notebook", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "spoon", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "wood", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "art", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "card", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "no", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "note", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "apple", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "lamb", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "yes", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "Mary", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "poet", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "go", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "hobby", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "mom", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "mommy", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "dog", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "hello", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "nose", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "as", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "and", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "fact", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "family", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "cats", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "daddy", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "mommy", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "finger", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "song", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "cats", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "roses", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "big", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "continent", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "red", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "leg", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "tennis", sound: "", trn: "" },
  //     { letter: "a", word: "name", sound: "eɪ", trn: "|neɪm|" },
  //     // { letter: "ou", sword: "you", sound: "uː", trn: "|juː|" },
  //     { letter: "o", word: "do", sound: "uː", trn: "|duː|" },
  //     // { letter: "ow", word: "how", sound: "aʊ", trn: "|haʊ|" },
  //     { letter: "k", isPrev: true, word: "park", sound: "k", trn: "|pɑːrk|" },
  //     { letter: "th", isPrev: true, word: "this", sound: "ð", trn: "|ðɪs|" },
  //     { letter: "oo", isPrev: true, word: "look", sound: "ʊ", trn: "|lʊk|" },
  //     { letter: "oo", isPrev: true, word: "too", sound: "uː", trn: "|tuː|" },
  //     { letter: "u", isPrev: true, word: "run", sound: "ʌ", trn: "|rʌn|" },
  //     { letter: "w", isPrev: true, word: "winter", sound: "w", trn: "|ˈwɪntər|" },
  //     { letter: "e", isPrev: true, word: "he", sound: "i:", trn: "|hiː|" },
  //     { letter: "e", isPrev: true, word: "here", sound: "ɪ", trn: "|hɪr|" },
  //     { letter: "i", isPrev: true, word: "time", sound: "aɪ", trn: "|taɪm|" },
  //     { letter: "f", isPrev: true, word: "frog", sound: "f", trn: "|frɒɡ|" },
  //     { letter: "n", isPrev: true, word: "ring", sound: "ŋ", trn: "|rɪŋ|" },
  //     { letter: "s", isPrev: true, word: "rose", sound: "z", trn: "|rəʊz|" },
  //     { letter: "a", isPrev: true, word: "hat", sound: "æ", trn: "|hæt|" },
  //     { letter: "m", isPrev: true, word: "arm", sound: "m", trn: "|ɑːrm|" },
  //     { letter: "h", isPrev: true, sound: "h", word: "hand", trn: "|hænd|" },
  //     { letter: "y", isPrev: true, sound: "j", word: "yard", trn: "|jɑːrd|" },
  //     { letter: "o", isPrev: true, sound: "əʊ", word: "stone", trn: "|stəʊn|" },
  //     { letter: "n", isPrev: true, sound: "n", word: "not", trn: "|nɒt|" },
  //     { letter: "o", isPrev: true, sound: "ɒ", word: "not", trn: "|nɒt|" },
  //     { letter: "t", isPrev: true, sound: "t", word: "not", trn: "|nɒt|" },
  //     { letter: "p", isPrev: true, sound: "p", word: "pet", trn: "|pet|" },
  //     { letter: "e", isPrev: true, sound: "e", word: "pet", trn: "|pet|" },
  //     { letter: "e", isPrev: true, sound: "ə", word: "sister", trn: "|ˈsɪstər|" },
  //     { letter: "c", isPrev: true, sound: "k", word: "car", trn: "|kɑːr|" },
  //     { letter: "a", isPrev: true, sound: "ɑː", word: "car", trn: "|kɑːr|" },
  //     { letter: "r", isPrev: true, sound: "r", word: "car", trn: "|kɑːr|" },
  //     { letter: "b", isPrev: true, sound: "b", word: "bed", trn: "|bed|" },
  //     { letter: "d", isPrev: true, sound: "d", word: "bed", trn: "|bed|" },
  //     { letter: "s", isPrev: true, sound: "s", word: "star", trn: "|stɑːr|" },
  //     { letter: "l", isPrev: true, sound: "l", word: "little", trn: "|ˈlɪtl|" },
  //     { letter: "i", isPrev: true, sound: "i", word: "little", trn: "|ˈlɪtl|" },
  //     { letter: "g", isPrev: true, sound: "g", word: "egg", trn: "|eɡ|" },
  //     { letter: "o", isPrev: true, sound: "ɔː", word: "sport", trn: "|spɔːrt|" },
  //   ],
  //   number: 6,
  //   storyWithText: true,
  //   storyIds: [
  //     {
  //       id: 1589,
  //       start: 10,
  //       end: 174,
  //     },
  //   ],
  //   bookImages: [],
  //   newLettersCount: 0,
  //   newSounsCount: 4,
  //   words: [
  //     "brother",
  //     "group",
  //     "soup",
  //     "you",
  //     "are",
  //     "where",
  //     "baby",
  //     "lady",
  //     "plan",
  //     "name",
  //     "plane",
  //     "cow",
  //     "how",
  //     "down",
  //     "do",
  //     "two",
  //     "one",

  //     // "this",
  //     // "rat",
  //     // "sat",
  //     // "on",
  //     // "mat",
  //     // "look",
  //     // "too",
  //     // "fat",
  //     // "park",
  //     // "room",
  //     // "bedroom",
  //     // "book",
  //     // "notebook",
  //     // "tiger",
  //     // "spider",
  //     // "time",
  //     // "winter",
  //     // "spring",
  //     // "hop",
  //     // "can",
  //     // "he",
  //     // "we",
  //     // "here",
  //     // "England",
  //     // "summer",
  //     // "run",
  //     // "butter",
  //     // "up",
  //     // "cup",
  //     // "and",
  //     // "in",
  //     // "family",
  //     // "frog",
  //     // "finger",
  //     // "ring",
  //     // "song",

  //     // "no",
  //     // "go",
  //     // "note",
  //     // "stone",
  //     // "apple",
  //     // "hand",
  //     // "hat",
  //     // "had",
  //     // "lamb",
  //     // "yes",
  //     // "yard",
  //     // "hobby",

  //     // "not",
  //     // "pen",
  //     // "bed",
  //     // "top",
  //     // "big",
  //     // "pet",
  //     // "star",
  //     // "end",
  //   ],
  // },
  // 7: {
  //   letters: [
  //     { isHidden: true, word: "map", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "cap", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "apple", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "are", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "name", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "sat", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "car", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "plane", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "rat", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "star", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "table", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "mat", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "arm", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "take", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "fat", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "art", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "same", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "bank", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "card", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "case", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "am", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "scar", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "face", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "hand", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "bark", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "race", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "hat", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "tar", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "migrate", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "lamb", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "park", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "late", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "as", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "lark", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "mate", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "and", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "carp", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "plate", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "fact", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "spark", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "state", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "family", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "cart", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "game", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "cat", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "dark", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "blame", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "cats", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "far", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "crane", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "can", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "tart", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "lake", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "had", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "barn", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "wake", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "dad", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "garden", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "cake", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "plan", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "garlic", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "brake", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "task", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "market", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "make", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "parent", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "yard", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "baby", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "carry", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "harm", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "tasty", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "marry", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "mark", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "lady", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "narrow", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "smart", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "crazy", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "aware", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "start", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "lazy", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "carrot", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "farm", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "hazy", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "arrow", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "hard", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "hasty", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "zoo", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "zip", letter: "", sound: "", trn: "" },
  //     { isHidden: true, word: "puzzle", letter: "", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "this", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "is", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "rat", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "the", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "sat", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "on", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "mat", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "look", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "too", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "fat", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "park", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "bank", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "room", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "bedroom", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "book", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "notebook", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "spoon", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "wood", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "art", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "card", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "no", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "note", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "apple", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "lamb", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "yes", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "Mary", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "poet", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "go", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "hobby", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "mom", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "mommy", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "dog", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "hello", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "nose", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "as", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "and", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "fact", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "family", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "cats", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "daddy", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "mommy", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "finger", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "song", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "cats", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "roses", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "big", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "continent", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "red", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "leg", sound: "", trn: "" },
  //     { isHidden: true, letter: "", word: "tennis", sound: "", trn: "" },
  //     { letter: "z", isPrev: true, word: "zoo", sound: "uː", trn: "|zuː|" },
  //     { letter: "a", isPrev: true, word: "name", sound: "eɪ", trn: "|neɪm|" },
  //     { letter: "ou", isPrev: true, word: "you", sound: "uː", trn: "|juː|" },
  //     { letter: "o", isPrev: true, word: "do", sound: "uː", trn: "|duː|" },
  //     { letter: "ow", isPrev: true, word: "how", sound: "aʊ", trn: "|haʊ|" },
  //     { letter: "k", isPrev: true, word: "park", sound: "k", trn: "|pɑːrk|" },
  //     { letter: "th", isPrev: true, word: "this", sound: "ð", trn: "|ðɪs|" },
  //     { letter: "oo", isPrev: true, word: "look", sound: "ʊ", trn: "|lʊk|" },
  //     { letter: "oo", isPrev: true, word: "too", sound: "uː", trn: "|tuː|" },
  //     { letter: "u", isPrev: true, word: "run", sound: "ʌ", trn: "|rʌn|" },
  //     { letter: "w", isPrev: true, word: "winter", sound: "w", trn: "|ˈwɪntər|" },
  //     { letter: "e", isPrev: true, word: "he", sound: "i:", trn: "|hiː|" },
  //     { letter: "e", isPrev: true, word: "here", sound: "ɪ", trn: "|hɪr|" },
  //     { letter: "i", isPrev: true, word: "time", sound: "aɪ", trn: "|taɪm|" },
  //     { letter: "f", isPrev: true, word: "frog", sound: "f", trn: "|frɒɡ|" },
  //     { letter: "n", isPrev: true, word: "ring", sound: "ŋ", trn: "|rɪŋ|" },
  //     { letter: "s", isPrev: true, word: "rose", sound: "z", trn: "|rəʊz|" },
  //     { letter: "a", isPrev: true, word: "hat", sound: "æ", trn: "|hæt|" },
  //     { letter: "m", isPrev: true, word: "arm", sound: "m", trn: "|ɑːrm|" },
  //     { letter: "h", isPrev: true, sound: "h", word: "hand", trn: "|hænd|" },
  //     { letter: "y", isPrev: true, sound: "j", word: "yard", trn: "|jɑːrd|" },
  //     { letter: "o", isPrev: true, sound: "əʊ", word: "stone", trn: "|stəʊn|" },
  //     { letter: "n", isPrev: true, sound: "n", word: "not", trn: "|nɒt|" },
  //     { letter: "o", isPrev: true, sound: "ɒ", word: "not", trn: "|nɒt|" },
  //     { letter: "t", isPrev: true, sound: "t", word: "not", trn: "|nɒt|" },
  //     { letter: "p", isPrev: true, sound: "p", word: "pet", trn: "|pet|" },
  //     { letter: "e", isPrev: true, sound: "e", word: "pet", trn: "|pet|" },
  //     { letter: "e", isPrev: true, sound: "ə", word: "sister", trn: "|ˈsɪstər|" },
  //     { letter: "c", isPrev: true, sound: "k", word: "car", trn: "|kɑːr|" },
  //     { letter: "a", isPrev: true, sound: "ɑː", word: "car", trn: "|kɑːr|" },
  //     { letter: "r", isPrev: true, sound: "r", word: "car", trn: "|kɑːr|" },
  //     { letter: "b", isPrev: true, sound: "b", word: "bed", trn: "|bed|" },
  //     { letter: "d", isPrev: true, sound: "d", word: "bed", trn: "|bed|" },
  //     { letter: "s", isPrev: true, sound: "s", word: "star", trn: "|stɑːr|" },
  //     { letter: "l", isPrev: true, sound: "l", word: "little", trn: "|ˈlɪtl|" },
  //     { letter: "i", isPrev: true, sound: "i", word: "little", trn: "|ˈlɪtl|" },
  //     { letter: "g", isPrev: true, sound: "g", word: "egg", trn: "|eɡ|" },
  //     { letter: "o", isPrev: true, sound: "ɔː", word: "sport", trn: "|spɔːrt|" },
  //   ],
  //   number: 7,
  //   storyWithText: true,
  //   storyIds: [
  //     // {
  //     //   id: 1589,
  //     //   start: 10,
  //     //   end: 174,
  //     // },
  //     {
  //       id: 1698,
  //       start: 10,
  //       end: 174,
  //     },
  //   ],
  //   bookImages: [],
  //   newLettersCount: 1,
  //   newSounsCount: 0,
  //   words: [
  //     "lazy",
  //     "crazy",
  //     "zoo",
  //     "zip",
  //     "puzzle",
  //     "baby",
  //     "lady",
  //     "plane",
  //     "brother",
  //     "group",
  //     "soup",
  //     "you",
  //     "where",
  //     "plan",
  //     "name",
  //     "cow",
  //     "how",
  //     "down",
  //     "do",
  //     "this",
  //     "rat",
  //     "sat",
  //     "on",
  //     "mat",
  //     "look",
  //     "too",
  //     "fat",
  //     "park",
  //     "room",
  //     "bedroom",
  //     "book",
  //     "notebook",
  //     "tiger",
  //     "spider",
  //     "time",
  //     "winter",
  //     "spring",
  //     "hop",
  //     "can",
  //     "he",
  //     "we",
  //     "here",
  //     "England",
  //     "summer",
  //     "run",
  //     "butter",
  //     "up",
  //     "cup",
  //     "and",
  //     "in",
  //     "family",
  //     "frog",
  //     "finger",
  //     "ring",
  //     "song",

  //     "no",
  //     "go",
  //     "note",
  //     "stone",
  //     "apple",
  //     "hand",
  //     "hat",
  //     "had",
  //     "lamb",
  //     "yes",
  //     "yard",
  //     "hobby",

  //     "not",
  //     "pen",
  //     "bed",
  //     "top",
  //     "big",
  //     "pet",
  //     "star",
  //     "end",
  //   ],
  // },
};

export const readToLearnCourseWords = [
  ...Object.values(initialLessons)
    .map((l) => l.words)
    .flat(),
  "to",
  "a",
  "can",
  "Dan",
];

export const LearnToReadScreen: React.FC = () => {
  const [lessonSentences, setLessonSentences] = useState<Sentence[]>([]);
  const [module, setModule] = useState<Module>();
  const [currentModuleWords, setCurrentModuleWords] = useState<Word[]>([]);
  const setGlobalWords = useLessonsStore((state) => state.setGlobalWords);
  const globalWords = useLessonsStore((state) => state.globalWords);
  const authUser = useLessonsStore((state) => state.authUser);
  const grammarModules = useLessonsStore((state) => state.grammarModules);
  const setGrammarModules = useLessonsStore((state) => state.setGrammarModules);
  const [notExistingUserWords, setNotExistingUserWords] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const lessons = useMemo(() => {
    return Object.values(initialLessons).sort((a, b) => b.number - a.number);
    // .reverse();
  }, []);
  // console.log("🚀 ~ lessons:", lessons);

  return (
    <MenuLayout isFluid>
      <Container
        sx={{
          backgroundColor: "white",
          py: 5,
        }}
      >
        {isAdmin(authUser?.id) && (
          <Box sx={{ border: "2px solid red" }} p={2} mb={2}>
            <Typography>Admin</Typography>
            <HStack>
              <Button
                variant="outlined"
                size="small"
                onClick={async () => {
                  navigator.clipboard.writeText(readToLearnCourseWords.join("\n"));
                }}
              >
                Copy all words
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={async () => {
                  const { data: userWords } = await supabaseClient
                    .from("user-words")
                    .select()
                    .eq("user", "53ef5828-71ce-4fdb-bfa1-80d8c4ebd57b");

                  const rowUserWords: any = Array.from(new Set(userWords?.map((w) => w.word)));

                  const notExistingUserWords = readToLearnCourseWords.filter((w) => {
                    if (w === "tv") return false;
                    return !rowUserWords?.includes(w);
                  });
                  setNotExistingUserWords(notExistingUserWords);
                  console.log("🚀 ~ notExistingUserWords:", notExistingUserWords);
                }}
              >
                Check not existing words
              </Button>
              {!!notExistingUserWords.length && (
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  color="warning"
                  size="small"
                  onClick={async () => {
                    setIsLoading(true);

                    const deployResult = await Promise.all(
                      notExistingUserWords.map((w: string) => {
                        return supabaseClient.from("user-words").insert({
                          user: "53ef5828-71ce-4fdb-bfa1-80d8c4ebd57b",
                          id: `53ef5828-71ce-4fdb-bfa1-80d8c4ebd57b-${w}`,
                          word: w,
                        });
                      }),
                    );
                    console.log("🚀 ~ deployResult:", deployResult);

                    setIsLoading(false);
                  }}
                >
                  Deploy words
                </LoadingButton>
              )}
            </HStack>
          </Box>
        )}

        <VStack gap={0} maxWidth={600} alignItems="flex-start">
          <Box>
            <ItalicTitle size={25} text="Домашня робота" />
            <HomeWorkScreen />
          </Box>
          {/* <VStack mb={4}>
            <Typography gutterBottom variant="h3">
              Тренажери української мови
            </Typography>
            <FindVowelLetter />
            <FindConconantLetter />
          </VStack> */}
          {/* <Typography gutterBottom variant="h3">
            Уроки англійської
          </Typography> */}
          {/* {!grammarModules?.length && <Loader />} */}
          <ItalicTitle size={25} text="Всі уроки" />
          {authUser &&
            authUser.read_lesson &&
            lessons
              .filter((l) => authUser!.read_lesson! + 0.9 >= l.number)
              .map((lesson) => {
                const linkText = (
                  <HStack
                    pb={1}
                    alignItems="flex-end"
                    pl={Boolean(lesson.words.length) ? 0 : 4}
                    pt={Boolean(lesson.words.length) ? 2 : 0}
                    width="100%"
                  >
                    {lesson.title ? (
                      <Typography
                        sx={{
                          textDecoration: "underline",
                          position: "relative",
                          width: "100%",
                          display: "block",
                          // backgroundColor: "red",
                        }}
                      >
                        {!Boolean(lesson.words.length) ? (
                          <Box
                            component="span"
                            sx={{
                              textDecoration: "none",
                              position: "absolute",
                              left: -15,
                              fontWeight: 700,
                              top: -2,
                              color: "red",
                            }}
                          >
                            ⧴{" "}
                          </Box>
                        ) : (
                          ""
                        )}
                        {Boolean(lesson.words.length) && (
                          <Typography
                            component="span"
                            color="primary"
                            sx={{
                              fontSize: 12,
                              float: "left",
                              border: "2px solid green",
                              borderRadius: "50%",
                              width: 24,
                              height: 24,
                              mr: 1,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {/* нові слова: &nbsp; */}
                            {lesson.words.length}
                          </Typography>
                        )}
                        {lesson.title}
                      </Typography>
                    ) : (
                      `Урок ${lesson.number}.`
                    )}
                  </HStack>
                );

                // if (!authUser || !authUser.read_lesson || authUser.read_lesson < lesson.number) {
                //   return <Box sx={{ color: "grey" }}>{linkText}</Box>;
                // }

                return (
                  <MuiLink
                    key={lesson.number}
                    color="secondary.dark"
                    sx={{ textDecoration: "none", width: "100%" }}
                    component={Link}
                    display="inline-block"
                    // to={`/grammar/grammar-lesson/${lesson.number}/build-words-list`}
                    to={`/learn-to-read/${lesson.number}`}
                  >
                    {linkText}
                  </MuiLink>
                );
              })}
        </VStack>
      </Container>
    </MenuLayout>
  );
};
