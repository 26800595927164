import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Drawer, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Rule } from "../../EnglishKidsLessonsScreen/CourseProgram";
import { useLessonsStore } from "../../../../store/lessonsStore";
import VStack from "../../../Unknown/VStack";
import { userHomeWorkCurrentDay } from "../../../../common/constants";
import HStack from "../../../Unknown/HStack";
import LinearProgress from "../../../User/LinearProgress";
import ModalCloseButton from "../../../Unknown/ModalCloseButton";
import shuffle from "../../../../common/shuffle";
import ItalicTitle from "../../../Title/ItalicTitle";

function splitByQuotes(str: string) {
  const result = [];
  let currentWord = "";
  let beforeOrLastWords = "";
  let insideQuotes = false;

  for (let i = 0; i < str.length; i++) {
    const char = str[i];

    if (char === "'") {
      insideQuotes = !insideQuotes;
      // currentWord += char; // Add the quote to currentWord
      if (beforeOrLastWords) {
        result.push(beforeOrLastWords);
        beforeOrLastWords = "";
      }
    } else if (!insideQuotes) {
      beforeOrLastWords += char;
      if (currentWord) {
        result.push(currentWord); // Push the word to the result array
        currentWord = ""; // Reset currentWord
      }
    } else {
      currentWord += char; // Add the character to currentWord
    }
  }

  // Add the last word if it exists
  if (currentWord) {
    result.push(currentWord);
  }
  if (beforeOrLastWords) {
    result.push(beforeOrLastWords);
  }

  return result;
}

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
};

export type LessonRule = {
  // id: string;
  lesson: number;
  question: string;
  answer: string;
  // options?: string[];
};

// const lastLessonWithTasks = 6;

const highlight = (text: string, fontWeight?: number, color?: string) => {
  return splitByQuotes(text).map((part, index) => {
    if (index % 2 !== 0) {
      return (
        <ItalicTitle
          key={index}
          // fontWeight={fontWeight}
          text={part}
          color="green"
          size={25}
          isItalic={false}
          fontFamily="Roboto"
        />
      );
    }
    return (
      <ItalicTitle
        key={index}
        size={23}
        color={color || "black"}
        // fontWeight={fontWeight}
        isItalic={false}
        text={part}
      />
    );
  });
};

const Option: React.FC<{ text: string; correctAnswer: string; onAnswer: () => void }> = ({
  text,
  correctAnswer,
  onAnswer,
}) => {
  const [color, setColor] = useState<"inherit" | "error">("inherit");

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (color === "error") {
      timeout = setTimeout(() => {
        setColor("inherit");
      }, 1000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [color]);

  return (
    <Button
      variant="outlined"
      sx={{
        color: color === "error" ? "#ffb0b0" : "black",
        textAlign: "left",
        display: "block",
        border: `3px solid ${color === "error" ? "#ffb0b0" : "#efefef"}`,
        // color: !visible ? "#e3e3e3" : "#a77301",
        transition: "ease border 0.2s",
        "&:hover": {
          border: `3px solid ${color === "error" ? "#ffb0b0" : "#efefef"}`,
        },
        "& > span": {
          // transition: "ease color 0.2s",
          fontSize: 16,
          // color: color === "error" ? "#ffb0b0" : "black",
        },
      }}
      onClick={() => {
        if (correctAnswer === text) {
          onAnswer();
        } else {
          setColor("error");
        }
      }}
    >
      {highlight(text, 400)}
    </Button>
  );
};

const Rules: React.FC<{ rules: Rule[] }> = ({ rules }) => {
  const { lessonId, lessonType } = useParams();
  const lessonModalOpen = useLessonsStore((state) => state.lessonModalOpen === "rules");
  const setLessonModalOpen = useLessonsStore((state) => state.setLessonModalOpen);
  const [taskIndex, setTaskIndex] = useState(0);
  const [nextIsLoading, setNextIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [correctAnswered, setCorrectAnswered] = useState<string>("");
  const [isAnswerd, setIsAnswerd] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const authUser = useLessonsStore((state) => state.authUser);
  const rulesToRepeat = useLessonsStore((state) => state.homeWork.rulesToRepeat);
  const updateHomeWork = useLessonsStore((state) => state.updateHomeWork);
  const homeWork = useLessonsStore((state) => state.homeWork);
  const [lessonTasks, setLessonTasks] = useState<Rule[]>([]);

  const restart = () => {
    setTaskIndex(0);
    setCorrectAnswered("");
    setIsAnswerd(false);
    setIsSuccess(false);
    setIsOptionsVisible(false);

    if (rules) {
      setLessonTasks(rules);
    }
  };

  useEffect(() => {
    restart();
  }, [lessonModalOpen]);

  const currentTask = useMemo(() => {
    if (!lessonTasks) return null;

    return lessonTasks[taskIndex];
  }, [lessonTasks, taskIndex, rulesToRepeat]);

  useEffect(() => {
    let timer: any = null;
    if (currentTask && correctAnswered === currentTask.answer) {
      setIsAnswerd(true);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [correctAnswered, currentTask]);

  const taskWords = useMemo(() => {
    if (!currentTask) return [];
    return [currentTask.answer];
  }, [currentTask]);

  const handleDrawerClose = () => {
    setLessonModalOpen("");
  };
  useEffect(() => {
    if (taskWords.length === 2) {
      setIsOptionsVisible(true);
    } else {
      setIsOptionsVisible(false);
    }
  }, [taskWords]);

  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  if (!lessonTasks || !lessonTasks.length) return null;

  return (
    <>
      {/* <LinearProgress height={4} elementsCount={wordsLength} currentValue={wordIndex} /> */}
      <Button variant="outlined" onClick={() => setLessonModalOpen("rules")}>
        Правила
      </Button>
      <Drawer
        open={lessonModalOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 9,
        }}
      >
        <Box sx={drawerContentStyle}>
          <ModalCloseButton onClose={handleDrawerClose} />
          <LinearProgress height={7} elementsCount={lessonTasks.length} currentValue={taskIndex} />

          {/* {!isSuccess && (
            <Box position="absolute" top={10} left={10}>
              <Typography>
                Запитань залишилось:&nbsp;{" "}
                <ItalicTitle
                  text={(lessonTasks.length - taskIndex).toString()}
                  color="green"
                  size={25}
                />
              </Typography>
            </Box>
          )} */}
          <Box sx={{ backgroundColor: "white" }} py={15} px={3} mb={2} width="100%">
            {/* {isSuccess && (
              <VStack>
                <Typography textAlign="center" pt={2}>
                  Всі завдання виконані&nbsp;🚀
                </Typography>
                <Button
                  variant="outlined"
                  onClick={() => {
                    restart();
                  }}
                >
                  Почати з початку
                </Button>
              </VStack>
            )} */}
            {!isSuccess && currentTask && (
              <VStack>
                <Box mb={4}>
                  <Typography variant="h2" maxWidth={600} textAlign="center">
                    {currentTask.question && <>{highlight(currentTask.question)}</>}
                  </Typography>
                </Box>
                <VStack mb={2}>
                  {correctAnswered && (
                    <>
                      {taskWords.length !== 1 && (
                        <Typography color="primary" variant="h3">
                          Правильно
                        </Typography>
                      )}
                      {/* <Typography>{highlight(correctAnswered, 400, "#8d7536")}</Typography> */}
                      <Typography
                        sx={{
                          pl: 4,
                          pt: 7,
                          borderTop: "3px solid",
                        }}
                      >
                        {highlight(correctAnswered, 400, "#3e2863")}
                      </Typography>
                    </>
                  )}
                </VStack>
                {!isOptionsVisible && taskWords.length !== 2 && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (taskWords.length === 1) {
                        setIsAnswerd(true);
                        setIsOptionsVisible(true);
                        setCorrectAnswered(taskWords[0]);
                      } else {
                        setIsOptionsVisible(true);
                      }
                    }}
                  >
                    {taskWords.length === 1 ? "Показати відповідь" : "Варіанти відповіді"}
                  </Button>
                )}

                {isAnswerd && (
                  <Box py={6}>
                    <LoadingButton
                      variant="outlined"
                      loading={nextIsLoading}
                      onClick={async () => {
                        setCorrectAnswered("");
                        if (taskWords.length !== 2) {
                          setIsOptionsVisible(false);
                        } else {
                          setIsOptionsVisible(true);
                        }
                        if (taskIndex + 1 < lessonTasks.length) {
                          setTaskIndex(taskIndex + 1);
                        } else {
                          setTaskIndex(0);
                        }

                        setIsAnswerd(false);

                        // if (errorCount) {
                        //   setLessonTasks((prev) => [...prev, currentTask]);
                        //   setErrorCount(0);
                        // }
                      }}
                    >
                      Далі
                    </LoadingButton>
                  </Box>
                )}
              </VStack>
            )}
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default Rules;
