import { useState } from "react";
import Button from "../../Unknown/Button";
import HStack from "../../Unknown/HStack";
import TextField from "../../Unknown/TextField";

const AiAskAboutSubjectAndCreatingAStory = () => {
  const [subject, setSubject] = useState("");

  return (
    <HStack>
      <TextField
        sx={{
          width: "400px",
        }}
        placeholder="Subject for a new story"
        value={subject}
        onChange={(e) => {
          setSubject(e.target.value);
        }}
      />
      <Button
        variant="contained"
        color="info"
        onClick={() => {
          navigator.clipboard.writeText(`
        1. I create lessons for kids. For learning English. It is online. I can use a drawing table if needed.
        2. The lesson subject: ${subject}
        3. I need as much as possible ideas for training this subject.

        Give me ideas.

        ps: don't give me: Complete the Sentence, Sorting Game
        `);
        }}
      >
        Ai ask about subject and creating a story
      </Button>
    </HStack>
  );
};

export default AiAskAboutSubjectAndCreatingAStory;
