import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import HStack from "../Unknown/HStack";
import LoadingButton from "@mui/lab/LoadingButton";
import supabaseClient from "../../common/supabaseClient";

const AddYoutubeIdField: React.FC<{ storyId: string; youtubeId?: string }> = ({
  storyId,
  youtubeId,
}) => {
  console.log("🚀 ~ storyId:", storyId);
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (youtubeId) setId(youtubeId);
  }, [youtubeId]);

  return (
    <HStack>
      <TextField
        color="primary"
        value={id}
        size="small"
        placeholder="YouTube id"
        onChange={(event) => {
          setId(event.target.value);
        }}
        type="text"
        variant="outlined"
      />
      <LoadingButton
        loading={loading}
        variant="outlined"
        size="medium"
        color="error"
        onClick={async () => {
          setLoading(true);

          await supabaseClient
            .from("english_verses")
            .update({
              youtube_id: id,
            })
            .eq("id", storyId);

          setLoading(false);
        }}
      >
        update
      </LoadingButton>
    </HStack>
  );
};

export default AddYoutubeIdField;
