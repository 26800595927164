import { IconButton, Link, Tooltip } from "@mui/material";
import Button from "../Button";
import { useLessonsStore } from "../../../store/lessonsStore";
import isAdmin from "../../../common/isAdmin";
import { PropsWithChildren } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HStack from "../HStack";

const CopyTextToClipboard: React.FC<PropsWithChildren> = ({ children }) => {
  const authUser = useLessonsStore((state) => state.authUser);

  if (!isAdmin(authUser?.id)) return null;

  return (
    <>
      <IconButton
        color="info"
        onClick={() => {
          navigator.clipboard.writeText(children as any);
        }}
      >
        <ContentCopyIcon />
      </IconButton>
      {children}
    </>
  );
};

export default CopyTextToClipboard;
