import LastPageIcon from "@mui/icons-material/LastPage";
import FormatLineSpacingIcon from "@mui/icons-material/FormatLineSpacing";
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import { useLessonsStore } from "../../../../store/lessonsStore";
import useGlobalWordsMap from "../../../hooks/useGlobalWordsMap";
import WordExample from "../../../Unknown/LearnWordsSection/WordExample";
import useMountedWaitAsyncEffect from "../../../../hooks/useMountedWaitAsyncEffect";
import { useLocalStorage, usePrevious, useWindowSize } from "react-use";
import isAdmin from "../../../../common/isAdmin";
import { Word } from "../../../Admin/SPTranslationItem";
import supabaseClient from "../../../../common/supabaseClient";
import VStack from "../../../Unknown/VStack";
import FullScreenLoader from "../../../Unknown/FullScreenLoader";
import LinearProgress from "../../../User/LinearProgress";
import ModalCloseButton from "../../../Unknown/ModalCloseButton";
import ControllingStudentPage from "../../../Unknown/Root/ControllingStudentPage";
import WordTranscription from "../../../Word/WordTranscription";
import WordTranslationSection from "../../../Word/WordTranslationSection";
import CopyTextToClipboard from "../../../Unknown/CopyTextToClipboard";
import HStack from "../../../Unknown/HStack";
import AddWordsToCurrentUserLesson from "../AddWordsToCurrentUserLesson";

const isTouchDevice = require("is-touch-device");

const ChangePositionButton: React.FC<
  PropsWithChildren & {
    addToTheEditor?: boolean;
    isPrimary?: boolean;
    isDisabled?: boolean;
    color?: string;
    onClick?: () => void;
  }
> = ({ children, isPrimary, isDisabled, addToTheEditor, color, onClick }) => {
  const editorState = useLessonsStore((state) => state.editorState);
  const setEditorState = useLessonsStore((state) => state.setEditorState);

  return (
    <Box
      component={Paper}
      elevation={2}
      borderRadius={2}
      borderColor="#efefef"
      alignItems="center"
      display="flex"
      px={4}
      py={2}
      gap={2}
      sx={{
        pointerEvents: isDisabled ? "none" : "auto",
        opacity: isDisabled ? 0.3 : 1,
        padding: "5px 13px",
        fontSize: "24px",
        textAlign: "center",
        alignItems: "center",
        cursor: isTouchDevice() ? "auto" : "pointer",
        outline: "none",
        backgroundColor: color || "#fff",
        border: "3px solid #efefef",
        borderRadius: "15px",
        boxShadow: "0 5px #e8e8e8",
        transition: "border .3s ease",
        "&:active, &:focus": {
          outline: "none",
          backgroundColor: "#fff",
          boxShadow: "0 5px #e8e8e8",
          transform: "translateY(2px)",
        },
      }}
      onClick={
        !addToTheEditor && onClick
          ? onClick
          : () => {
              setEditorState(
                `<h2><span class="ql-custom-read-word" style="color: #010231;">${children}</span></h2> ${editorState}`,
              );
              if (onClick) onClick();
            }
      }
    >
      {addToTheEditor ? (
        <IconButton
          sx={{
            top: 0,
            left: 0,
            width: "20px",
            height: "20px",
            pointerEvents: "none",
          }}
        >
          <FormatLineSpacingIcon />
        </IconButton>
      ) : (
        <Typography variant="h3" color={isPrimary ? "primary" : "default"} fontWeight={500}>
          {children}
        </Typography>
      )}
    </Box>
  );
};

const StudentReadCarousel: React.FC = () => {
  const lessonModalOpen = useLessonsStore((state) => state.lessonModalOpen === "learn-words");
  const setLessonModalOpen = useLessonsStore((state) => state.setLessonModalOpen);
  const studentWordsToRead = useLessonsStore((state) => state.studentWordsToRead);

  const handleDrawerClose = () => {
    setLessonModalOpen("");
    // if (onDrawerClose) onDrawerClose();
  };
  // const authUser = useLessonsStore((state) => state.authUser);
  // const [studentUserUuid, setStudentUserUuid] = useState<string | null>(null);

  const [currentWord, nextWord] = useMemo(() => {
    const [currentWord, nextWord] = studentWordsToRead;
    return [currentWord, nextWord || ""];
  }, [studentWordsToRead]);

  const buttonText = useMemo(() => {
    return `Читати слова (${studentWordsToRead?.length})`;
  }, [studentWordsToRead]);

  if (!currentWord) return null;

  return (
    <>
      <Button variant="outlined" onClick={() => setLessonModalOpen("learn-words")}>
        {buttonText}
      </Button>

      <Drawer
        open={lessonModalOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 9,
        }}
      >
        <DrawerContent
          currentWord={currentWord}
          currentWords={studentWordsToRead}
          nextWord={nextWord}
          onClose={handleDrawerClose}
        ></DrawerContent>
      </Drawer>
    </>
  );
};

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
};

const DrawerContent: React.FC<
  {
    currentWord: string;
    currentWords: string[];
    nextWord: string;
    onClose: () => void;
  } & PropsWithChildren
> = ({ children, currentWord: currentRowWord, nextWord, currentWords, onClose }) => {
  const [repeatedWords, setRepeatedWords] = useState<string[]>([]);
  const [isTranslated, setIsTranslated] = useState(false);
  const authUser = useLessonsStore((state) => state.authUser);
  const studentWordsToRead = useLessonsStore((state) => state.studentWordsToRead);
  const setStudentWordsToRead = useLessonsStore((state) => state.setStudentWordsToRead);
  const setPersonalLessonsWords = useLessonsStore((state) => state.setPersonalLessonsWords);
  const drawerContentRef = useRef<any>(null);
  const updateHomeWork = useLessonsStore((state) => state.updateHomeWork);
  const wordsToRepeat = useLessonsStore((state) => state.homeWork.wordsToRepeat);
  const [wordsRepeatMap, setWordsRepeatMap] = useState<Record<string, boolean | undefined>>({});

  const [possibleTranslation, setPossibleTranslation] = useState("");
  const [isPossibleTranslationTrue, setIsPossibleTranslationTrue] = useState(false);
  const [possibleTranslationError, setPossibleTranslationError] = useState(false);
  const isTeacherLesson = useLessonsStore((state) => state.isTeacherLesson);

  const options = useMemo(() => {
    if (!isAdmin(authUser?.id)) return { words: [] };
    return { words: studentWordsToRead || [] };
  }, [studentWordsToRead, authUser]);

  const currentModuleWordsMap = useGlobalWordsMap(options);

  useEffect(() => {
    setPossibleTranslation("");
    setIsPossibleTranslationTrue(false);
    setPossibleTranslationError(false);
  }, [currentRowWord]);

  useEffect(() => {
    if (studentWordsToRead?.length && currentWords.length !== studentWordsToRead.length) {
      const rowWords = studentWordsToRead.map((word) => word.name);

      alert(`Not existing words: ${currentWords.filter((w) => !rowWords.includes(w)).join(", ")}`);
    }
  }, [studentWordsToRead, currentWords]);

  const currentWord = useMemo(() => {
    if (!studentWordsToRead) return null;

    const word = currentModuleWordsMap[currentRowWord];
    if (isAdmin(authUser?.id) && !word) return currentRowWord;
    return word || currentRowWord;
  }, [studentWordsToRead, wordsToRepeat, currentRowWord, authUser, currentModuleWordsMap]);
  console.log("🚀 ~ currentWord:", currentWord, currentRowWord);

  const [userExistingWordsStorage, setUserExistingWordsStorage] = useLocalStorage(
    "user-existing-words",
    JSON.stringify([]),
  );

  useEffect(() => {
    try {
      if (userExistingWordsStorage && !Array.isArray(JSON.parse(userExistingWordsStorage)))
        setUserExistingWordsStorage(JSON.stringify([]));
    } catch (e) {}
  }, [userExistingWordsStorage]);

  const moveToTheEnd = async () => {
    if (!authUser) return;

    const newWords = [...currentWords];
    const firstWord = newWords.shift();
    if (firstWord) newWords.push(firstWord);

    console.log("------ 8");
    setTimeout(async () => {
      setIsTranslated(false);
      setStudentWordsToRead(newWords);
    }, 200);

    await adminControl("end");
  };

  const moveBy3 = async () => {
    const newWords = [...currentWords];
    const firstWord = newWords.shift();
    if (firstWord) newWords.splice(2, 0, firstWord);
    setTimeout(() => {
      setIsTranslated(false);
      setStudentWordsToRead(newWords);
    }, 200);

    await adminControl("3");
  };

  const moveBy5 = async () => {
    const newWords = [...currentWords];
    const firstWord = newWords.shift();
    if (firstWord) newWords.splice(4, 0, firstWord);
    setTimeout(() => {
      setIsTranslated(false);
      setStudentWordsToRead(newWords);
    }, 200);

    await adminControl("5");
  };

  const moveBy12 = async () => {
    const newWords = [...currentWords];
    const firstWord = newWords.shift();
    if (firstWord) newWords.splice(11, 0, firstWord);
    setTimeout(() => {
      setIsTranslated(false);
      setStudentWordsToRead(newWords);
    }, 200);

    await adminControl("12");
  };

  const editorState = useLessonsStore((state) => state.editorState);

  const moveBy20 = async () => {
    const newWords = [...currentWords];
    const firstWord = newWords.shift();
    if (firstWord) newWords.splice(19, 0, firstWord);
    setTimeout(() => {
      setIsTranslated(false);
      setStudentWordsToRead(newWords);
    }, 200);

    await adminControl("20");
  };

  const adminControlRepeatingIndex = useLessonsStore((state) => state.adminControlRepeatingIndex);
  const prevAdminControlRepeatingIndex = usePrevious(adminControlRepeatingIndex);

  useMountedWaitAsyncEffect(
    () => {
      console.log("🚀 ~ period:");
      if (
        authUser &&
        adminControlRepeatingIndex &&
        prevAdminControlRepeatingIndex !== adminControlRepeatingIndex &&
        !isAdmin(authUser?.id)
      ) {
        const period = Math.floor(adminControlRepeatingIndex);
        console.log("🚀 ~ period:", period);
        if (period === 3) {
          moveBy3();
        } else if (period === 5) {
          moveBy5();
        } else if (period === 12) {
          moveBy12();
        } else if (period === currentWords.length) {
          moveToTheEnd();
        }
      }
    },
    [adminControlRepeatingIndex, prevAdminControlRepeatingIndex, authUser],
    0,
  );

  // useEffect(() => {
  //   if (!lessonStoreWords) {
  //     (async () => {
  //       const { data: spWords, error } = await supabaseClient
  //         .from("words")
  //         .select()
  //         .in("name", currentWords)
  //         .returns<Word[]>();

  //       if (!spWords) {
  //         if (error) {
  //           throw error;
  //         } else {
  //           throw new Error("Something went wrong. No Sp words found");
  //         }
  //       }
  //       setPersonalLessonsWords(lessonId, spWords);
  //     })();
  //   }
  // }, [lessonStoreWords, currentWords, lessonId, setPersonalLessonsWords]);

  const { width: windowWidth } = useWindowSize();

  const adminControl = async (type: string) => {
    if (!isAdmin(authUser?.id)) return null;

    const randomNumber = Math.floor(Math.random() * Math.random() * 10000000) / 10000000;
    const [, randomNumberDecimal] = randomNumber.toString().split(".");

    let period = +type;
    if (!type || Number.isNaN(period)) period = currentWords.length;
    await supabaseClient
      .from("admin_control")
      .update({
        // user: studentUserUuid,
        new_repeat_index: `${period}.${randomNumberDecimal}`,
      })
      .eq("id", 0);
  };

  const currentWordName = useMemo(() => {
    return (currentWord as any).name || (currentWord as any);
  }, [currentWord]);

  const [isUserAbleToGoAhead, setIsUserAbleToGoAhead] = useState(false);

  const playerMaxWidth = useMemo(() => {
    if (windowWidth > 500) {
      return 420;
    }
    return windowWidth - 50;
  }, [windowWidth]);

  const drawer = useMemo(() => {
    if (!currentWord)
      return (
        <Box sx={drawerContentStyle}>
          <FullScreenLoader />
        </Box>
      );

    return (
      <Box sx={drawerContentStyle} ref={drawerContentRef}>
        <LinearProgress
          elementsCount={currentWords.length - 1}
          currentValue={repeatedWords.length}
        />
        {children}
        <ModalCloseButton onClose={onClose} />
        {/* <Settings
          isUserAbleToGoAhead={isUserAbleToGoAhead}
          setIsUserAbleToGoAhead={setIsUserAbleToGoAhead}
        /> */}
        <VStack
          px={5}
          justifyContent="center"
          position="relative"
          width="100vw"
          flexWrap="nowrap"
          pt={20}
          pb={10}
          sx={{
            overflowX: "hidden",
            // backgroundColor: "red",
          }}
        >
          <HStack flexWrap="nowrap" alignItems="flex-start">
            <Box maxWidth="50%">
              <AddWordsToCurrentUserLesson />
              <ControllingStudentPage isNested />
            </Box>
            <VStack width="500px" pt={60}>
              <Typography variant="h2" color="primary.main" gutterBottom>
                <CopyTextToClipboard>
                  {(currentWord as any)?.name || currentWord}
                </CopyTextToClipboard>
              </Typography>
              {isAdmin(authUser?.id) && (
                <VStack position="relative" sx={{ width: "100%", overflowX: "hidden", pb: 5 }}>
                  {/* <Button
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${(currentWord as any).name || (currentWord as any)}`,
                  );
                }}
              >
                copy
              </Button> */}
                  <WordTranscription
                    isReadToLearn={true}
                    word={
                      typeof currentWord === "string"
                        ? ({ name: currentWord } as any)
                        : (currentWord as any)
                    }
                    play={isTranslated}
                    autoplay
                    small
                  />
                  <WordTranslationSection
                    translation={(currentWord as any)?.translation || ""}
                    short
                    sentences={[]}
                    isVisible
                    examples={false}
                  />

                  <VStack py={3} justifyContent="center" alignItems="center" width={300}>
                    {!isTeacherLesson &&
                      !wordsRepeatMap[(currentWord as any)?.name || (currentWord as any)] && (
                        <>
                          <HStack>
                            <ChangePositionButton color="#ffa0a0" onClick={moveBy3}>
                              3
                            </ChangePositionButton>
                            <ChangePositionButton addToTheEditor onClick={moveBy3}>
                              {nextWord}
                            </ChangePositionButton>
                          </HStack>
                          <HStack>
                            <ChangePositionButton onClick={moveBy5}>5</ChangePositionButton>
                            <ChangePositionButton addToTheEditor onClick={moveBy5}>
                              {nextWord}
                            </ChangePositionButton>
                          </HStack>
                          <HStack>
                            <ChangePositionButton onClick={moveBy12}>12</ChangePositionButton>
                            <ChangePositionButton addToTheEditor onClick={moveBy12}>
                              {nextWord}
                            </ChangePositionButton>
                          </HStack>
                          <HStack>
                            <ChangePositionButton onClick={moveBy20}>20</ChangePositionButton>
                            <ChangePositionButton addToTheEditor onClick={moveBy20}>
                              {nextWord}
                            </ChangePositionButton>
                          </HStack>
                        </>
                      )}

                    {((isTeacherLesson && isUserAbleToGoAhead) || isAdmin(authUser?.id)) && (
                      <HStack>
                        <ChangePositionButton onClick={moveToTheEnd}>далі</ChangePositionButton>
                        <ChangePositionButton addToTheEditor onClick={moveToTheEnd}>
                          {nextWord}
                        </ChangePositionButton>
                      </HStack>
                    )}
                  </VStack>
                </VStack>
              )}
            </VStack>
          </HStack>
        </VStack>
      </Box>
    );
  }, [
    authUser,
    currentWord,
    currentWords,
    isTranslated,
    repeatedWords,
    currentRowWord,
    wordsRepeatMap,
    isUserAbleToGoAhead,
    possibleTranslationError,
    possibleTranslation,
    editorState,
    isPossibleTranslationTrue,
    onClose,
  ]);

  return drawer;
};

export default StudentReadCarousel;
