const rules18 = [
  {
    id: "171",
    questionUa: "Що таке 'youʼre'?",
    correctAnswer: " 'youʼre' це скорочення від 'you are' що означає 'ти є'",
  },
  {
    id: "170",
    questionUa: "Коли ми кажемо 'youʼre welcome'?",
    correctAnswer: "Коли нам подякували і ми хочемо сказати 'будь ласка' або ж 'прошу'",
  },
  {
    id: "169",
    questionUa: "Коли ми кажемо 'bless you'?",
    correctAnswer:
      "Коли хтось чихає і ми хочемо сказати 'будь здоровий', 'будь здоровa' або ж 'будьте здорові'",
  },
  {
    id: "168",
    questionUa: "У чому різниця між 'many', 'much' та 'a lot'?",
    correctAnswer:
      " 'many' вживається тільки з тим що ми можемо порахувати, 'much' вживається тільки з тим що ми НЕ можемо порахувати. 'a lot' - супер слово яке працює в обох випадках.",
  },
  {
    id: "172",
    questionUa: "У чому різниця між 'round' і 'around'",
    correctAnswer:
      " 'round' це 'круглий', а 'around' це 'навколо'. 'around' = 'a' + 'round' = 'одне коло' = 'навколо'",
  },
  {
    id: "173",
    questionUa: "Чому літера 'a' читається як українські дві літери 'ей' у слові 'rain'?",
    correctAnswer:
      "Тому що після літери 'a' літера 'i' яка є голосною, це змінює попередній голосний звук з короткого на довгий",
  },
  {
    id: "174",
    questionUa:
      "Чому літера 'o' читається як українські дві літери 'oу' у слові 'stone', а не просто 'o' як у слові 'not'?",
    correctAnswer:
      "Тому що у кінці слова літера 'e' яка змінює попередній голосний звук з короткого на довгий",
  },
  {
    id: "175",
    questionUa:
      "Чому літера 'a' читається як 'ей' у словах 'make', 'made' а не як 'е' як у словах 'apple, cat, can...'?",
    correctAnswer:
      "Тому що у кінці слова літера 'e' яка змінює попередній голосний звук з короткого на довгий",
  },
  {
    id: "176",
    questionUa: "Як читається літера 'і' у словах 'birth', 'birthday', 'circle'?",
    correctAnswer:
      "Літера 'i' перед літерою 'r' часто звучить як український мʼякий знак + літера 'o'. 'birth = бьорс', 'circle = сьоркл'",
  },
  {
    id: "177",
    questionUa:
      "Чому літера 'a' читається як 'o' у слові 'small' а не як 'е' як у словах 'apple, cat, can...'?",
    correctAnswer:
      "Літера 'a' зазвичай читається як 'o' якщо вона знаходиться перед двома літерами 'll'",
  },
  {
    id: "178",
    questionUa: "Як читаються літери 'ou' у словах 'round', 'down', 'our'?",
    correctAnswer: "Вимовляється як українські дві літери 'a' і 'у', тобто 'ау'",
  },
  {
    id: "179",
    questionUa: "Яка повна форма словосполучення 'letʼs', та що воно означає?",
    correctAnswer:
      "Повна форма 'let us' що перекладається 'дозволь нам'. Наприклад 'letʼs go' що дослівно означає 'дозволь нам піти', або ж просто 'давай ідемо'",
  },
];

export default rules18;
