import { Button, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { adminUserId } from "../../../common/constants";
import { useLessonsStore } from "../../../store/lessonsStore";
import VStack from "../VStack";

interface NavigationButtonProps {
  to: string;
  icon?: any;
  label: string;
  isActive?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
}

interface SideBarNavigationButtonProps {
  to: string;
  icon?: any;
  label: string;
  isActive?: boolean;
  isVisible?: boolean;
  onClick?: () => void;
}

export const SideBarNavigationButton: React.FC<SideBarNavigationButtonProps> = ({
  to,
  label,
  isActive,
  isVisible,
  onClick,
}) => {
  const navigate = useNavigate();
  // const location = useLocation();

  const handleClick = () => {
    if (onClick) onClick();
    // console.log("🚀 ~ location:", location);
    // if (location.pathname.startsWith("/difference/")) {
    // }
    navigate(to);
  };

  return (
    <Button
      variant="text"
      onClick={handleClick}
      color={isActive ? "primary" : "inherit"}
      sx={{
        width: "100%",
        display: "block",
        fontSize: 17,
        textAlign: "left",
        lineHeight: "1.2",
        textDecorationLine: isVisible === false ? "inherit" : "underline",
        opacity: isVisible === false ? 0.5 : 1,
        pointerEvents: isVisible === false ? "none" : "auto",
      }}
    >
      {label}
    </Button>
  );
};

export const NavigationButton: React.FC<NavigationButtonProps> = ({
  to,
  icon,
  label,
  isActive,
  fullWidth,
  onClick,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) onClick();
    navigate(to);
  };

  return (
    <Button
      variant={isActive ? "contained" : "outlined"}
      onClick={handleClick}
      color={isActive ? "primary" : "inherit"}
      sx={{
        // color: isActive ? "green" : "default",
        width: fullWidth ? "100%" : "120px",
        lineHeight: "1.2",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography component="span" fontSize={20}>
        {icon}
      </Typography>
      {label}
    </Button>
  );
};

export const SideBarMainNavigation: React.FC = () => {
  const isPersonalLesson = useLessonsStore((state) => state.isPersonalLesson);
  const authUser = useLessonsStore((state) => state.authUser);
  const location = useLocation();
  const isAdmin = authUser?.id === adminUserId;

  return (
    <VStack py={2} width="100%">
      <SideBarNavigationButton
        to="/sentences"
        label="Повторення речень"
        isActive={location.pathname === "/sentences"}
      />
      <SideBarNavigationButton
        to="/words"
        label="Повторення слів"
        isActive={location.pathname === "/words"}
      />
      <SideBarNavigationButton
        to="/grammar"
        label="Базова граматика англійської мови"
        isActive={location.pathname === "/grammar"}
      />
      <SideBarNavigationButton
        to="/learn-to-read"
        label="Вчитися читати"
        isActive={location.pathname === "/learn-to-read"}
      />
      <SideBarNavigationButton
        to="/lessons"
        label="Уроки"
        isActive={location.pathname === "/lessons"}
      />
      <SideBarNavigationButton
        to="/english-lessons"
        label="Уроки англійської"
        isActive={location.pathname === "/english-lessons"}
      />
      {isPersonalLesson && (
        <>
          <SideBarNavigationButton
            to="/difference"
            label="Різниця між словами"
            isActive={location.pathname.startsWith("/difference")}
          />
          <SideBarNavigationButton
            to="/personal-lessons"
            label="Персональні уроки"
            isActive={location.pathname === "/personal-lessons"}
          />
          <SideBarNavigationButton
            to="/personal-dialogs"
            label="Діалоги"
            isActive={location.pathname === "/personal-dialogs"}
          />
          <SideBarNavigationButton
            to="/reading"
            label="Читання"
            isActive={location.pathname === "/reading"}
          />
        </>
      )}
      {isAdmin && (
        <>
          <SideBarNavigationButton
            to="/admin"
            label="Admin"
            isActive={location.pathname === "/admin"}
          />
          <SideBarNavigationButton
            to="/bangla-sentences"
            label="Bangla Sentences"
            isActive={location.pathname === "/bangla-sentences"}
          />
          <SideBarNavigationButton
            to="/sanskrit"
            label="Sanskrit Words"
            isActive={location.pathname === "/sanskrit"}
          />
          <SideBarNavigationButton
            to="/create-personal-lesson"
            label="Створити персональний урок"
            isActive={location.pathname === "/create-personal-lesson"}
          />
          <SideBarNavigationButton
            to="/create-difference-lesson"
            label="Створити difference урок"
            isActive={location.pathname === "/create-difference-lesson"}
          />
          <SideBarNavigationButton
            to="/bangla-grammar"
            label="Bengali grammar"
            isActive={location.pathname === "/bangla-grammar"}
          />
          <SideBarNavigationButton
            to="/timer"
            label="Timer"
            isActive={location.pathname === "/timer"}
          />
        </>
      )}
      {(isAdmin || authUser?.uuid === "86523831-4881-4b89-91b5-def185deba6e") && (
        <SideBarNavigationButton
          to="/food-tracking"
          label="Food tracking"
          isActive={location.pathname === "/food-tracking"}
        />
      )}
      {(isAdmin || authUser?.uuid === "86523831-4881-4b89-91b5-def185deba6e") && (
        <SideBarNavigationButton
          to="/declarations"
          label="Декларації"
          isActive={location.pathname === "/declarations"}
        />
      )}
    </VStack>
  );
};
