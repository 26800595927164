import { createClient } from "@supabase/supabase-js";

let supabaseDrawingClient = createClient(
  `${process.env.REACT_APP_DRAWING_SUPABASE_URL}`,
  // "https://rjrjsfbijrnincmyibxa.supabase.co",
  `${process.env.REACT_APP_DRAWING_SUPABASE_KEY}`,
  // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJqcmpzZmJpanJuaW5jbXlpYnhhIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcwODY4MTIwNSwiZXhwIjoyMDI0MjU3MjA1fQ.rjcQNSmsggaPL2H2VnUrg6ZmqwUntScyzg_3Sc0Kb2Q",
  // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJqcmpzZmJpanJuaW5jbXlpYnhhIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDg2ODEyMDUsImV4cCI6MjAyNDI1NzIwNX0.PBAIklSmyoGC5Ryh0CPZntVDplOaC1W1vqj8mpeWqV8",
);

export default supabaseDrawingClient;
