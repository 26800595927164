import LoadingButton from "@mui/lab/LoadingButton";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import AirIcon from "@mui/icons-material/Air";

import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import StorageIcon from "@mui/icons-material/Storage";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import getWordLevelColor from "../../common/getWordLevelColor";
import supabaseClient from "../../common/supabaseClient";
import useCreateAudio from "../../common/useCreateAudio";
import { useLessonsStore } from "../../store/lessonsStore";
import CopyNewWordsForGemini from "../Admin/CopyNewWordsForGemini";
import MenuLayout from "../Layout/MenuLayout";
import HStack from "../Unknown/HStack";
import storiesData from "./stories";
import useGlobalWords from "../hooks/getGlobalWords";
import PdfModal from "../Unknown/PdfModal";
import getSentenceWords from "../../common/getSentenceWords";
import removePunctuationFromString from "../../common/removePunctuationFromString";
import HoverDetails from "../Word/HoverDetails";
import useGlobalWordsMap from "../hooks/useGlobalWordsMap";
import ReactPlayer from "react-player";
import VStack from "../Unknown/VStack";
import DeleteIcon from "@mui/icons-material/Delete";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import AddIcon from "@mui/icons-material/Add";
import {
  createUserLesson,
  getUserLesson,
  updateUserLesson,
} from "../Unknown/Root/ControllingStudentPage";
import ItalicTitle from "../Title/ItalicTitle";
import AiQuestionAnswer from "./AiQuestionAnswer";
import LoadExistingQuestionAndAnswer from "./LoadExistingQuestionAndAnswer";
import ExternalLinkVideo from "../Unknown/ExternalLinkVideo";
import CopyStoryIdButton from "../Unknown/CopyStoryIdButton";
import { TextPartVideoByStartAndEndButton } from "../Unknown/TextPartVideoByStartAndEnd";
import isAdmin from "../../common/isAdmin";
import AddYoutubeIdField from "./AddYoutubeIdField";

const AddUserLessonWord: React.FC<{ word: string; storyId: string; storyWords: string[] }> = ({
  word,
  storyId,
  storyWords,
}) => {
  const [w, setW] = useState(word);
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <Box position="relative">
      <TextField
        color="primary"
        value={w}
        sx={{
          // width: `${word.length * (word.length < 6 ? 45 : 20)}px`,
          width: "110px",
          pr: "10px",
        }}
        onChange={(event) => {
          setW(event.target.value);
        }}
        type="text"
        variant="outlined"
      />
      <IconButton
        color="error"
        sx={{ position: "absolute", top: -10, right: -10, zIndex: 1 }}
        onClick={async () => {
          const { data: story } = await supabaseClient
            .from("english_verses")
            .select()
            .eq("id", storyId)
            .single();

          if (!story.words) {
            alert("story doesn't have words");
            return;
          }

          await supabaseClient
            .from("english_verses")
            .update({ words: story.words.filter((w: string) => w !== word) })
            .eq("id", storyId);

          setIsVisible(false);
        }}
      >
        <DeleteIcon />
      </IconButton>
      <IconButton
        color="primary"
        sx={{ position: "absolute", top: 20, right: -10, zIndex: 1 }}
        onClick={async () => {
          const { data: story } = await supabaseClient
            .from("english_verses")
            .select()
            .eq("id", storyId)
            .single();

          if (!story.words) {
            alert("story doesn't have words");
            return;
          }

          await supabaseClient
            .from("english_verses")
            .update({
              words: story.words.map((sw: string) => {
                if (sw === word) return w;
                return sw;
              }),
            })
            .eq("id", storyId);

          setIsVisible(false);
        }}
      >
        <UpgradeIcon />
      </IconButton>
    </Box>
  );
};
const AddUserLesson: React.FC<{ newWords: string[]; storyId: string; storyWords: string[] }> = ({
  newWords,
  storyId,
  storyWords,
}) => {
  return (
    <Box>
      {/* <Button
        variant="contained"
        onClick={async () => {
          const { data: story } = await supabaseClient
            .from("english_verses")
            .select()
            .eq("id", storyId)
            .single();
          // console.log("words", storyWords);
        }}
      >
        add all words for user
      </Button> */}
      <HStack>
        {newWords?.map((word) => {
          return <AddUserLessonWord word={word} storyId={storyId} storyWords={storyWords} />;
        })}
      </HStack>
    </Box>
  );
};

const MakeItActiveForUser: React.FC<{ storyId: number }> = ({ storyId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const studentUser = useLessonsStore((state) => state.studentUser);

  return (
    <LoadingButton
      loading={isLoading}
      variant="contained"
      color="info"
      onClick={async () => {
        if (!studentUser) {
          alert("no user found");
          return;
        }

        setIsLoading(true);

        await supabaseClient.from("admin_control").update({ active_video_id: storyId }).eq("id", 0);

        setIsLoading(false);
      }}
    >
      <Tooltip title="Make it active for user">
        <AirplanemodeActiveIcon />
      </Tooltip>
    </LoadingButton>
  );
};

const AddToUser: React.FC<{ storyId: number; type: "prev" | "ignore" }> = ({ storyId, type }) => {
  const [isLoading, setIsLoading] = useState(false);
  const studentUser = useLessonsStore((state) => state.studentUser);
  const newUserLesson = useLessonsStore((state) => state.newUserLesson);
  const setNewUserLesson = useLessonsStore((state) => state.setNewUserLesson);
  // const [done, setDone] = useState(false)

  // if (done) return null;
  if (newUserLesson?.video_ids?.includes(Number(storyId))) return null;

  return (
    <LoadingButton
      loading={isLoading}
      variant="contained"
      color={type === "prev" ? "primary" : "error"}
      onClick={async () => {
        if (!studentUser) {
          alert("no user found");
          return;
        }

        console.log("type", type);
        setIsLoading(true);
        if (type === "prev") {
          const { data: studentDbUser } = await supabaseClient
            .from("users")
            .select()
            .eq("id", studentUser.id)
            .single();
          console.log("storyId", storyId);
          if (!studentDbUser) return;
          const prevVideosIds = [...(studentDbUser.prev_video_ids || []), storyId];

          const resultUpdateUserWihPrevVideoIds = await supabaseClient
            .from("users")
            .update({
              prev_video_ids: prevVideosIds,
            })
            .eq("id", studentUser.id);

          console.log("resultUpdateUserWihPrevVideoIds", resultUpdateUserWihPrevVideoIds);

          // today user lesson
          const userLesson = await getUserLesson(studentUser.id);
          if (userLesson) {
            await updateUserLesson({
              user: studentUser.id,
              video_ids: [...(userLesson.video_ids || []), storyId].map((id) => Number(id)),
              words: [...(userLesson?.words || [])],
            });
          } else {
            await createUserLesson({ user: studentUser.id, video_ids: [storyId] });
          }

          let updatedOrCreatedUserLesson = await getUserLesson(studentUser.id);
          setNewUserLesson(updatedOrCreatedUserLesson);
        } else if (type === "ignore") {
          const { data: studentDbUser } = await supabaseClient
            .from("users")
            .select()
            .eq("id", studentUser.id)
            .single();
          console.log("🚀 ~ studentDbUser:", studentDbUser);
          if (!studentDbUser) return;
          const ignoreVideoIds = [...(studentDbUser.ignore_video_ids || []), storyId].map((id) =>
            Number(id),
          );
          console.log("🚀 ~ prevVideosIds:", ignoreVideoIds);
          const updateResult = await supabaseClient
            .from("users")
            .update({
              ignore_video_ids: ignoreVideoIds,
            })
            .eq("id", studentUser.id);

          console.log("updateResult", updateResult);
        }
        setIsLoading(false);
      }}
    >
      {type === "prev" && (
        <Tooltip title="Add to user video ids">
          <PersonAddIcon />
        </Tooltip>
      )}
      {type !== "prev" && (
        <Tooltip title="Add to ignore video ids">
          <PersonRemoveIcon />
        </Tooltip>
      )}
      {/* {type === "prev" ? "Add to user video ids" : "Add to ignore video ids"} */}
    </LoadingButton>
  );
};

export const Story: React.FC<{
  lazyLoading?: boolean;
  story: any;
  endless?: boolean;
  onUpdateStories: () => void;
}> = ({ story, endless, lazyLoading, onUpdateStories }) => {
  const setGlobalWords = useLessonsStore((state) => state.setGlobalWords);
  const globalWords = useLessonsStore((state) => state.globalWords);
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { createAudio } = useCreateAudio();
  const [storyText, setStoryText] = useState(story.id);
  const studentWords = useLessonsStore((state) => state.studentWords);
  const [toLoad, setToLoad] = useState(!lazyLoading);
  const [storyType, setStoryType] = useState(story.type);

  useEffect(() => {
    setStoryText(story.id);
  }, [story.id]);

  const options = useMemo(() => {
    if (!toLoad) return { words: [] };
    return { words: story.words || [] };
  }, [story.words, toLoad]);

  const curerntModuleWordsMap = useGlobalWordsMap(options);

  return (
    <Box key={story.id} mb={3} width="100%">
      <hr style={{ border: "5px solid" }} />
      {story.author && (
        <Button
          component="a"
          target="_blank"
          href={`https://www.google.com/search?q=${story.author}`}
        >
          {story.author}
        </Button>
      )}
      {lazyLoading && (
        <Button
          variant="contained"
          color="info"
          onClick={() => {
            setToLoad(true);
          }}
        >
          Load words
        </Button>
      )}
      <TextField
        color="primary"
        value={storyText}
        multiline={true}
        sx={{
          mb: 2,
        }}
        fullWidth
        placeholder="Description"
        onChange={(event) => {
          setStoryText(event.target.value);
        }}
        type="text"
        variant="outlined"
      />
      <HStack mb={2}>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={async () => {
            setIsLoading(true);
            const result = await supabaseClient
              .from("english_verses")
              .update({
                verse: storyText,
                words: getSentenceWords(storyText),
              })
              .eq("id", story.story_id);
            console.log("story", story);
            console.log("result", result);
            setIsLoading(false);
          }}
        >
          Update video text
        </LoadingButton>

        <CopyToClipboard
          text={`
        Explain this phase of ${story.author}:
        ${story.id}
        
        When it was said (year and place)? What does it mean?
        
        Give me a description of this phrase in a format:
        [Phrase]
        
        [When was said]
        [The meaning]
        
        The answer must be in Ukrainian language. 
        Don't put some title in the answer. Just follow my answer pattern.
        `}
        >
          <Tooltip title="What does it mean?">
            <AirIcon />
          </Tooltip>
          {/* <Button>
            
          </Button> */}
        </CopyToClipboard>
      </HStack>
      <HStack>
        <TextField
          color="primary"
          value={storyType}
          size="small"
          placeholder="Type"
          onChange={(event) => {
            setStoryType(event.target.value);
          }}
          type="text"
          variant="outlined"
        />
        <Button
          variant="outlined"
          size="medium"
          color="error"
          onClick={async () => {
            await supabaseClient
              .from("english_verses")
              .update({
                type: storyType,
              })
              .eq("id", story.story_id);
          }}
        >
          update
        </Button>
      </HStack>
      <AddYoutubeIdField storyId={story.story_id} youtubeId={story.youtube_id} />
      {story.link && !story.link.includes("shorts") && story.youtube_id && (
        <Box
          margin="0 auto"
          sx={{
            position: "relative",
            width: "100%",
          }}
        >
          <TextPartVideoByStartAndEndButton
            start={story.clip_start || 0}
            end={(story.clip_start || 0) + (story.duration || 10)}
            story={story}
            endless
            showVideoWithoutButton={true}
          />
        </Box>
      )}
      {story.storage_link && !story.youtube_id && (
        <VStack
          sx={{
            position: "relative",
            minWidth: "280px",
            maxWidth: 600,
            width: "100%",
            overflow: "hidden",
          }}
        >
          <ReactPlayer
            controls
            width="100%"
            height="100%"
            // onEnded={() => {
            //   handleOnEnded();
            // }}
            style={{
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
            }}
            url={`${story.storage_link}`}
          />
        </VStack>
      )}

      <HStack mb={5}>
        <ItalicTitle text={`Words: ${story.word_count}`} />
        <br />
        {Boolean(story.all_intersection_count) && (
          <>
            <ItalicTitle text={`User intersection: ${story.all_intersection_count}`} />
            <br />
          </>
        )}
        <ItalicTitle text={`User new words: ${story.new_words_count}`} />
        <br />
        <AddUserLesson
          newWords={story.new_words}
          storyId={story.story_id}
          storyWords={story.words}
        />
      </HStack>
      <HStack mb={5}>
        {/* I need to get more sentences suit to the clip context.
        I need to learn the next words: ${JSON.stringify(
          story.words.filter((w: string) => !studentWords.includes(w)),
          )}
        1. So create some additional simple sentences for me with the words I need to learn in the context of the clip. And also give some popular sentences/phases (if exists) including new words. */}
        {/* sentences: string[], // sentences to learn/train new words */}
        <AiQuestionAnswer
          isVerified={story.verified}
          chapterText={storyText}
          chapterId={story.story_id}
        />
        <LoadingButton
          loading={isLoading}
          variant="outlined"
          color="error"
          onClick={async () => {
            setIsLoading(true);
            await supabaseClient
              .from("english_verses")
              .update({
                reading_user_ignore: true,
              })
              .eq("id", story.story_id);
            setIsLoading(false);
          }}
        >
          Ignore for reading user
        </LoadingButton>
        <LoadExistingQuestionAndAnswer isVerified={story.verified} storyId={story.story_id} />
        <HStack>
          <CopyStoryIdButton admin id={story.story_id} />
          <ExternalLinkVideo link={story.link} />
          {story.storage_link && (
            <Button
              variant="contained"
              color="info"
              component={Link}
              target="_blank"
              href={story.storage_link}
            >
              <Tooltip title="open storage video in a new tab">
                <SnippetFolderIcon />
              </Tooltip>
            </Button>
          )}
          {story.type === "pdf" && (
            <Typography>
              <Badge>Is PDF:</Badge> {story.pdf_name}
            </Typography>
          )}
          {story.type === "pdf" && <PdfModal story={story} />}
        </HStack>
      </HStack>
      <HStack>
        <AddToUser type="prev" storyId={story.story_id} />
        <AddToUser type="ignore" storyId={story.story_id} />
        <MakeItActiveForUser storyId={story.story_id} />
        <Button
          variant="contained"
          color="error"
          onClick={async () => {
            setIsLoading(true);
            await supabaseClient.from("english_verses").delete().eq("verse", story.id);
            onUpdateStories();
            setIsLoading(false);
          }}
        >
          <HStack>
            <Tooltip title="Remove this video from the db">
              <RemoveCircleIcon />
            </Tooltip>
            <Tooltip title="Remove this video from the db">
              <StorageIcon />
            </Tooltip>
          </HStack>
        </Button>
      </HStack>

      {story.row_new_words && (
        <>
          <Box color="brown">
            <Typography variant="h4">New words:</Typography>
            {story.row_new_words?.map((word: string) => {
              return (
                <Button key={word} sx={{ color: getWordLevelColor(word) }}>
                  {word}
                </Button>
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
};
const AdminCheckUserStories: React.FC = () => {
  const [stories, setStories] = useState(storiesData);

  return (
    <MenuLayout>
      <Box sx={{ backgroundColor: "white" }} p={5}>
        <Grid container>
          <Grid item xs={12}>
            {stories?.map((story: any) => {
              return (
                <Story
                  key={story.id}
                  story={story}
                  lazyLoading
                  onUpdateStories={() => {
                    setStories(stories.filter((q: any) => q.id != story.id));
                  }}
                />
              );
            })}
          </Grid>
        </Grid>
      </Box>
    </MenuLayout>
  );
};

export default AdminCheckUserStories;
