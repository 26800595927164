import { Checkbox, Typography } from "@mui/material";
import { useState } from "react";
import isAdmin from "../../../common/isAdmin";
import supabaseClient from "../../../common/supabaseClient";
import { useLessonsStore } from "../../../store/lessonsStore";

const DrawingBoardOpenButton = () => {
  const authUser = useLessonsStore((state) => state.authUser);
  const showDrawingBoard = useLessonsStore((state) => state.showDrawingBoard);
  const setShowDrawingBoard = useLessonsStore((state) => state.setShowDrawingBoard);
  const setIsBoardTextAllowedByAdmin = useLessonsStore(
    (state) => state.setIsBoardTextAllowedByAdmin,
  );

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowDrawingBoard(event.target.checked);
    setIsBoardTextAllowedByAdmin(event.target.checked);

    await supabaseClient
      .from("admin_control")
      .update({
        drawing_board_is_open: showDrawingBoard,
      })
      .eq("id", 0);
  };

  return (
    <>
      {isAdmin(authUser?.id) && (
        <Typography sx={{ width: "100%" }}>
          Open drawing popup
          <Checkbox checked={showDrawingBoard} onChange={handleChange} />
        </Typography>
      )}
    </>
  );
};

export default DrawingBoardOpenButton;
