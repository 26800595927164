import AiAskAboutSubjectAndCreatingAStory from "./AiAskAboutSubjectAndCreatingAStory";
import CheckCollinsVideoPronunciation from "./CheckCollinsVideoPronunciation";

const AdminMainScreen = () => {
  return (
    <>
      <CheckCollinsVideoPronunciation />
      <AiAskAboutSubjectAndCreatingAStory />
    </>
  );
};

export default AdminMainScreen;
