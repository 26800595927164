import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Drawer, Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import usePersonalLesson from "../../../hooks/usePersonalLesson";
import { useLessonsStore } from "../../../store/lessonsStore";
import LinearProgress from "../../User/LinearProgress";
import ExampleSentenceItem from "../../Word/WordTranslationSection/ExampleSentenceItem";
import supabaseClient from "../../../common/supabaseClient";
import { ExtendedUserSentence, Sentence } from "../../../types/supabase";
import FullScreenLoader from "../../Unknown/FullScreenLoader";
import HStack from "../../Unknown/HStack";
import Listening from "../../Grammar/GrammarLesson/tabs/Listening";

export const ListeningSentences: React.FC<{ sliderId: string }> = ({ sliderId }) => {
  const [slider, setSlider] = useState<Slider | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const authUser = useLessonsStore((state) => state.authUser);
  const sliderIndex = useLessonsStore(
    (state) => state.englishGrammarCourseSliderPositions[sliderId] || 0,
  );
  const setSliderIndex = useLessonsStore((state) => state.setEnglishGrammarCourseSliderPositions);

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  const handleSlideChange = async (index: number) => {
    setSliderIndex(sliderId, index);
  };

  const [isOpenEnd, setIsOpenEnd] = useState(false);
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (isOpen) {
      timeout = setTimeout(() => {
        setIsOpenEnd(true);
      }, 300);
    } else {
      setIsOpenEnd(false);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isOpen]);

  const [sentences, setSentences] = useState<Sentence[]>([]);

  const fetchSentences = async () => {
    if (!authUser) return null;
    const { data: spSentences } = await supabaseClient
      .from("user-sentences")
      .select("*, sentences(*)")
      .like("id", `${authUser.uuid}-%`)
      .order("repeat")
      .order("en")
      .limit(1000)
      .returns<ExtendedUserSentence[]>();

    if (spSentences)
      setSentences(
        spSentences.map((s) => s.sentences).filter((s) => Boolean(s.en_audio_url)) as Sentence[],
      );
  };

  const [resetingSlider, setResetingSlider] = useState(false);
  const resetSlider = () => {
    setResetingSlider(true);
    setSlider(null);
    handleSlideChange(0);
    setTimeout(() => {
      setSlider(null);
      setResetingSlider(false);
    }, 200);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Listening lessonSentences={sentences} onClick={async () => await fetchSentences()} />
      </Grid>
      {/* <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={async () => {
            setIsOpen(true);
            if (!sentences?.length) {
              await fetchSentences();
            }
          }}
        >
          Аудіювання речень
        </Button>
        <Drawer open={isOpen} onClose={handleDrawerClose} anchor="bottom">
          <Box sx={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
            {sentences?.length && (
              <LinearProgress
                elementsCount={sentences.length - 1}
                currentValue={sliderIndex || 0}
              />
            )}

            {(!isOpenEnd || resetingSlider || !Boolean(sentences?.length)) && <FullScreenLoader />}
            {sentences && (
              <Typography variant="caption" p={2}>
                {sentences.length !== sliderIndex + 1 && `${sliderIndex + 1} / ${sentences.length}`}
              </Typography>
            )}
            <IconButton
              onClick={handleDrawerClose}
              sx={{
                position: "absolute",
                right: 10,
                top: 8,
                zIndex: 2,
              }}
            >
              <CloseIcon />
            </IconButton>
            {!resetingSlider && sentences?.length && (
              <Slider
                lazyLoad="ondemand"
                infinite={false}
                ref={(slider) => setSlider(slider)}
                swipe={false}
                initialSlide={sliderIndex}
                afterChange={handleSlideChange}
              >
                {sentences?.map((sentence, index) => {
                  // if (sentence.isGreeting) {
                  //   return (
                  //     <LastSentenceItem
                  //       nextButtonText="Почати з початку"
                  //       successText="Всі речення прослухані 🙌"
                  //     >
                  //       <Button variant="contained" onClick={resetSlider}>
                  //         Почати з початку
                  //       </Button>
                  //       <Button
                  //         variant="outlined"
                  //         onClick={() => {
                  //           setIsOpen(false);
                  //           resetSlider();
                  //         }}
                  //       >
                  //         Закрити
                  //       </Button>
                  //     </LastSentenceItem>
                  //   );
                  // }
                  return (
                    <ExampleSentenceItem
                      en={sentence.en}
                      key={index}
                      audioSmall={false}
                      slideIndex={index}
                      sliderId={sliderId}
                      fullScreen
                      isCentered
                      lessonWords={[]}
                      audioUrl={sentence.sentences.en_audio_url}
                      ua={sentence.sentences.ua}
                    >
                      <HStack>
                        <Button
                          variant="outlined"
                          color="warning"
                          onClick={() => {
                            const newIndex = sliderIndex + 9;
                            const updatedSentences = [...sentences];
                            if (newIndex < sentences?.length) {
                              updatedSentences.splice(newIndex, 0, updatedSentences[sliderIndex]);
                            } else {
                              updatedSentences.push(updatedSentences[sliderIndex]);
                            }
                            slider?.slickNext();
                          }}
                        >
                          складно
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={async () => {
                            // const updatedSentences = [
                            //   ...sentences,
                            //   {
                            //     en: "",
                            //     ua: "",
                            //     audioUrl: "",
                            //     words: [],
                            //     isGreeting: true,
                            //   },
                            // ];

                            if (sliderIndex === sentences?.length - 1) {
                              // setSentences(updatedSentences);
                              resetSlider();
                            } else {
                              slider?.slickNext();
                            }
                          }}
                        >
                          легко
                        </Button>
                      </HStack>
                    </ExampleSentenceItem>
                  );
                })}
              </Slider>
            )}
          </Box>
        </Drawer>
      </Grid> */}
    </Grid>
  );
};

// return (
//   <Grid container>
//     <Grid item xs={12}>
//       <Button
//         variant="outlined"
//         onClick={async () => {
//           setIsOpen(true);
//           if (!personalLessonsSentences?.length) {
//             await fetchPersonalSentences();
//           }
//         }}
//       >
//         {!!partSentencesIds && `Аудіювання речень (${partSentencesIds.length})`}
//         {!!differenceSentences && `Аудіювання речень (${differenceSentences.length})`}
//       </Button>
//       <Drawer open={isOpen} onClose={handleDrawerClose} anchor="bottom">
//         <Box sx={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
//           {personalLessonsSentences && (
//             <LinearProgress
//               elementsCount={personalLessonsSentences.length - 1}
//               currentValue={sliderIndex || 0}
//             />
//           )}
//           {differenceSentences && (
//             <LinearProgress
//               elementsCount={differenceSentences.length - 1}
//               currentValue={sliderIndex || 0}
//             />
//           )}

//           {(!isOpenEnd || resetingSlider || !Boolean(personalLessonsSentences?.length)) && (
//             <FullScreenLoader />
//           )}
//           {personalLessonsSentences && (
//             <Typography variant="caption" p={2}>
//               {personalLessonsSentences.length !== sliderIndex + 1 &&
//                 `${sliderIndex + 1} / ${personalLessonsSentences.length}`}
//             </Typography>
//           )}
//           <IconButton
//             onClick={handleDrawerClose}
//             sx={{
//               position: "absolute",
//               right: 10,
//               top: 8,
//               zIndex: 2,
//             }}
//           >
//             <CloseIcon />
//           </IconButton>
//           {!resetingSlider && personalLessonsSentences?.length && (
//             <Slider
//               lazyLoad="ondemand"
//               infinite={false}
//               ref={(slider) => setSlider(slider)}
//               swipe={false}
//               initialSlide={sliderIndex}
//               afterChange={handleSlideChange}
//             >
//               {personalLessonsSentences?.map((sentence, index) => {
//                 // if (sentence.isGreeting) {
//                 //   return (
//                 //     <LastSentenceItem
//                 //       nextButtonText="Почати з початку"
//                 //       successText="Всі речення прослухані 🙌"
//                 //     >
//                 //       <Button variant="contained" onClick={resetSlider}>
//                 //         Почати з початку
//                 //       </Button>
//                 //       <Button
//                 //         variant="outlined"
//                 //         onClick={() => {
//                 //           setIsOpen(false);
//                 //           resetSlider();
//                 //         }}
//                 //       >
//                 //         Закрити
//                 //       </Button>
//                 //     </LastSentenceItem>
//                 //   );
//                 // }
//                 return (
//                   <ExampleSentenceItem
//                     en={sentence.en}
//                     key={index}
//                     audioSmall={false}
//                     slideIndex={index}
//                     sliderId={sliderId}
//                     fullScreen
//                     isCentered
//                     lessonWords={[]}
//                     audioUrl={sentence.en_audio_url}
//                     ua={sentence.ua}
//                   >
//                     <HStack>
//                       <Button
//                         variant="outlined"
//                         color="warning"
//                         onClick={() => {
//                           const newIndex = sliderIndex + 9;
//                           const updatedSentences = [...personalLessonsSentences];
//                           if (newIndex < personalLessonsSentences?.length) {
//                             updatedSentences.splice(newIndex, 0, updatedSentences[sliderIndex]);
//                           } else {
//                             updatedSentences.push(updatedSentences[sliderIndex]);
//                           }
//                           setPersonalLessonsSentences(lessonId, updatedSentences);
//                           slider?.slickNext();
//                         }}
//                       >
//                         не зрозуміла
//                       </Button>
//                       <Button
//                         variant="outlined"
//                         onClick={() => {
//                           // const updatedSentences = [
//                           //   ...sentences,
//                           //   {
//                           //     en: "",
//                           //     ua: "",
//                           //     audioUrl: "",
//                           //     words: [],
//                           //     isGreeting: true,
//                           //   },
//                           // ];
//                           if (sliderIndex === personalLessonsSentences?.length - 1) {
//                             // setSentences(updatedSentences);
//                             resetSlider();
//                           } else {
//                             slider?.slickNext();
//                           }
//                         }}
//                       >
//                         зрозуміла
//                       </Button>
//                     </HStack>
//                   </ExampleSentenceItem>
//                 );
//               })}
//             </Slider>
//           )}
//         </Box>
//       </Drawer>
//     </Grid>
//   </Grid>
// );
