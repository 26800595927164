import supabaseClient from "../../../../../common/supabaseClient";
import { useLessonsStore } from "../../../../../store/lessonsStore";
import Checkbox from "../../../Checkbox";
import Typography from "../../../Typography";

/**
 *
 * if checked: start interval for an active user to fetch data from the database
 *
 */
const ForceReloadIntervalSwitch = () => {
  const isForceReloadInterval = useLessonsStore((state) => state.isForceReloadInterval);

  const setIsForceReloadInterval = useLessonsStore((state) => state.setIsForceReloadInterval);
  const handleForceReloadChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    await supabaseClient
      .from("admin_control")
      .update({
        force_reload: event.target.checked,
      })
      .eq("id", 0);
    // await supabaseClient.from("admin_control").select().eq("id", 0).single();

    setIsForceReloadInterval(!event.target.checked);
  };

  return (
    <Typography sx={{ width: "100%" }}>
      Force reload interval
      <Checkbox checked={isForceReloadInterval} onChange={handleForceReloadChange} />
    </Typography>
  );
};

export default ForceReloadIntervalSwitch;
