import { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import { useLessonsStore } from "../../../../store/lessonsStore";
import Drawer from "../../../Unknown/Drawer";
import Button from "../../../Unknown/Button";
import { useWindowSize } from "react-use";
import LinearProgress from "../../../User/LinearProgress";
import Box from "../../../Unknown/Box";
import ModalCloseButton from "../../../Unknown/ModalCloseButton";
import VStack from "../../../Unknown/VStack";
import Typography from "../../../Unknown/Typography";
import HStack from "../../../Unknown/HStack";
import { Paper } from "@mui/material";

const isTouchDevice = require("is-touch-device");

export interface PartWord {
  word: string;
  normalizedWord: string;
}

export const ChangePositionButton: React.FC<
  PropsWithChildren & {
    isPrimary?: boolean;
    isDisabled?: boolean;
    color?: string;
    onClick: () => void;
  }
> = ({ children, isPrimary, isDisabled, color, onClick }) => {
  return (
    <Box
      component={Paper}
      elevation={2}
      borderRadius={2}
      borderColor="#efefef"
      alignItems="center"
      display="flex"
      px={4}
      py={2}
      gap={2}
      sx={{
        pointerEvents: isDisabled ? "none" : "auto",
        opacity: isDisabled ? 0.3 : 1,
        padding: "5px 13px",
        fontSize: "24px",
        textAlign: "center",
        alignItems: "center",
        cursor: isTouchDevice() ? "auto" : "pointer",
        outline: "none",
        backgroundColor: color || "#fff",
        border: "3px solid #efefef",
        borderRadius: "15px",
        boxShadow: "0 5px #e8e8e8",
        transition: "border .3s ease",
        "&:active, &:focus": {
          outline: "none",
          backgroundColor: "#fff",
          boxShadow: "0 5px #e8e8e8",
          transform: "translateY(2px)",
        },
      }}
      onClick={onClick}
    >
      <Typography variant="h3" color={isPrimary ? "primary" : "default"} fontWeight={500}>
        {children}
      </Typography>
    </Box>
  );
};

interface LearnWordsSectionProps {
  words: string[];
}

const StudentReadWordsCarousel: React.FC<LearnWordsSectionProps> = ({ words }) => {
  const lessonModalOpen = useLessonsStore((state) => state.lessonModalOpen === "read-words");
  const setLessonModalOpen = useLessonsStore((state) => state.setLessonModalOpen);

  const handleDrawerClose = () => {
    setLessonModalOpen("");
  };

  return (
    <>
      {
        <Button variant="outlined" onClick={() => setLessonModalOpen("read-words")}>
          Читати слова ({words.length})
        </Button>
      }

      <Drawer
        open={lessonModalOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 9,
        }}
      >
        <DrawerContent words={words} onClose={handleDrawerClose}></DrawerContent>
      </Drawer>
    </>
  );
};

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
};

const DrawerContent: React.FC<
  {
    words: string[];
    onClose: () => void;
  } & PropsWithChildren
> = ({ words: inputWords, onClose }) => {
  const [repeatedWords, setRepeatedWords] = useState<string[]>([]);
  const [isTranslated, setIsTranslated] = useState(false);
  const authUser = useLessonsStore((state) => state.authUser);
  // const lessonStoreWords = useLessonsStore((state) => state.personalLessonsWords[lessonId]);
  const setPersonalLessonsWords = useLessonsStore((state) => state.setPersonalLessonsWords);
  const drawerContentRef = useRef<any>(null);
  const updateHomeWork = useLessonsStore((state) => state.updateHomeWork);
  const wordsToRepeat = useLessonsStore((state) => state.homeWork.wordsToRepeat);
  const [wordsRepeatMap, setWordsRepeatMap] = useState<Record<string, boolean | undefined>>({});

  const [possibleTranslation, setPossibleTranslation] = useState("");
  const [isPossibleTranslationTrue, setIsPossibleTranslationTrue] = useState(false);
  const [possibleTranslationError, setPossibleTranslationError] = useState(false);
  const isTeacherLesson = useLessonsStore((state) => state.isTeacherLesson);
  const readingWordIndex = useLessonsStore((state) => state.readingWordIndex);
  const setReadingWordIndex = useLessonsStore((state) => state.setReadingWordIndex);
  const readingWords = useLessonsStore((state) => state.readingWords);
  const setReadingWords = useLessonsStore((state) => state.setReadingWords);

  useEffect(() => {
    if (readingWords.length !== inputWords.length && inputWords.length) {
      setReadingWords(inputWords);
    }
  }, [readingWords, inputWords]);

  const currentWord = useMemo(() => {
    const [word] = readingWords;
    return word;
  }, [readingWords]);

  const moveToTheEnd = () => {
    if (!authUser) return;
    const newWords = [...readingWords];
    const firstWord = newWords.shift();
    if (firstWord) newWords.push(firstWord);
    setReadingWords(newWords);
    if (readingWordIndex + 1 <= newWords.length) {
      setReadingWordIndex(readingWordIndex + 1);
    } else {
      setReadingWordIndex(0);
    }
  };

  const moveBy3 = () => {
    const newWords = [...readingWords];
    const firstWord = newWords.shift();
    if (firstWord) newWords.splice(2, 0, firstWord);
    setReadingWords(newWords);
  };

  const moveBy7 = () => {
    const newWords = [...readingWords];
    const firstWord = newWords.shift();
    if (firstWord) newWords.splice(6, 0, firstWord);
    setReadingWords(newWords);
  };

  const moveBy20 = () => {
    const newWords = [...readingWords];
    const firstWord = newWords.shift();
    if (firstWord) newWords.splice(19, 0, firstWord);
    setReadingWords(newWords);
  };
  console.log("🚀 ~ readingWordIndex:", readingWordIndex);

  const drawer = useMemo(() => {
    return (
      <Box sx={drawerContentStyle} ref={drawerContentRef}>
        <LinearProgress elementsCount={readingWords.length - 1} currentValue={readingWordIndex} />
        <ModalCloseButton onClose={onClose} />

        <VStack
          px={5}
          justifyContent="center"
          position="relative"
          width="100vw"
          flexWrap="nowrap"
          pt={20}
          pb={10}
          sx={{
            overflowX: "hidden",
            // backgroundColor: "red",
          }}
        >
          <VStack minHeight={200}>
            <Typography variant="h2" color="primary.main" gutterBottom>
              {currentWord}
            </Typography>
          </VStack>

          <HStack py={3} justifyContent="center" alignItems="center" width={300}>
            <ChangePositionButton
              onClick={async () => {
                moveBy3();
              }}
            >
              3
            </ChangePositionButton>
            <ChangePositionButton
              onClick={async () => {
                moveBy7();
              }}
            >
              7
            </ChangePositionButton>
            <ChangePositionButton
              onClick={async () => {
                moveBy20();
              }}
            >
              20
            </ChangePositionButton>
            <ChangePositionButton
              color="#bdffa0"
              onClick={async () => {
                moveToTheEnd();
              }}
            >
              {/* <LastPageIcon /> */}
              далі
            </ChangePositionButton>
          </HStack>
        </VStack>
      </Box>
    );
  }, [
    authUser,
    currentWord,
    isTranslated,
    repeatedWords,
    wordsRepeatMap,
    possibleTranslationError,
    possibleTranslation,
    isPossibleTranslationTrue,
    onClose,
  ]);

  return drawer;
};

export default StudentReadWordsCarousel;
