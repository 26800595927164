import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Drawer, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import shuffle from "../../../common/shuffle";
import { useLessonsStore } from "../../../store/lessonsStore";
import ItalicTitle from "../../Title/ItalicTitle";
import HStack from "../../Unknown/HStack";
import ModalCloseButton from "../../Unknown/ModalCloseButton";
import VStack from "../../Unknown/VStack";
import LinearProgress from "../../User/LinearProgress";
import CongratulationsModal from "../UkTrainers/CongratulationsModal";
import useIsHomeWork from "../../../hooks/useIsHomeWork";
import ShowCurrentHomeWorkNumbersInDrawer from "../../Unknown/ShowCurrentHomeWorkNumbersInDrawer";
import parsed from "../../../common/parsed";
import { userHomeWorkCurrentDay } from "../../../common/constants";
import { format } from "date-fns";
import rules13 from "./rules13";
import rules15 from "./rules15";
import rules16 from "./rules16";
import rules17 from "./rules17";
import rules18 from "./rules18";
import rules19 from "./rules19";
import rules20 from "./rules20";
import rules21 from "./rules21";

function splitByQuotes(str: string) {
  const result = [];
  let currentWord = "";
  let beforeOrLastWords = "";
  let insideQuotes = false;

  for (let i = 0; i < str.length; i++) {
    const char = str[i];

    if (char === "'") {
      insideQuotes = !insideQuotes;
      // currentWord += char; // Add the quote to currentWord
      if (beforeOrLastWords) {
        result.push(beforeOrLastWords);
        beforeOrLastWords = "";
      }
    } else if (!insideQuotes) {
      beforeOrLastWords += char;
      if (currentWord) {
        result.push(currentWord); // Push the word to the result array
        currentWord = ""; // Reset currentWord
      }
    } else {
      currentWord += char; // Add the character to currentWord
    }
  }

  // Add the last word if it exists
  if (currentWord) {
    result.push(currentWord);
  }
  if (beforeOrLastWords) {
    result.push(beforeOrLastWords);
  }

  return result;
}

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
};

export type LessonRule = {
  id: string;
  questionUa: string;
  correctAnswer: string;
  options?: string[];
};

export const lessonRules: Record<string, LessonRule[]> = {
  1: [
    {
      id: "1",
      questionUa: "Чому у кінці слова 'doll' дві літери 'll'?",
      correctAnswer:
        "Літера 'l' часто подвоюється якщо вона знаходиться у кінці слова після короткого голосного звука",
      options: ["Це слово іншомовного походження і треба просто запамʼятати", "Ніхто не знає чому"],
    },
    {
      id: "2",
      questionUa: "Чому у кінці слова 'little' літера 'e' не вимовляється?",
      correctAnswer: "Літера 'e' ніколи не вимовляється у кінці слова",
      options: ["Це виняток і треба просто запамʼятати", "Ніхто не знає чому"],
    },
    {
      id: "3",
      questionUa: "Чому у слові 'little' дві літери 'tt'?",
      correctAnswer:
        "Літера 't' подвоюється якщо вона знаходиться між коротким голосним звуком та літерами 'le'",
      options: [
        "Це слово іншомовного походження і треба просто запамʼятати",
        "Це помилка так писати",
      ],
    },
    {
      id: "4",
      questionUa: "Чому у кінці слова 'bell' дві літери 'll'?",
      correctAnswer:
        "Літера 'l' часто подвоюється якщо вона знаходиться у кінці слова після короткого голосного звука",
      options: ["Це слово іншомовного походження і треба просто запамʼятати", "Ніхто не знає чому"],
    },
  ],
  2.3: [
    {
      id: "5",
      questionUa: "Чому у кінці слова 'little' літера 'e' не вимовляється?",
      correctAnswer: "Літера 'e' ніколи не вимовляється у кінці слова",
      options: ["Це виняток і треба просто запамʼятати", "Ніхто не знає чому"],
    },
    {
      id: "6",
      questionUa: "Чому у слові 'little' дві літери 'tt'?",
      correctAnswer:
        "Літера 't' подвоюється якщо вона знаходиться між коротким голосним звуком та літерами 'le'",
      options: [
        "Це слово іншомовного походження і треба просто запамʼятати",
        "Це помилка так писати",
      ],
    },
    {
      id: "7",
      questionUa: "Чому у кінці слова 'bell' дві літери 'll'?",
      correctAnswer:
        "Літера 'l' часто подвоюється якщо вона знаходиться у кінці слова після короткого голосного звука",
      options: ["Це слово іншомовного походження і треба просто запамʼятати", "Ніхто не знає чому"],
    },
    {
      id: "8",
      questionUa: "Чому ми пишемо 'просьба' а вимовляємо 'прозьба'?",
      correctAnswer:
        "Тому що після глухої літери 'c' наступна літера 'б' яка є дзвінкою і змінює вимову літери 'с' на 'з'",
      options: [],
    },
    {
      id: "9",
      questionUa: "Чому ми пишемо 'боротьба' а вимовляємо 'бородьба'?",
      correctAnswer:
        "Тому що після глухої літери 'т' наступна літера 'б' яка є дзвінкою і змінює вимову літери 'т' на 'д'",
      options: [],
    },
    {
      id: "10",
      questionUa: "Чому ми пишемо 'ноутбук' а вимовляємо 'ноудбук'?",
      correctAnswer:
        "Тому що після глухої літери 'т' наступна літера 'б' яка є дзвінкою і змінює вимову літери 'т' на 'д'",
      options: [],
    },
    {
      id: "11",
      questionUa: "Чому ми пишемо 'отже' а вимовляємо 'одже'?",
      correctAnswer:
        "Тому що після глухої літери 'т' наступна літера 'ж' яка є дзвінкою і змінює вимову літери 'т' на 'д'",
      options: [],
    },
    {
      id: "12",
      questionUa: "Чому ми пишемо 'швидко' а вимовляємо 'швитко'?",
      correctAnswer:
        "Тому що після дзвінкої літери 'д' наступна літера 'к' яка є глухою і змінює вимову літери 'д' на 'т'",
      options: [],
    },
    {
      id: "13",
      questionUa: "Чому ми пишемо 'рибка' а вимовляємо 'рипка'?",
      correctAnswer:
        "Тому що після дзвінкої літери 'б' наступна літера 'к' яка є глухою і змінює вимову літери 'б' на 'п'",
      options: [],
    },
    {
      id: "14",
      questionUa: "Чому ми пишемо 'легко' а вимовляємо 'лехко'?",
      correctAnswer:
        "Тому що після дзвінкої літери 'г' наступна літера 'к' яка є глухою і змінює вимову літери 'г' на 'х'",
      options: [],
    },
    {
      id: "15",
      questionUa: "Літера 'd' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "16",
      questionUa: "Літера 'p' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "17",
      questionUa: "Літера 'l' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "18",
      questionUa: "Літера 'b' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "19",
      questionUa: "Літера 't' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "20",
      questionUa: "Літера 'n' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "21",
      questionUa: "Літера 'z' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
  ],
  3.3: [
    {
      id: "22",
      questionUa: "Чому у кінці слова 'apple' літера 'e' не вимовляється?",
      correctAnswer: "Літера 'e' ніколи не вимовляється у кінці слова",
    },
    {
      id: "23",
      questionUa:
        "Навіщо у словах 'little', 'bottle', 'battle' писати дві літери 'tt' якщо вимовляємо тільки одну?",
      correctAnswer: "Тому що попередній голосний звук короткий.",
    },
    {
      id: "24",
      questionUa:
        "Чому у слові 'little' літера 'i' читається як українська 'і', а не 'ай' як у словах 'pine', 'site', 'line'?",
      correctAnswer:
        "Тому що склад закритий. Голосний звук у закритому складі 'lit' звучить як 'i' а не 'ай'",
    },
    {
      id: "25",
      questionUa:
        "Чому слово 'little' ділиться на склади так 'lit-tle'? Чому не 'li-ttle'?  Чому не 'litt-le'",
      correctAnswer:
        "Тому що 'приголосна' + 'le' = завжди окремий склад. Також якщо дві приголосні підряд, то ми розділяємо слово на склади між ними, перша літера йде до попереднього складу, а друга до наступного",
    },
    {
      id: "26",
      questionUa:
        "Чому у словах 'little', 'bottle', 'battle' другий склад дорівнює 'tle'? Адже 'e' у кінці слова не вимовляється, тому і не формує складу.",
      correctAnswer:
        "Тому що 'приголосна' + 'le' = завжди окремий склад незважаючи на те що літера 'e' у кінці не вимовляється",
    },
    {
      id: "27",
      questionUa:
        "Чому у словах 'line', 'nine', 'pine', 'site' літера 'і' читається як 'ай' а не як українська літера 'i'?",
      correctAnswer:
        "Тому що у кінці цих слів літера 'e' яка не вимовляється. Але ця літера на письмі говорить нам що попередній голосний звук довгий а не короткий. Це відбувається із-за того що ця літера у цих словах на письмі змінює склад з закритого на відкритий.",
    },
    {
      id: "28",
      questionUa: "Чому у кінці слова 'bell' дві літери 'll'?",
      correctAnswer:
        "Літера 'l' часто подвоюється якщо вона знаходиться у кінці слова після короткого голосного звука",
    },
    {
      id: "29",
      questionUa: "Чому ми пишемо 'просьба' а вимовляємо 'прозьба'?",
      correctAnswer:
        "Тому що після глухої літери 'c' наступна літера 'б' яка є дзвінкою і змінює вимову літери 'с' на 'з'",
    },
    {
      id: "30",
      questionUa: "Чому ми пишемо 'боротьба' а вимовляємо 'бородьба'?",
      correctAnswer:
        "Тому що після глухої літери 'т' наступна літера 'б' яка є дзвінкою і змінює вимову літери 'т' на 'д'",
    },
    {
      id: "31",
      questionUa: "Чому ми пишемо 'ноутбук' а вимовляємо 'ноудбук'?",
      correctAnswer:
        "Тому що після глухої літери 'т' наступна літера 'б' яка є дзвінкою і змінює вимову літери 'т' на 'д'",
    },
    {
      id: "32",
      questionUa: "Чому ми пишемо 'отже' а вимовляємо 'одже'?",
      correctAnswer:
        "Тому що після глухої літери 'т' наступна літера 'ж' яка є дзвінкою і змінює вимову літери 'т' на 'д'",
    },
    {
      id: "33",
      questionUa: "Чому ми пишемо 'швидко' а вимовляємо 'швитко'?",
      correctAnswer:
        "Тому що після дзвінкої літери 'д' наступна літера 'к' яка є глухою і змінює вимову літери 'д' на 'т'",
    },
    {
      id: "34",
      questionUa: "Чому ми пишемо 'рибка' а вимовляємо 'рипка'?",
      correctAnswer:
        "Тому що після дзвінкої літери 'б' наступна літера 'к' яка є глухою і змінює вимову літери 'б' на 'п'",
    },
    {
      id: "35",
      questionUa: "Чому ми пишемо 'легко' а вимовляємо 'лехко'?",
      correctAnswer:
        "Тому що після дзвінкої літери 'г' наступна літера 'к' яка є глухою і змінює вимову літери 'г' на 'х'",
    },
    {
      id: "36",
      questionUa: "Літера 'd' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "37",
      questionUa: "Літера 'p' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "38",
      questionUa: "Літера 'l' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "39",
      questionUa: "Літера 'b' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "40",
      questionUa: "Літера 't' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "41",
      questionUa: "Літера 'n' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "42",
      questionUa: "Літера 'z' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "43",
      questionUa: "Літера 'h' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "44",
      questionUa: "Літера 'v' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "45",
      questionUa: "Літера 'k' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
  ],
  4: [
    {
      id: "46",
      questionUa:
        "Навіщо у словах 'little', 'bottle', 'battle' писати дві літери 'tt' якщо вимовляємо тільки одну?",
      correctAnswer: "Тому що попередній голосний звук короткий.",
    },
    {
      id: "47",
      questionUa:
        "Чому у словах 'little', 'milk', 'pig', 'big' літера 'i' читається як українська 'і', а не 'ай' як у словах 'pine', 'site', 'line', 'bike', 'lime', 'life', 'five', 'hi'?",
      correctAnswer: "Тому що літера 'i' знаходиться у закритому складі.",
    },
    {
      id: "48",
      questionUa:
        "Чому слово 'little' ділиться на склади так 'lit-tle'? Чому не 'li-ttle'?  Чому не 'litt-le'",
      correctAnswer:
        "Тому що 'приголосна' + 'le' = завжди окремий склад. Також якщо дві приголосні підряд, то ми розділяємо слово на склади між ними, перша літера йде до попереднього складу, а друга до наступного",
    },
    {
      id: "49",
      questionUa:
        "Чому у словах 'little', 'bottle', 'battle' другий склад дорівнює 'tle'? Адже 'e' у кінці слова не вимовляється, тому і не формує складу.",
      correctAnswer:
        "Тому що 'приголосна' + 'le' = завжди окремий склад незважаючи на те що літера 'e' у кінці не вимовляється",
    },
    {
      id: "50",
      questionUa:
        "Чому у словах 'line', 'nine', 'pine', 'site' літера 'і' читається як 'ай' а не як українська літера 'i'?",
      correctAnswer:
        "Тому що у кінці цих слів літера 'e' яка не вимовляється. Але ця літера на письмі говорить нам що попередній голосний звук (у даних словах це звук 'i') довгий а не короткий. Це відбувається із-за того що ця літера у цих словах на письмі змінює склад з закритого на відкритий.",
    },
    {
      id: "51",
      questionUa: "Чому у кінці слова 'bell' дві літери 'll'?",
      correctAnswer:
        "Літера 'l' часто подвоюється якщо вона знаходиться у кінці слова після короткого голосного звука",
    },
    {
      id: "52",
      questionUa: "Чому ми пишемо 'просьба' а вимовляємо 'прозьба'?",
      correctAnswer:
        "Тому що після глухої літери 'c' наступна літера 'б' яка є дзвінкою і змінює вимову літери 'с' на 'з'",
    },
    {
      id: "53",
      questionUa:
        "Чому у слові 'maps' літера 's' читається як 's', а у слові 'dogs' читається як 'z'?",
      correctAnswer: "Тому що 's' вимовляється як 'z' у кінці слова після дзвінкої приголосної",
    },
    {
      id: "54",
      questionUa: "Чому у слові 'is' літера 's' читається як 'z'?",
      correctAnswer:
        "Тому що 's' стоїть після 'голосної літери'. У деяких словах після 'голосної літери' так само як і після 'дзвінкої приголосної' літера 's' вимовляється як 'z'",
    },
    {
      id: "55",
      questionUa: "Чому ми пишемо 'боротьба' а вимовляємо 'бородьба'?",
      correctAnswer:
        "Тому що після глухої літери 'т' наступна літера 'б' яка є дзвінкою і змінює вимову літери 'т' на 'д'",
    },
    {
      id: "56",
      questionUa: "Чому ми пишемо 'ноутбук' а вимовляємо 'ноудбук'?",
      correctAnswer:
        "Тому що після глухої літери 'т' наступна літера 'б' яка є дзвінкою і змінює вимову літери 'т' на 'д'",
    },
    {
      id: "57",
      questionUa: "Чому ми пишемо 'отже' а вимовляємо 'одже'?",
      correctAnswer:
        "Тому що після глухої літери 'т' наступна літера 'ж' яка є дзвінкою і змінює вимову літери 'т' на 'д'",
    },
    {
      id: "58",
      questionUa: "Чому ми пишемо 'швидко' а вимовляємо 'швитко'?",
      correctAnswer:
        "Тому що після дзвінкої літери 'д' наступна літера 'к' яка є глухою і змінює вимову літери 'д' на 'т'",
    },
    {
      id: "59",
      questionUa: "Чому ми пишемо 'рибка' а вимовляємо 'рипка'?",
      correctAnswer:
        "Тому що після дзвінкої літери 'б' наступна літера 'к' яка є глухою і змінює вимову літери 'б' на 'п'",
    },
    {
      id: "60",
      questionUa: "Чому ми пишемо 'легко' а вимовляємо 'лехко'?",
      correctAnswer:
        "Тому що після дзвінкої літери 'г' наступна літера 'к' яка є глухою і змінює вимову літери 'г' на 'х'",
    },
    {
      id: "61",
      questionUa: "Літера 'm' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "62",
      questionUa: "Літера 'g' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "63",
      questionUa: "Літера 'f' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "64",
      questionUa: "Літера 'd' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "65",
      questionUa: "Літера 'p' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "66",
      questionUa: "Літера 'l' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "67",
      questionUa: "Літера 'b' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "68",
      questionUa: "Літера 't' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "69",
      questionUa: "Літера 'n' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "70",
      questionUa: "Літера 'z' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "71",
      questionUa: "Літера 'h' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "72",
      questionUa: "Літера 'v' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "73",
      questionUa: "Літера 'k' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
  ],
  "5.4": [
    {
      id: "74",
      questionUa: "Чому у слові 'to' літера 'o' вимовляється як українська довга 'у'?",
      correctAnswer: "Це виняток. Потрібно запамʼятати.",
    },
    {
      id: "75",
      questionUa: "Як вимовляються дві літери 'ee' у словах 'see, tree, need, green, geese'?",
      correctAnswer:
        "Вимовляється як українська літера 'i'. Звук завжди довгий, ніби кілька літер 'iiii...'",
    },
    {
      id: "76",
      questionUa: "Навіщо у словах 'see, tree' писати 'ee', чому просто не написати 'i'?",
      correctAnswer:
        "В такому випадку зміниться вимова, тому що це слова які складаються з одного відкритого складу. У відкритому складі літера 'i' вимовляється 'ай'",
    },
    {
      id: "77",
      questionUa: "Що таке займенники?",
      correctAnswer:
        "Це слова які допомагають нам замінювати іменники у речення, щоб не повторювати те саме у кожному реченні.",
    },
    {
      id: "78",
      questionUa: "Що таке 'this'? До якого типу займенників це слово відноситься?",
      correctAnswer:
        "Це слово яке вказує на 'предмет/людину/місце' (то що є іменником і можна порахувати) що знаходиться біля нас. Відноситься до вказівних займенників, тому що вказує на розташування.",
    },
    {
      id: "79",
      questionUa: "Яка головна різниця між 'this' та 'it'?",
      correctAnswer:
        " 'it' замінює те що ми вже сказали, 'this' вказує на те що 'предмет/людину/місце' знаходиться біля нас.",
    },
    {
      id: "80",
      questionUa: "Як правильно вимовляти 'oo' у словах 'root, room, goose'?",
      correctAnswer: "вимовляється як довга українська 'у'",
    },
    {
      id: "81",
      questionUa:
        "Навіщо у словах 'little', 'bottle', 'battle' писати дві літери 'tt' якщо вимовляємо тільки одну?",
      correctAnswer: "Тому що попередній голосний звук короткий.",
    },
    {
      id: "82",
      questionUa:
        "Чому у словах 'little', 'bottle', 'battle' другий склад дорівнює 'tle'? Адже 'e' у кінці слова не вимовляється, тому і не формує складу.",
      correctAnswer:
        "Тому що 'приголосна' + 'le' = завжди окремий склад незважаючи на те що літера 'e' у кінці не вимовляється",
    },
    {
      id: "83",
      questionUa: "Чому у кінці слова 'bell' дві літери 'll'?",
      correctAnswer:
        "Літера 'l' часто подвоюється якщо вона знаходиться у кінці слова після короткого голосного звука",
    },
    {
      id: "84",
      questionUa: "Чому ми пишемо 'просьба' а вимовляємо 'прозьба'?",
      correctAnswer:
        "Тому що після глухої літери 'c' наступна літера 'б' яка є дзвінкою і змінює вимову літери 'с' на 'з'",
    },
    {
      id: "85",
      questionUa:
        "Чому у слові 'maps' літера 's' читається як 's', а у слові 'dogs' читається як 'z'?",
      correctAnswer: "Тому що 's' вимовляється як 'z' у кінці слова після дзвінкої приголосної",
    },
    {
      id: "86",
      questionUa: "Чому у слові 'is' літера 's' читається як 'z'?",
      correctAnswer:
        "Тому що 's' стоїть після 'голосної літери'. У деяких словах після 'голосної літери' так само як і після 'дзвінкої приголосної' літера 's' вимовляється як 'z'",
    },
    {
      id: "87",
      questionUa: "Чому ми пишемо 'легко' а вимовляємо 'лехко'?",
      correctAnswer:
        "Тому що після дзвінкої літери 'г' наступна літера 'к' яка є глухою і змінює вимову літери 'г' на 'х'",
    },
    {
      id: "88",
      questionUa: "Літера 'c' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "89",
      questionUa: "Літера 'r' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "90",
      questionUa: "Літера 'm' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "91",
      questionUa: "Літера 'g' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "92",
      questionUa: "Літера 'f' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "93",
      questionUa: "Літера 'd' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "94",
      questionUa: "Літера 'p' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "95",
      questionUa: "Літера 'l' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "96",
      questionUa: "Літера 'b' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "97",
      questionUa: "Літера 't' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "98",
      questionUa: "Літера 'n' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "99",
      questionUa: "Літера 'z' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "100",
      questionUa: "Літера 'h' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "101",
      questionUa: "Літера 'v' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "102",
      questionUa: "Літера 'k' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
  ],
  "6": [
    {
      id: "103",
      questionUa: "Які артиклі ти знаєш?",
      correctAnswer: "the, a, an",
    },
    {
      id: "104",
      questionUa: "У чому різниця між артиклями 'a' та 'an'?",
      correctAnswer:
        " 'a' вживається з іменниками в однині якщо іменник починається на приголосний звук, 'an' вживається з іменниками в однині якщо іменник починається на голосний звук",
    },
    {
      id: "-104",
      questionUa:
        "Чому перед 'apple' говоримо 'an' - 'an apple', а перед 'cat' говоримо 'a' - 'a cat'?",
      correctAnswer:
        "Тому що 'a' вживається перед іменником який починається на приголосний звук, 'an' вживається перед іменником який починається на голосний звук",
    },
    {
      id: "-105",
      questionUa: "Як перекласти слово 'to' У реченні 'I like to eat apples'?",
      correctAnswer:
        "Тут слово 'to' не перекладається тут. В даному реченні 'to' це допоміжне слово яке потрібно ставити між двома дієсловами. У даному випадку 'like' та 'eat'",
    },
    {
      id: "105",
      questionUa: "Що означають артиклі 'a' та 'an'?",
      correctAnswer: "a/an = один, означає просто один 'іменник'(обʼєкт/річ/тварину/людину)",
    },
    {
      id: "106",
      questionUa: "Які дві головні відмінності між артиклями 'the' та 'a/an'?",
      correctAnswer:
        "Перша різниця: the - це означений артикль, вживається з іменниками які вже згадувалися раніше, або з тим про що ми знаємо. a/an вживається з іменниками які згадуються вперше. Друга різниця: 'a/an' - вживається тільки перед іменниками в однині, 'the'- вживається як перед іменниками в однині так і перед іменниками в множині",
    },
    {
      id: "107",
      questionUa: "Чому у слові 'to' літера 'o' вимовляється як українська довга 'у'?",
      correctAnswer: "Це виняток. Потрібно запамʼятати.",
    },
    {
      id: "108",
      questionUa: "Як вимовляються дві літери 'ee' у словах 'see, tree, need, green, geese'?",
      correctAnswer:
        "Вимовляється як українська літера 'i'. Звук завжди довгий, ніби кілька літер 'iiii...'",
    },
    {
      id: "109",
      questionUa: "Навіщо у словах 'see, tree' писати 'ee', чому просто не написати 'i'?",
      correctAnswer:
        "В такому випадку зміниться вимова, тому що це слова які складаються з одного відкритого складу. У відкритому складі літера 'i' вимовляється 'ай'",
    },
    {
      id: "110",
      questionUa: "Що таке займенники?",
      correctAnswer:
        "Це слова які допомагають нам замінювати іменники у речення, щоб не повторювати те саме у кожному реченні.",
    },
    {
      id: "111",
      questionUa: "Що таке 'this'? До якого типу займенників це слово відноситься?",
      correctAnswer:
        "Це слово яке вказує на 'предмет/людину/місце' (то що є іменником і можна порахувати) що знаходиться біля нас. Відноситься до вказівних займенників, тому що вказує на розташування.",
    },
    {
      id: "112",
      questionUa: "Яка головна різниця між 'this' та 'it'?",
      correctAnswer:
        " 'it' замінює те що ми вже сказали, 'this' вказує на те що 'предмет/людину/місце' знаходиться біля нас.",
    },
    {
      id: "113",
      questionUa: "Як правильно вимовляти 'oo' у словах 'root, room, goose'?",
      correctAnswer: "вимовляється як довга українська 'у'",
    },
    {
      id: "114",
      questionUa:
        "Навіщо у словах 'little', 'bottle', 'battle' писати дві літери 'tt' якщо вимовляємо тільки одну?",
      correctAnswer: "Тому що попередній голосний звук короткий.",
    },
    {
      id: "115",
      questionUa:
        "Чому у словах 'little', 'bottle', 'battle' другий склад дорівнює 'tle'? Адже 'e' у кінці слова не вимовляється, тому і не формує складу.",
      correctAnswer:
        "Тому що 'приголосна' + 'le' = завжди окремий склад незважаючи на те що літера 'e' у кінці не вимовляється",
    },
    {
      id: "116",
      questionUa: "Чому у кінці слова 'bell' дві літери 'll'?",
      correctAnswer:
        "Літера 'l' часто подвоюється якщо вона знаходиться у кінці слова після короткого голосного звука",
    },
    {
      id: "117",
      questionUa: "Чому ми пишемо 'просьба' а вимовляємо 'прозьба'?",
      correctAnswer:
        "Тому що після глухої літери 'c' наступна літера 'б' яка є дзвінкою і змінює вимову літери 'с' на 'з'",
    },
    {
      id: "118",
      questionUa:
        "Чому у слові 'maps' літера 's' читається як 's', а у слові 'dogs' читається як 'z'?",
      correctAnswer: "Тому що 's' вимовляється як 'z' у кінці слова після дзвінкої приголосної",
    },
    {
      id: "119",
      questionUa: "Чому у слові 'is' літера 's' читається як 'z'?",
      correctAnswer:
        "Тому що 's' стоїть після 'голосної літери'. У деяких словах після 'голосної літери' так само як і після 'дзвінкої приголосної' літера 's' вимовляється як 'z'",
    },
    {
      id: "120",
      questionUa: "Чому ми пишемо 'легко' а вимовляємо 'лехко'?",
      correctAnswer:
        "Тому що після дзвінкої літери 'г' наступна літера 'к' яка є глухою і змінює вимову літери 'г' на 'х'",
    },
    {
      id: "121",
      questionUa: "Літера 'c' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "122",
      questionUa: "Літера 'r' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "123",
      questionUa: "Літера 'm' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "124",
      questionUa: "Літера 'g' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "125",
      questionUa: "Літера 'f' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "126",
      questionUa: "Літера 'd' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "127",
      questionUa: "Літера 'p' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "128",
      questionUa: "Літера 'l' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "129",
      questionUa: "Літера 'b' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "130",
      questionUa: "Літера 't' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "131",
      questionUa: "Літера 'n' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "132",
      questionUa: "Літера 'z' глуха чи дзвінка приголосна?",
      correctAnswer: "Дзвінка",
      options: ["Глуха"],
    },
    {
      id: "133",
      questionUa: "Літера 'h' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "134",
      questionUa: "Літера 'v' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
    {
      id: "135",
      questionUa: "Літера 'k' глуха чи дзвінка приголосна?",
      correctAnswer: "Глуха",
      options: ["Дзвінка"],
    },
  ],
  "7": [
    {
      id: "135",
      questionUa:
        "Чому у словах 'he, she, be, we' літера 'e' вимовляється як українська літера 'і'?",
      correctAnswer:
        "Це правило відкритого складу. Якщо слово має один склад і закінчується на 'e', то літера 'e' вимовляється як українська літера 'i'",
    },
    {
      id: "136",
      questionUa: " 'sheep' це 'вівця', а я буде 'вівці'?",
      correctAnswer: "Так само. 'sheep' це вівця а також вівці.",
    },
    {
      id: "137",
      questionUa: "Які форми дієслова 'be' ти знаєш?",
      correctAnswer: "am, is, are",
    },
    {
      id: "138",
      questionUa: "У чому різниця між 'have' та 'has'?",
      correctAnswer:
        "Обидва слова означають 'мати, володіти', але 'have' вживається з 'Я, ти, ми, вони', а 'has' вживається з 'він, вона, це, воно",
    },
    {
      id: "139",
      questionUa: "Чому у словах 'climb' та 'lamb' остання літера 'b' не вимовляється?",
      correctAnswer: "У такому типу слів де у кінці слова 'm + b' літера 'b' не вимовляється.",
    },
    {
      id: "140",
      questionUa: "Які артиклі ти знаєш?",
      correctAnswer: "the, a, an",
    },
    {
      id: "141",
      questionUa: "У чому різниця між артиклями 'a' та 'an'?",
      correctAnswer:
        " 'a' вживається з іменниками в однині якщо іменник починається на приголосний звук, 'an' вживається з іменниками в однині якщо іменник починається на голосний звук",
    },
    {
      id: "142",
      questionUa:
        "Чому перед 'apple' говоримо 'an' - 'an apple', а перед 'cat' говоримо 'a' - 'a cat'?",
      correctAnswer:
        "Тому що 'a' вживається перед іменником який починається на приголосний звук, 'an' вживається перед іменником який починається на голосний звук",
    },
    {
      id: "143",
      questionUa: "Як перекласти слово 'to' У реченні 'I like to eat apples'?",
      correctAnswer:
        "Тут слово 'to' не перекладається тут. В даному реченні 'to' це допоміжне слово яке потрібно ставити між двома дієсловами. У даному випадку 'like' та 'eat'",
    },
    {
      id: "144",
      questionUa: "Що означають артиклі 'a' та 'an'?",
      correctAnswer: "a/an = один, означає просто один 'іменник'(обʼєкт/річ/тварину/людину)",
    },
    {
      id: "145",
      questionUa: "Які дві головні відмінності між артиклями 'the' та 'a/an'?",
      correctAnswer:
        "Перша різниця: the - це означений артикль, вживається з іменниками які вже згадувалися раніше, або з тим про що ми знаємо. a/an вживається з іменниками які згадуються вперше. Друга різниця: 'a/an' - вживається тільки перед іменниками в однині, 'the'- вживається як перед іменниками в однині так і перед іменниками в множині",
    },
    {
      id: "146",
      questionUa: "Чому у слові 'to' та 'do' літера 'o' вимовляється як українська довга 'у'?",
      correctAnswer: "Це виняток. Потрібно запамʼятати.",
    },
    {
      id: "147",
      questionUa: "Коли літера 'e' вимовляється як українська літера 'i'?",
      correctAnswer:
        "1. у відкритому складі (he, she, be...). 2. коли дві літери 'ee' підряд (see, tree, need...). 3. (інколи) коли після літери 'e' іде літера 'a', тобто 'ea' (please, tea, eat...).",
    },
    {
      id: "148",
      questionUa: "Навіщо у словах 'see, tree' писати 'ee', чому просто не написати 'i'?",
      correctAnswer:
        "В такому випадку зміниться вимова, тому що це слова які складаються з одного відкритого складу. У відкритому складі літера 'i' вимовляється 'ай'",
    },
    {
      id: "149",
      questionUa: "Що таке займенники?",
      correctAnswer:
        "Це слова які допомагають нам замінювати іменники у речення, щоб не повторювати те саме у кожному реченні.",
    },
    {
      id: "150",
      questionUa: "Що таке 'this'? До якого типу займенників це слово відноситься?",
      correctAnswer:
        "Це слово яке вказує на 'предмет/людину/місце' (то що є іменником і можна порахувати) що знаходиться біля нас. Відноситься до вказівних займенників, тому що вказує на розташування.",
    },
    {
      id: "151",
      questionUa: "Яка головна різниця між 'this' та 'it'?",
      correctAnswer:
        " 'it' замінює те що ми вже сказали, 'this' вказує на те що 'предмет/людину/місце' знаходиться біля нас.",
    },
    {
      id: "152",
      questionUa: "Як правильно вимовляти 'oo' у словах 'root, room, goose'?",
      correctAnswer: "вимовляється як довга українська 'у'",
    },
    {
      id: "153",
      questionUa:
        "Навіщо у словах 'little', 'bottle', 'battle' писати дві літери 'tt' якщо вимовляємо тільки одну?",
      correctAnswer: "Тому що попередній голосний звук короткий.",
    },
  ],
  "8": [
    {
      id: "154",
      questionUa: "Як перекладається 'of' у реченні 'A box of apples.'?",
      correctAnswer:
        "Ніяк. Це слово яке допомагає зрозуміти нам що ящик з яблуками, а не просто 'ящик' і 'яблука' ",
    },
    {
      id: "155",
      questionUa: "Як перекладається 'of' у реченні 'A cup of tea.'?",
      correctAnswer:
        "Ніяк. Це слово яке допомагає зрозуміти нам що чашка з чаєм, а не просто 'чашка' і 'чай' ",
    },
    {
      id: "156",
      questionUa: "Як перекладається 'of' у реченні 'A glass of water.'?",
      correctAnswer:
        "Ніяк. Це слово яке допомагає зрозуміти нам що стакан з водою, а не просто 'стакан' і 'вода' ",
    },
    {
      id: "157",
      questionUa:
        "Чому у словах 'he, she, be, we' літера 'e' вимовляється як українська літера 'і'?",
      correctAnswer:
        "Це правило відкритого складу. Якщо слово має один склад і закінчується на 'e', то літера 'e' вимовляється як українська літера 'i'",
    },
    {
      id: "158",
      questionUa: " 'sheep' це 'вівця', а я буде 'вівці'?",
      correctAnswer: "Так само. 'sheep' це вівця а також вівці.",
    },
    {
      id: "159",
      questionUa: "Які форми дієслова 'be' ти знаєш?",
      correctAnswer: "am, is, are",
    },
    {
      id: "160",
      questionUa: "У чому різниця між 'have' та 'has'?",
      correctAnswer:
        "Обидва слова означають 'мати, володіти', але 'have' вживається з 'Я, ти, ми, вони', а 'has' вживається з 'він, вона, це, воно",
    },
    {
      id: "161",
      questionUa: "Чому у словах 'climb' та 'lamb' остання літера 'b' не вимовляється?",
      correctAnswer: "У такому типу слів де у кінці слова 'm + b' літера 'b' не вимовляється.",
    },
    {
      id: "162",
      questionUa: "Які артиклі ти знаєш?",
      correctAnswer: "the, a, an",
    },
    {
      id: "163",
      questionUa: "У чому різниця між артиклями 'a' та 'an'?",
      correctAnswer:
        " 'a' вживається з іменниками в однині якщо іменник починається на приголосний звук, 'an' вживається з іменниками в однині якщо іменник починається на голосний звук",
    },
    {
      id: "164",
      questionUa:
        "Чому перед 'apple' говоримо 'an' - 'an apple', а перед 'cat' говоримо 'a' - 'a cat'?",
      correctAnswer:
        "Тому що 'a' вживається перед іменником який починається на приголосний звук, 'an' вживається перед іменником який починається на голосний звук",
    },
    {
      id: "165",
      questionUa: "Як перекласти слово 'to' У реченні 'I like to eat apples'?",
      correctAnswer:
        "Тут слово 'to' не перекладається тут. В даному реченні 'to' це допоміжне слово яке потрібно ставити між двома дієсловами. У даному випадку 'like' та 'eat'",
    },
    {
      id: "166",
      questionUa: "Що означають артиклі 'a' та 'an'?",
      correctAnswer: "a/an = один, означає просто один 'іменник'(обʼєкт/річ/тварину/людину)",
    },
    {
      id: "167",
      questionUa: "Які дві головні відмінності між артиклями 'the' та 'a/an'?",
      correctAnswer:
        "Перша різниця: the - це означений артикль, вживається з іменниками які вже згадувалися раніше, або з тим про що ми знаємо. a/an вживається з іменниками які згадуються вперше. Друга різниця: 'a/an' - вживається тільки перед іменниками в однині, 'the'- вживається як перед іменниками в однині так і перед іменниками в множині",
    },
    {
      id: "168",
      questionUa: "Чому у слові 'to' та 'do' літера 'o' вимовляється як українська довга 'у'?",
      correctAnswer: "Це виняток. Потрібно запамʼятати.",
    },
    {
      id: "169",
      questionUa: "Коли літера 'e' вимовляється як українська літера 'i'?",
      correctAnswer:
        "1. у відкритому складі (he, she, be...). 2. коли дві літери 'ee' підряд (see, tree, need...). 3. (інколи) коли після літери 'e' іде літера 'a', тобто 'ea' (please, tea, eat...).",
    },
    {
      id: "170",
      questionUa: "Навіщо у словах 'see, tree' писати 'ee', чому просто не написати 'i'?",
      correctAnswer:
        "В такому випадку зміниться вимова, тому що це слова які складаються з одного відкритого складу. У відкритому складі літера 'i' вимовляється 'ай'",
    },
    {
      id: "171",
      questionUa: "Що таке займенники?",
      correctAnswer:
        "Це слова які допомагають нам замінювати іменники у речення, щоб не повторювати те саме у кожному реченні.",
    },
    {
      id: "172",
      questionUa: "Що таке 'this'? До якого типу займенників це слово відноситься?",
      correctAnswer:
        "Це слово яке вказує на 'предмет/людину/місце' (то що є іменником і можна порахувати) що знаходиться біля нас. Відноситься до вказівних займенників, тому що вказує на розташування.",
    },
    {
      id: "173",
      questionUa: "Яка головна різниця між 'this' та 'it'?",
      correctAnswer:
        " 'it' замінює те що ми вже сказали, 'this' вказує на те що 'предмет/людину/місце' знаходиться біля нас.",
    },
    {
      id: "174",
      questionUa: "Як правильно вимовляти 'oo' у словах 'root, room, goose'?",
      correctAnswer: "вимовляється як довга українська 'у'",
    },
    {
      id: "175",
      questionUa:
        "Навіщо у словах 'little', 'bottle', 'battle' писати дві літери 'tt' якщо вимовляємо тільки одну?",
      correctAnswer: "Тому що попередній голосний звук короткий.",
    },
  ],
  "9": [
    {
      id: "153",
      questionUa: "У чому різниця між 'store' та 'shop'?",
      correctAnswer:
        " 'store' - часто вживається як великий магазин чи склад. 'shop' - здебільшого невеличкий магазинчик.",
    },
    {
      id: "154",
      questionUa: "Як перекладається 'of' у реченні 'A box of apples.'?",
      correctAnswer:
        "Ніяк. Це слово яке допомагає зрозуміти нам що ящик з яблуками, а не просто 'ящик' і 'яблука' ",
    },
    {
      id: "155",
      questionUa: "Як перекладається 'of' у реченні 'A cup of tea.'?",
      correctAnswer:
        "Ніяк. Це слово яке допомагає зрозуміти нам що чашка з чаєм, а не просто 'чашка' і 'чай' ",
    },
    {
      id: "156",
      questionUa: "Як перекладається 'of' у реченні 'A glass of water.'?",
      correctAnswer:
        "Ніяк. Це слово яке допомагає зрозуміти нам що стакан з водою, а не просто 'стакан' і 'вода' ",
    },
    {
      id: "157",
      questionUa:
        "Чому у словах 'he, she, be, we' літера 'e' вимовляється як українська літера 'і'?",
      correctAnswer:
        "Це правило відкритого складу. Якщо слово має один склад і закінчується на 'e', то літера 'e' вимовляється як українська літера 'i'",
    },
    {
      id: "158",
      questionUa: " 'sheep' це 'вівця', а я буде 'вівці'?",
      correctAnswer: "Так само. 'sheep' це вівця а також вівці.",
    },
    {
      id: "159",
      questionUa: "Які форми дієслова 'be' ти знаєш?",
      correctAnswer: "am, is, are",
    },
    {
      id: "160",
      questionUa: "У чому різниця між 'have' та 'has'?",
      correctAnswer:
        "Обидва слова означають 'мати, володіти', але 'have' вживається з 'Я, ти, ми, вони', а 'has' вживається з 'він, вона, це, воно",
    },
    {
      id: "161",
      questionUa: "Чому у словах 'climb' та 'lamb' остання літера 'b' не вимовляється?",
      correctAnswer: "У такому типу слів де у кінці слова 'm + b' літера 'b' не вимовляється.",
    },
    {
      id: "162",
      questionUa: "Які артиклі ти знаєш?",
      correctAnswer: "the, a, an",
    },
    {
      id: "163",
      questionUa: "У чому різниця між артиклями 'a' та 'an'?",
      correctAnswer:
        " 'a' вживається з іменниками в однині якщо іменник починається на приголосний звук, 'an' вживається з іменниками в однині якщо іменник починається на голосний звук",
    },
    {
      id: "164",
      questionUa:
        "Чому перед 'apple' говоримо 'an' - 'an apple', а перед 'cat' говоримо 'a' - 'a cat'?",
      correctAnswer:
        "Тому що 'a' вживається перед іменником який починається на приголосний звук, 'an' вживається перед іменником який починається на голосний звук",
    },
    {
      id: "165",
      questionUa: "Як перекласти слово 'to' У реченні 'I like to eat apples'?",
      correctAnswer:
        "Тут слово 'to' не перекладається тут. В даному реченні 'to' це допоміжне слово яке потрібно ставити між двома дієсловами. У даному випадку 'like' та 'eat'",
    },
    {
      id: "166",
      questionUa: "Що означають артиклі 'a' та 'an'?",
      correctAnswer: "a/an = один, означає просто один 'іменник'(обʼєкт/річ/тварину/людину)",
    },
    {
      id: "167",
      questionUa: "Які дві головні відмінності між артиклями 'the' та 'a/an'?",
      correctAnswer:
        "Перша різниця: the - це означений артикль, вживається з іменниками які вже згадувалися раніше, або з тим про що ми знаємо. a/an вживається з іменниками які згадуються вперше. Друга різниця: 'a/an' - вживається тільки перед іменниками в однині, 'the'- вживається як перед іменниками в однині так і перед іменниками в множині",
    },
    {
      id: "168",
      questionUa: "Чому у слові 'to' та 'do' літера 'o' вимовляється як українська довга 'у'?",
      correctAnswer: "Це виняток. Потрібно запамʼятати.",
    },
    {
      id: "169",
      questionUa: "Коли літера 'e' вимовляється як українська літера 'i'?",
      correctAnswer:
        "1. у відкритому складі (he, she, be...). 2. коли дві літери 'ee' підряд (see, tree, need...). 3. (інколи) коли після літери 'e' іде літера 'a', тобто 'ea' (please, tea, eat...).",
    },
    {
      id: "170",
      questionUa: "Навіщо у словах 'see, tree' писати 'ee', чому просто не написати 'i'?",
      correctAnswer:
        "В такому випадку зміниться вимова, тому що це слова які складаються з одного відкритого складу. У відкритому складі літера 'i' вимовляється 'ай'",
    },
    {
      id: "171",
      questionUa: "Що таке займенники?",
      correctAnswer:
        "Це слова які допомагають нам замінювати іменники у речення, щоб не повторювати те саме у кожному реченні.",
    },
    {
      id: "172",
      questionUa: "Що таке 'this'? До якого типу займенників це слово відноситься?",
      correctAnswer:
        "Це слово яке вказує на 'предмет/людину/місце' (то що є іменником і можна порахувати) що знаходиться біля нас. Відноситься до вказівних займенників, тому що вказує на розташування.",
    },
    {
      id: "173",
      questionUa: "Яка головна різниця між 'this' та 'it'?",
      correctAnswer:
        " 'it' замінює те що ми вже сказали, 'this' вказує на те що 'предмет/людину/місце' знаходиться біля нас.",
    },
    {
      id: "174",
      questionUa: "Як правильно вимовляти 'oo' у словах 'root, room, goose'?",
      correctAnswer: "вимовляється як довга українська 'у'",
    },
    {
      id: "175",
      questionUa:
        "Навіщо у словах 'little', 'bottle', 'battle' писати дві літери 'tt' якщо вимовляємо тільки одну?",
      correctAnswer: "Тому що попередній голосний звук короткий.",
    },
  ],
  "10": [
    {
      id: "153",
      questionUa: "У чому різниця між 'store' та 'shop'?",
      correctAnswer:
        " 'store' - часто вживається як великий магазин чи склад. 'shop' - здебільшого невеличкий магазинчик.",
    },
    {
      id: "154",
      questionUa: "Як перекладається 'of' у реченні 'A box of apples.'?",
      correctAnswer:
        "Ніяк. Це слово яке допомагає зрозуміти нам що ящик з яблуками, а не просто 'ящик' і 'яблука' ",
    },
    {
      id: "155",
      questionUa: "Як перекладається 'of' у реченні 'A cup of tea.'?",
      correctAnswer:
        "Ніяк. Це слово яке допомагає зрозуміти нам що чашка з чаєм, а не просто 'чашка' і 'чай' ",
    },
    {
      id: "156",
      questionUa: "Як перекладається 'of' у реченні 'A glass of water.'?",
      correctAnswer:
        "Ніяк. Це слово яке допомагає зрозуміти нам що стакан з водою, а не просто 'стакан' і 'вода' ",
    },
    {
      id: "157",
      questionUa:
        "Чому у словах 'he, she, be, we' літера 'e' вимовляється як українська літера 'і'?",
      correctAnswer:
        "Це правило відкритого складу. Якщо слово має один склад і закінчується на 'e', то літера 'e' вимовляється як українська літера 'i'",
    },
    {
      id: "158",
      questionUa: " 'sheep' це 'вівця', а я буде 'вівці'?",
      correctAnswer: "Так само. 'sheep' це вівця а також вівці.",
    },
    {
      id: "159",
      questionUa: "Які форми дієслова 'be' ти знаєш?",
      correctAnswer: "am, is, are",
    },
    {
      id: "160",
      questionUa: "У чому різниця між 'have' та 'has'?",
      correctAnswer:
        "Обидва слова означають 'мати, володіти', але 'have' вживається з 'Я, ти, ми, вони', а 'has' вживається з 'він, вона, це, воно",
    },
    {
      id: "161",
      questionUa: "Чому у словах 'climb' та 'lamb' остання літера 'b' не вимовляється?",
      correctAnswer: "У такому типу слів де у кінці слова 'm + b' літера 'b' не вимовляється.",
    },
    {
      id: "162",
      questionUa: "Які артиклі ти знаєш?",
      correctAnswer: "the, a, an",
    },
    {
      id: "163",
      questionUa: "У чому різниця між артиклями 'a' та 'an'?",
      correctAnswer:
        " 'a' вживається з іменниками в однині якщо іменник починається на приголосний звук, 'an' вживається з іменниками в однині якщо іменник починається на голосний звук",
    },
    {
      id: "164",
      questionUa:
        "Чому перед 'apple' говоримо 'an' - 'an apple', а перед 'cat' говоримо 'a' - 'a cat'?",
      correctAnswer:
        "Тому що 'a' вживається перед іменником який починається на приголосний звук, 'an' вживається перед іменником який починається на голосний звук",
    },
    {
      id: "165",
      questionUa: "Як перекласти слово 'to' У реченні 'I like to eat apples'?",
      correctAnswer:
        "Тут слово 'to' не перекладається тут. В даному реченні 'to' це допоміжне слово яке потрібно ставити між двома дієсловами. У даному випадку 'like' та 'eat'",
    },
    {
      id: "166",
      questionUa: "Що означають артиклі 'a' та 'an'?",
      correctAnswer: "a/an = один, означає просто один 'іменник'(обʼєкт/річ/тварину/людину)",
    },
    {
      id: "167",
      questionUa: "Які дві головні відмінності між артиклями 'the' та 'a/an'?",
      correctAnswer:
        "Перша різниця: the - це означений артикль, вживається з іменниками які вже згадувалися раніше, або з тим про що ми знаємо. a/an вживається з іменниками які згадуються вперше. Друга різниця: 'a/an' - вживається тільки перед іменниками в однині, 'the'- вживається як перед іменниками в однині так і перед іменниками в множині",
    },
    {
      id: "168",
      questionUa: "Чому у слові 'to' та 'do' літера 'o' вимовляється як українська довга 'у'?",
      correctAnswer: "Це виняток. Потрібно запамʼятати.",
    },
    {
      id: "169",
      questionUa: "Коли літера 'e' вимовляється як українська літера 'i'?",
      correctAnswer:
        "1. у відкритому складі (he, she, be...). 2. коли дві літери 'ee' підряд (see, tree, need...). 3. (інколи) коли після літери 'e' іде літера 'a', тобто 'ea' (please, tea, eat...).",
    },
    {
      id: "170",
      questionUa: "Навіщо у словах 'see, tree' писати 'ee', чому просто не написати 'i'?",
      correctAnswer:
        "В такому випадку зміниться вимова, тому що це слова які складаються з одного відкритого складу. У відкритому складі літера 'i' вимовляється 'ай'",
    },
    {
      id: "171",
      questionUa: "Що таке займенники?",
      correctAnswer:
        "Це слова які допомагають нам замінювати іменники у речення, щоб не повторювати те саме у кожному реченні.",
    },
    {
      id: "172",
      questionUa: "Що таке 'this'? До якого типу займенників це слово відноситься?",
      correctAnswer:
        "Це слово яке вказує на 'предмет/людину/місце' (то що є іменником і можна порахувати) що знаходиться біля нас. Відноситься до вказівних займенників, тому що вказує на розташування.",
    },
    {
      id: "173",
      questionUa: "Яка головна різниця між 'this' та 'it'?",
      correctAnswer:
        " 'it' замінює те що ми вже сказали, 'this' вказує на те що 'предмет/людину/місце' знаходиться біля нас.",
    },
    {
      id: "174",
      questionUa: "Як правильно вимовляти 'oo' у словах 'root, room, goose'?",
      correctAnswer: "вимовляється як довга українська 'у'",
    },
    {
      id: "175",
      questionUa:
        "Навіщо у словах 'little', 'bottle', 'battle' писати дві літери 'tt' якщо вимовляємо тільки одну?",
      correctAnswer: "Тому що попередній голосний звук короткий.",
    },
  ],
  "11": [
    {
      id: "175",
      questionUa:
        "Чому літера 'c' читається як українська 'c' у слові 'fleece' а не як 'к' як у словах 'cat, climb, coffee, can...'?",
      correctAnswer:
        "Тому що ця літера стоїть пере літерою 'e', в такому випадку вона вимовляється як українська 'c'",
    },
    {
      id: "176",
      questionUa: "Яка минула форма слова 'go'?",
      correctAnswer: "went",
    },
    {
      id: "177",
      questionUa: "Яка минула форма слова 'follow'?",
      correctAnswer: "followed",
    },
    {
      id: "178",
      questionUa: "Слово 'follow' це правильне чи неправильне дієслово?",
      correctAnswer: "правильне",
    },
    {
      id: "179",
      questionUa: "Слово 'go' це правильне чи неправильне дієслово?",
      correctAnswer: "неправильне",
    },
    {
      id: "180",
      questionUa: "Як буде 'це' та 'те'?",
      correctAnswer: " 'this' та 'that' ",
    },
    {
      id: "181",
      questionUa:
        "Чому у словах 'sure, rule' літера 'u' читається як українська 'у' а не 'a' як у словах 'run, understand, summer...'?",
      correctAnswer:
        "тому що літера 'e' у кінці слова змінює попередній голосний звук з короткого на довгий",
    },
    {
      id: "182",
      questionUa: "У чому різниця між 'at school' та 'in school'?",
      correctAnswer:
        " 'at school' - на території школи або всередині будівлі, 'in school' - всередині будівлі ",
    },
  ],
  "12": [
    {
      id: "175",
      questionUa:
        "Чому літера 'a' читається як 'ей' у словах 'baby', 'lady' а не як 'е' як у словах 'apple, cat, can...'?",
      correctAnswer:
        "Тому що у кінці слова літера 'y' яка змінює попередній голосний звук з короткого на довгий",
    },
    {
      id: "176",
      questionUa: "Чому літера 'h' не читається у слові 'where'?",
      correctAnswer:
        "Літера 'h' не читається у словах після літери 'w'. Подібні слова: 'what, white, when' ",
    },
    {
      id: "177",
      questionUa:
        "Чому літера 'a' читається як 'o' у словах 'want', 'wash', 'water' а не як 'е' як у словах 'apple, cat, can...'?",
      correctAnswer: "Літера 'a' зазвичай читається як 'o' якщо вона знаходиться після літери 'w'",
    },
    {
      id: "178",
      questionUa:
        "Чому літера 'c' читається як українська 'c' у слові 'fleece' а не як 'к' як у словах 'cat, climb, coffee, can...'?",
      correctAnswer:
        "Тому що ця літера стоїть пере літерою 'e', в такому випадку вона вимовляється як українська 'c'",
    },
    {
      id: "179",
      questionUa: "Яка минула форма слова 'go'?",
      correctAnswer: "went",
    },
    {
      id: "180",
      questionUa: "Яка минула форма слова 'follow'?",
      correctAnswer: "followed",
    },
    {
      id: "181",
      questionUa: "Слово 'follow' це правильне чи неправильне дієслово?",
      correctAnswer: "правильне",
    },
    {
      id: "182",
      questionUa: "Слово 'go' це правильне чи неправильне дієслово?",
      correctAnswer: "неправильне",
    },
    {
      id: "183",
      questionUa: "Як буде 'це' та 'те'?",
      correctAnswer: " 'this' та 'that' ",
    },
    {
      id: "184",
      questionUa:
        "Чому у словах 'sure, rule' літера 'u' читається як українська 'у' а не 'a' як у словах 'run, understand, summer...'?",
      correctAnswer:
        "тому що літера 'e' у кінці слова змінює попередній голосний звук з короткого на довгий",
    },
    {
      id: "185",
      questionUa: "У чому різниця між 'at school' та 'in school'?",
      correctAnswer:
        " 'at school' - на території школи або всередині будівлі, 'in school' - всередині будівлі ",
    },
  ],
  "13": rules13,
  "14": rules13,
  "15": rules15,
  "16": rules16,
  "17": rules17,
  "18": rules18,
  "19": rules19,
  "20": rules20,
  "21": rules21,
};

// const lastLessonWithTasks = 6;

const highlight = (text: string, fontWeight?: number, color?: string) => {
  return splitByQuotes(text).map((part, index) => {
    if (index % 2 !== 0) {
      return (
        <ItalicTitle
          key={index}
          // fontWeight={fontWeight}
          text={part}
          color="green"
          size={25}
          isItalic={false}
          fontFamily="Roboto"
        />
      );
    }
    return (
      <ItalicTitle
        key={index}
        size={23}
        color={color || "black"}
        // fontWeight={fontWeight}
        isItalic={false}
        text={part}
      />
    );
  });
};

const Option: React.FC<{ text: string; correctAnswer: string; onAnswer: () => void }> = ({
  text,
  correctAnswer,
  onAnswer,
}) => {
  const [color, setColor] = useState<"inherit" | "error">("inherit");

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (color === "error") {
      timeout = setTimeout(() => {
        setColor("inherit");
      }, 1000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [color]);

  return (
    <Button
      variant="outlined"
      sx={{
        color: color === "error" ? "#ffb0b0" : "black",
        textAlign: "left",
        display: "block",
        border: `3px solid ${color === "error" ? "#ffb0b0" : "#efefef"}`,
        // color: !visible ? "#e3e3e3" : "#a77301",
        transition: "ease border 0.2s",
        "&:hover": {
          border: `3px solid ${color === "error" ? "#ffb0b0" : "#efefef"}`,
        },
        "& > span": {
          // transition: "ease color 0.2s",
          fontSize: 16,
          // color: color === "error" ? "#ffb0b0" : "black",
        },
      }}
      onClick={() => {
        if (correctAnswer === text) {
          onAnswer();
        } else {
          setColor("error");
        }
      }}
    >
      {highlight(text, 400)}
    </Button>
  );
};

const SimpleLessonTasks: React.FC<{ rules?: LessonRule[] }> = ({ rules }) => {
  const { lessonId, lessonType } = useParams();
  const lessonModalOpen = useLessonsStore((state) => state.lessonModalOpen === "rules");
  const setLessonModalOpen = useLessonsStore((state) => state.setLessonModalOpen);
  const [taskIndex, setTaskIndex] = useState(0);
  const [nextIsLoading, setNextIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [correctAnswered, setCorrectAnswered] = useState<string>("");
  const [isAnswerd, setIsAnswerd] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const authUser = useLessonsStore((state) => state.authUser);
  const rulesToRepeat = useLessonsStore((state) => state.homeWork.rulesToRepeat);
  const updateHomeWork = useLessonsStore((state) => state.updateHomeWork);
  const homeWork = useLessonsStore((state) => state.homeWork);
  const [lessonTasks, setLessonTasks] = useState<LessonRule[]>([]);

  useEffect(() => {
    if (rules) {
      setLessonTasks(rules);
    } else {
      const t = lessonRules[lessonId || "1"];
      if (t) {
        setLessonTasks(t);
      }
    }
  }, [lessonId, rules]);

  // useEffect(() => {

  // })

  const restart = () => {
    setTaskIndex(0);
    setCorrectAnswered("");
    setIsAnswerd(false);
    setIsSuccess(false);
    setIsOptionsVisible(false);

    if (rules) {
      setLessonTasks(rules);
    } else {
      setLessonTasks(lessonRules[lessonId || "1"]);
    }
  };

  useEffect(() => {
    restart();
  }, [lessonModalOpen]);

  // const repeatedRules = useLessonsStore((state) => state.homeWork.rulesToRepeat);
  const isHomeWork = useIsHomeWork({ lessonId: +(lessonId || 0), lessonType: lessonType || "" });
  const currentTask = useMemo(() => {
    if (!lessonTasks) return null;
    if (rulesToRepeat.length && isHomeWork) {
      const task = lessonTasks.find((t) => t.id === rulesToRepeat[0]);
      if (task) {
        return task;
      }
    }
    return lessonTasks[taskIndex];
  }, [lessonTasks, isHomeWork, taskIndex, rulesToRepeat]);

  useEffect(() => {
    let timer: any = null;
    if (currentTask && correctAnswered === currentTask.correctAnswer) {
      setIsAnswerd(true);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [correctAnswered, currentTask]);
  const tasksOptions = useMemo(() => {
    if (!lessonTasks) return [];
    const words = [...lessonTasks.map((t) => [...t.correctAnswer, ...(t.options || [])]).flat()];
    // if (lessonId && Number(lessonId) <= lastLessonWithTasks && lessonRules[lessonId!]) {
    //   words.push(...lessonRules[lessonId!].map((task) => task.letter));
    // }
    return Array.from(new Set(words));
  }, [lessonTasks, lessonId]);

  const taskWords = useMemo(() => {
    if (!currentTask) return [];
    return shuffle(
      Array.from(new Set(shuffle([currentTask.correctAnswer, ...(currentTask.options || [])]))),
    );
  }, [currentTask]);

  const handleDrawerClose = () => {
    setLessonModalOpen("");
  };
  useEffect(() => {
    if (taskWords.length === 2) {
      setIsOptionsVisible(true);
    } else {
      setIsOptionsVisible(false);
    }
  }, [taskWords]);

  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  if (!lessonTasks || !lessonTasks.length) return null;

  return (
    <>
      {/* <LinearProgress height={4} elementsCount={wordsLength} currentValue={wordIndex} /> */}
      <Button variant="outlined" onClick={() => setLessonModalOpen("rules")}>
        Правила
      </Button>
      <Drawer
        open={lessonModalOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 9,
        }}
      >
        <Box sx={drawerContentStyle}>
          <ModalCloseButton onClose={handleDrawerClose} />
          <LinearProgress height={7} elementsCount={lessonTasks.length} currentValue={taskIndex} />
          {/* {isHomeWork && (
            <Box pt={14}>
              <ShowCurrentHomeWorkNumbersInDrawer type="rules" />
            </Box>
          )} */}
          {/* {!isSuccess && (
            <Box position="absolute" top={10} left={10}>
              <Typography>
                Запитань залишилось:&nbsp;{" "}
                <ItalicTitle
                  text={(lessonTasks.length - taskIndex).toString()}
                  color="green"
                  size={25}
                />
              </Typography>
            </Box>
          )} */}
          <Box sx={{ backgroundColor: "white" }} py={15} px={3} mb={2} width="100%">
            {!isHomeWork && (
              <CongratulationsModal
                open={isSuccess}
                message="Всі завдання виконані&nbsp;🚀"
                onClose={() => {
                  restart();
                  setLessonModalOpen("");
                }}
              />
            )}
            {/* {isSuccess && (
              <VStack>
                <Typography textAlign="center" pt={2}>
                  Всі завдання виконані&nbsp;🚀
                </Typography>
                <Button
                  variant="outlined"
                  onClick={() => {
                    restart();
                  }}
                >
                  Почати з початку
                </Button>
              </VStack>
            )} */}
            {!isSuccess && currentTask && (
              <VStack>
                <Box mb={4}>
                  <Typography variant="h2">
                    {currentTask.questionUa && <>{highlight(currentTask.questionUa)}</>}
                  </Typography>
                </Box>
                <VStack mb={2}>
                  {correctAnswered && (
                    <>
                      {taskWords.length !== 1 && (
                        <Typography color="primary" variant="h3">
                          Правильно
                        </Typography>
                      )}
                      <Typography>{highlight(correctAnswered, 400, "#8d7536")}</Typography>
                    </>
                  )}
                </VStack>
                {!isOptionsVisible && taskWords.length !== 2 && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (taskWords.length === 1) {
                        setIsAnswerd(true);
                        setIsOptionsVisible(true);
                        setCorrectAnswered(taskWords[0]);
                      } else {
                        setIsOptionsVisible(true);
                      }
                    }}
                  >
                    {taskWords.length === 1 ? "Показати відповідь" : "Варіанти відповіді"}
                  </Button>
                )}
                {!isLoading && isOptionsVisible && !isAnswerd && (
                  <HStack mb={4} justifyContent="center" maxWidth={500} position="relative">
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: 500,
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      {taskWords.map((text, index) => {
                        return (
                          <Option
                            key={index}
                            text={text}
                            correctAnswer={currentTask.correctAnswer}
                            onAnswer={() => {
                              setCorrectAnswered(text);
                            }}
                          />
                        );
                      })}
                    </Box>
                  </HStack>
                )}
                {isAnswerd && (
                  <Box py={6}>
                    <LoadingButton
                      variant="outlined"
                      loading={nextIsLoading}
                      onClick={async () => {
                        setCorrectAnswered("");
                        if (taskWords.length !== 2) {
                          setIsOptionsVisible(false);
                        } else {
                          setIsOptionsVisible(true);
                        }
                        if (taskIndex + 1 < lessonTasks.length) {
                          setTaskIndex(taskIndex + 1);
                        } else {
                          setTaskIndex(0);
                          if (!isHomeWork) {
                            setIsSuccess(true);
                          }
                        }

                        if (isHomeWork) {
                          const hmWordsTracking = localStorage.getItem(userHomeWorkCurrentDay);
                          let newHmWordsTracking: Record<string, any> = {
                            ...parsed(hmWordsTracking, {}),
                          };
                          const currentDate = format(new Date(), "yyyy-MM-dd");

                          if (!newHmWordsTracking[currentDate]) {
                            newHmWordsTracking = {
                              ...newHmWordsTracking,
                              [currentDate]: {
                                date: currentDate,
                                repeatedRules: [],
                              },
                            };
                          }
                          if (!newHmWordsTracking[currentDate]?.repeatedRules) {
                            newHmWordsTracking[currentDate].repeatedRules = [];
                          }

                          const updatedRules = Array.from(
                            new Set([
                              ...newHmWordsTracking[currentDate].repeatedRules,
                              currentTask.id,
                            ]),
                          );
                          newHmWordsTracking[currentDate].repeatedRules = updatedRules;

                          localStorage.setItem(
                            userHomeWorkCurrentDay,
                            JSON.stringify(newHmWordsTracking),
                          );

                          const newRulesToRepeat = rulesToRepeat.filter(
                            (w) => !updatedRules.includes(w),
                          );
                          updateHomeWork({
                            repeatedRules: updatedRules,
                            rulesToRepeat: rulesToRepeat.filter((w) => !updatedRules.includes(w)),
                          });
                        }

                        setIsAnswerd(false);

                        // if (errorCount) {
                        //   setLessonTasks((prev) => [...prev, currentTask]);
                        //   setErrorCount(0);
                        // }
                      }}
                    >
                      Далі
                    </LoadingButton>
                  </Box>
                )}
              </VStack>
            )}
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default SimpleLessonTasks;
