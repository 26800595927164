import { IconButton, Typography } from "@mui/material";
import Box from "../../../Unknown/Box";
import { learnToRead } from "../CourseProgram";
import HStack from "../../../Unknown/HStack";
import AddIcon from "@mui/icons-material/Add";
import { useLessonsStore } from "../../../../store/lessonsStore";
import supabaseClient from "../../../../common/supabaseClient";
import { Word } from "../../../Admin/SPTranslationItem";

const AdminLearnToRead = () => {
  const studentWordsToRead = useLessonsStore((state) => state.studentWordsToRead);
  const setStudentWordsToRead = useLessonsStore((state) => state.setStudentWordsToRead);
  const setGlobalWords = useLessonsStore((state) => state.setGlobalWords);

  return (
    <Box width="600px">
      {Object.entries(learnToRead).map(([lesson, words]) => {
        return (
          <Box>
            <HStack>
              <IconButton
                onClick={async () => {
                  const flatWords = words.flat();
                  const { data: spWords } = await supabaseClient
                    .from("words")
                    .select("name, translation, transcription")
                    .in("name", flatWords);

                  await supabaseClient
                    .from("admin_control")
                    .update({
                      words_to_read: flatWords,
                    })
                    .eq("id", 0);

                  if (spWords) setStudentWordsToRead(flatWords);
                  if (spWords) setGlobalWords(spWords as Word[]);
                }}
              >
                <AddIcon />
              </IconButton>
              <Typography variant="h2">Lesson {lesson}</Typography>
            </HStack>
            {words.map((nextWords) => {
              return (
                <Box>
                  <hr />
                  {nextWords.map((w) => (
                    <Typography
                      sx={{
                        display: "inline-block",
                        px: 1,
                      }}
                    >
                      {w}
                    </Typography>
                  ))}
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};

export default AdminLearnToRead;
