import { Typography } from "@mui/material";
import firstLetterUppercase from "../../../common/firstLetterUppercase";

const getSentenceWordMatch = (sentence: string, highlight: string) => {
  const replaceWithHighlight = (highlight: string) => {
    const parts = sentence.split(new RegExp(`\\b(${highlight})\\b`, "i"));
    return parts.map((part, index) => {
      return (
        <Typography
          key={index}
          component="span"
          fontWeight="bold"
          color={part.toLowerCase() !== highlight.toLowerCase() ? "secondary.dark" : "primary"}
          fontSize={20}
        >
          {part}
        </Typography>
      );
    });
  };

  console.log("🚀 ~ sentence:", sentence);
  if (sentence.match(`\\b${highlight.toLowerCase()}\\b`)) {
    return replaceWithHighlight(highlight.toLowerCase());
  } else if (sentence.match(`\\b${highlight}\\b`)) {
    return replaceWithHighlight(highlight);
  } else if (sentence.match(firstLetterUppercase(highlight))) {
    return replaceWithHighlight(firstLetterUppercase(highlight));
  } else {
    console.log("do not match", highlight, firstLetterUppercase(highlight));
  }
};

export default getSentenceWordMatch;
