import LoadingButton from "@mui/lab/LoadingButton";
import { useLessonsStore } from "../../../../store/lessonsStore";
import { useState } from "react";
import {
  createUserLesson,
  getUserLesson,
  updateUserLesson,
} from "../../../Unknown/Root/ControllingStudentPage";
import supabaseClient from "../../../../common/supabaseClient";
import getTextFromEditorState from "../../../../common/getTextFromEditorState";

const AddWordsToCurrentUserLesson: React.FC<{ words?: string[] }> = ({ words }) => {
  const [isLoading, setIsLoading] = useState(false);
  const studentUser = useLessonsStore((state) => state.studentUser);
  const newUserLesson = useLessonsStore((state) => state.newUserLesson);
  const setNewUserLesson = useLessonsStore((state) => state.setNewUserLesson);
  const setEditorState = useLessonsStore((state) => state.setEditorState);
  const editorState = useLessonsStore((state) => state.editorState);

  const getStudentUser = async () => {
    if (!studentUser) return;
    const { data: studentDbUser } = await supabaseClient
      .from("users")
      .select()
      .eq("id", studentUser.id)
      .single();

    return studentDbUser;
  };

  return (
    <>
      {words && Boolean(words?.length) && (
        <LoadingButton
          loading={isLoading}
          variant="contained"
          color={"primary"}
          onClick={async () => {
            if (!studentUser) {
              alert("no user found");
              return;
            }
            setIsLoading(true);
            const studentDbUser = await getStudentUser();

            if (!studentDbUser) return;

            // today user lesson
            const userLesson = await getUserLesson(studentUser.id, "english_user_lessons");

            if (userLesson) {
              await updateUserLesson(
                {
                  user: studentUser.id,
                  // video_ids: [...(userLesson.video_ids || []), storyId].map((id) => Number(id)),
                  words: Array.from(new Set([...(userLesson?.words || []), ...words])),
                },
                "english_user_lessons",
              );
            } else {
              await createUserLesson(
                { user: studentUser.id, video_ids: [], words },
                "english_user_lessons",
              );
            }

            let updatedOrCreatedUserLesson = await getUserLesson(
              studentUser.id,
              "english_user_lessons",
            );
            console.log("🚀 ~ updatedOrCreatedUserLesson:", updatedOrCreatedUserLesson);
            setNewUserLesson(updatedOrCreatedUserLesson);

            setIsLoading(false);
          }}
        >
          {/* <Tooltip title="Add to user video ids">
          <PersonAddIcon />
        </Tooltip> */}
          Add words from current lesson
          {/* {type === "prev" ? "Add to user video ids" : "Add to ignore video ids"} */}
        </LoadingButton>
      )}
      <LoadingButton
        loading={isLoading}
        variant="contained"
        color={"primary"}
        onClick={async () => {
          if (!studentUser) {
            alert("no user found");
            return;
          }
          setIsLoading(true);
          const studentDbUser = await getStudentUser();

          if (!studentDbUser) return;

          // today user lesson
          const userLesson = await getUserLesson(studentUser.id, "english_user_lessons");
          const words = getTextFromEditorState(editorState)
            .split("   ")
            .map((w) => w.trim());
          console.log("🚀 ~ words:", words);

          if (userLesson) {
            await updateUserLesson(
              {
                user: studentUser.id,
                // video_ids: [...(userLesson.video_ids || []), storyId].map((id) => Number(id)),
                reading_words: Array.from(
                  new Set([...(userLesson?.reading_words || []), ...words]),
                ),
              },
              "english_user_lessons",
            );
          } else {
            await createUserLesson(
              { user: studentUser.id, video_ids: [], reading_words: words },
              "english_user_lessons",
            );
          }

          let updatedOrCreatedUserLesson = await getUserLesson(
            studentUser.id,
            "english_user_lessons",
          );
          console.log("🚀 ~ updatedOrCreatedUserLesson:", updatedOrCreatedUserLesson);
          setNewUserLesson(updatedOrCreatedUserLesson);

          setEditorState("");
          setIsLoading(false);
        }}
      >
        {/* <Tooltip title="Add to user video ids">
          <PersonAddIcon />
        </Tooltip> */}
        Add reading words from editor state
        {/* {type === "prev" ? "Add to user video ids" : "Add to ignore video ids"} */}
      </LoadingButton>
    </>
  );
};

export default AddWordsToCurrentUserLesson;
