import { Box, Button, Container, Grid, TextField, Typography } from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { EditText, EditTextarea } from "react-edit-text";
import { useFirebaseApp } from "reactfire";
import getGCRandomVoice from "../../../common/getGCRandomVoice";
import supabaseClient from "../../../common/supabaseClient";
import wait from "../../../common/wait";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { Word } from "../../../types/supabase";
import WordAudio from "../../Audio/WordAudio";
import HStack from "../../Unknown/HStack";
import VStack from "../../Unknown/VStack";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import { useLessonsStore } from "../../../store/lessonsStore";
import DisplayToSkipWords from "../DisplayToSkipWords";
import CopyNewWordsForGemini from "../CopyNewWordsForGemini";
import getSkipWords from "../../../common/getSkipWords";
import filterSkipedWords from "../../../common/filterSkipedWords";
import { wordsWithoutImages } from "../../../common/constants";
import FoodItemImage from "../../Food/FoodItemImage";
import LoadingButton from "@mui/lab/LoadingButton";
import AdminMainScreen from "../AdminMainScreen";

const getUnverifiedWords = async () => {
  try {
    const { data: unverifiedWords } = await supabaseClient
      .from("words")
      .select()
      .is("verified", false)
      .limit(10)
      .throwOnError()
      .returns<Word[]>();

    if (!unverifiedWords) throw new Error("Something went worng. Unverified words are empty.");

    return unverifiedWords;
  } catch (err) {
    console.error("ERROR", err);
  }

  return [];
};

const AdminVerifyWords = () => {
  const [unverifiedWords, setUnverifiedWords] = useState<Word[]>([]);
  const [word, setWord] = useState<any>([]);
  console.log("🚀 ~ 11212word:", word);
  const newWords = useLessonsStore((state) => state.newWords);
  const setNewWords = useLessonsStore((state) => state.setNewWords);

  // useMountedWaitAsyncEffect(async () => {
  //   if (newWords.length) {
  //     const skipWords = await getSkipWords(newWords);
  //     console.log(
  //       "🚀 ~ skipWords:",
  //       skipWords,
  //       filterSkipedWords(newWords, skipWords),
  //     );
  //     setNewWords(filterSkipedWords(newWords, skipWords));
  //   }
  // }, [newWords]);

  const [value, setValue] = useState("");
  const [wordToFetch, setWordToFetch] = useState("");

  useMountedWaitAsyncEffect(async () => {
    // const { data } = await supabaseClient.rpc("get_all_words");
    if (wordToFetch) {
      const { data } = await supabaseClient
        .from("words")
        .select()
        .eq("name", wordToFetch)
        .maybeSingle();

      if (data) setWord(data);
    } else {
      const { data } = await supabaseClient
        .from("words")
        .select()
        .is("verified", false)
        .order("created_at", {
          ascending: false,
        })
        // .limit(30);
        .limit(1)
        .single();

      if (data) setWord(data);
    }
  }, [wordToFetch]);

  const app = useFirebaseApp();
  const functions = getFunctions(app, "europe-west3");

  const textToSpeechFunction = httpsCallable(
    functions,
    "recordEnglishTextToSpeechAndReturnStorageUrl",
  );

  const createAudio = async (word: string) => {
    try {
      const result = await textToSpeechFunction({
        // en: word.replace("(verb) ", ""),
        en: word,
        voiceName: getGCRandomVoice(),
      });
      return result;
    } catch (err) {
      console.log("🚀 try/catch create autio error", err);
    }
  };

  const [loading, setLoading] = useState(false);
  const [wordName, setWordName] = useState("");
  const [wordTranscription, setWordTranscription] = useState("");
  console.log("🚀 ~ wordTranscription:", wordTranscription);
  const [wordTranslation, setWordTranslation] = useState("");
  const [wordDescription, setWordDescription] = useState("");
  const [imageName, setImageName] = useState("");
  const [wordOtherTranslations, setWordOtherTranslations] = useState("");

  const addGenderEndings = (str: string) => {
    return str
      .split(",")
      .map((w) => {
        if (w.endsWith("ий")) {
          return str.replace(/ий$/, "(-ий/-а/-е)");
        }
        return w;
      })
      .join(", ");
  };

  const updateInputs = () => {
    if (!word) return;

    setWordName(word.name);
    setWordTranscription(addGenderEndings(word.transcription || ""));
    setWordTranslation(addGenderEndings(word.translation || ""));
    setWordDescription(word.description || "");
    setImageName(word.image_name || "");
    setWordOtherTranslations(word.other_translation || "");
  };

  useEffect(() => {
    updateInputs();
  }, [word]);

  const loadNext = async () => {
    setLoading(true);

    try {
      const { data: newWord } = await supabaseClient
        .from("words")
        .select()
        .is("verified", false)
        .limit(1)
        .single()
        .throwOnError();

      console.log("🚀 ~ newWord:", newWord);
      setWord(newWord);
    } catch (err: any) {
      console.log("🚀 ~ err:", err);
      alert(err.message);
    }

    updateInputs();
    setLoading(false);
  };

  return (
    <Container>
      <Grid container>
        <Box py={4}>
          <AdminMainScreen />
        </Box>
        <Box>
          {/* <HStack>
            <TextField
              name="enText"
              multiline
              value={value}
              fullWidth
              label="Word"
              onChange={(event) => setValue(event.target.value)}
            />
            <Button
              variant="outlined"
              onClick={() => {
                setWordToFetch(value);
              }}
            >
              Submit
            </Button>
          </HStack> */}
          <HStack>
            <Button
              color="info"
              variant="outlined"
              onClick={async () => {
                setWord(null);
                setTimeout(async () => {
                  await loadNext();
                }, 500);
              }}
            >
              reset
            </Button>
            <TextField
              fullWidth
              color="primary"
              value={wordName}
              multiline={false}
              onChange={(event) => {
                setWordName(event.target.value);
              }}
              type="text"
              variant="outlined"
            />
            <HStack width="100%" flexWrap="nowrap">
              <LoadingButton
                sx={{ minWidth: 0, padding: 0, fontSize: 40 }}
                loading={loading}
                onClick={async () => {
                  if (!word) return;
                  setLoading(true);

                  try {
                    const audioResponse: any = await createAudio(word.name);

                    await supabaseClient
                      .from("words")
                      .update({
                        audio_url: audioResponse?.data?.url,
                      })
                      .eq("name", word.name);

                    const updatedWord = await supabaseClient
                      .from("words")
                      .select()
                      .eq("name", word.name)
                      .single();

                    setWord(updatedWord);
                  } catch (err: any) {
                    console.log("🚀 ~ err:", err);
                    alert(err.message);
                  }
                  setLoading(false);
                }}
              >
                🆕
              </LoadingButton>
              {word?.audio_url && <WordAudio url={word.audio_url} />}
              <TextField
                fullWidth
                color="primary"
                value={wordTranscription}
                multiline={false}
                onChange={(event) => {
                  console.log("event.target.value", event.target.value);
                  setWordTranscription(event.target.value);
                }}
                type="text"
                variant="outlined"
              />
            </HStack>
            <TextField
              fullWidth
              color="primary"
              value={wordTranslation}
              multiline={false}
              onChange={(event) => {
                setWordTranslation(event.target.value);
              }}
              type="text"
              variant="outlined"
            />
            <TextField
              fullWidth
              color="primary"
              value={wordOtherTranslations}
              multiline={true}
              placeholder="Other translations"
              onChange={(event) => {
                setWordOtherTranslations(event.target.value);
              }}
              type="text"
              variant="outlined"
            />
            <TextField
              fullWidth
              color="primary"
              value={wordDescription}
              multiline={true}
              placeholder="Description"
              onChange={(event) => {
                setWordDescription(event.target.value);
              }}
              type="text"
              variant="outlined"
            />
            {imageName && (
              <Button
                color="error"
                variant="outlined"
                onClick={() => {
                  setImageName("");
                }}
              >
                Remove image name
              </Button>
            )}
            <LoadingButton
              loading={loading}
              variant="contained"
              color="error"
              onClick={async () => {
                if (!word) return;
                setLoading(true);

                // setWord(updatedWord);
                try {
                  await supabaseClient
                    .from("words")
                    .update({
                      name: wordName,
                      transcription: wordTranscription,
                      translation: wordTranslation,
                      other_translation: wordOtherTranslations || "",
                      description: wordDescription || "",
                      image_name: imageName,
                      verified: true,
                    })
                    .eq("name", word.name)
                    .throwOnError();

                  const updatedWord = await supabaseClient
                    .from("words")
                    .select()
                    .eq("name", word.name)
                    .limit(1)
                    .single()
                    .throwOnError();

                  setWord(updatedWord);
                  setTimeout(async () => {
                    // setWord(null);

                    await loadNext();
                    // const newWord = await supabaseClient
                    //   .from("words")
                    //   .select()
                    //   .is("verified", false)
                    //   .limit(1)
                    //   .single()
                    //   .throwOnError();

                    // setWord(newWord);
                  }, 500);
                } catch (err: any) {
                  console.log("🚀 ~ err:", err);
                  alert(err.message);
                }
                setLoading(false);
              }}
            >
              Update the word
            </LoadingButton>
            <LoadingButton
              loading={loading}
              variant="contained"
              color="info"
              onClick={async () => {
                await loadNext();
              }}
            >
              Load next
            </LoadingButton>
            {imageName && !wordsWithoutImages.includes(imageName) && (
              <VStack pt={5}>
                <FoodItemImage size="big" image={imageName} folderName="read-to-learn-images" />
              </VStack>
            )}
          </HStack>
          {/* ); */}

          {/* })} */}
        </Box>
      </Grid>
    </Container>
  );
};

export default memo(AdminVerifyWords);
