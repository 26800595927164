import { useParams } from "react-router-dom";
import { Word } from "../../../types/supabase";
import { useEffect, useMemo, useState } from "react";
import { Box, Button, Drawer, Typography } from "@mui/material";
import ModalCloseButton from "../../Unknown/ModalCloseButton";
import LinearProgress from "../../User/LinearProgress";
import WordLetterPazleItem from "../../Word/WordLetterPazleItem";
import VStack from "../../Unknown/VStack";
import HoverDetails from "../../Word/HoverDetails";
import parsed from "../../../common/parsed";
import { format } from "date-fns";
import { useLessonsStore } from "../../../store/lessonsStore";
import { drawerContentStyle, userHomeWorkCurrentDay } from "../../../common/constants";
import useIsHomeWork from "../../../hooks/useIsHomeWork";
import Stories from "../../Grammar/Stories";
import getSentenceWords from "../../../common/getSentenceWords";
import HStack from "../../Unknown/HStack";
import WordAudio from "../../Audio/WordAudio";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import useGlobalWordsMap from "../../hooks/useGlobalWordsMap";
import isAdmin from "../../../common/isAdmin";
import RepetitionButtons from "../../Sentence/RepetitionButtons";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import ControllingStudentPage from "../../Unknown/Root/ControllingStudentPage";
import { usePrevious } from "react-use";

const _5and4Sentences = [
  "I have a cat.",
  "I see a tree. It is a big tree.",
  "This tree is big.",
  "This is a big tree.",
  "This root is big.",
  "I have seven apples.",
  "I see a dog.",
  "A dog ran to a cat.",
  "I have apples and pineapples.",
  "This is a big room.",
  "This room is big.",
  "I have a pan and a pen.",
  "I see a man and a dog.",
  "I have a room. It is a big room.",
  "I have five pens and nine apples.",
  "I have an apple and a pineapple.",
  "I have a fan. It is a big fan.",
  "This is a fan.",
  "This fan is big.",
  "A cat ran to a bag.",
  "I need an apple.",
  "I have a pen in a bag.",
  "This is a bag. It is pink.",
  "This bag is pink.",
  "This is a pink bag.",
  "I have five pans.",
  "Seven apples in a bag.",
  "This apple is red.",
  "This is a red apple.",
  "I have a doll and a hat.",
  "A belt in a bag.",
  "I need a banana.",
  "Cats sit on a bed.",
  "I have a big green lime.",
  "This lime is big and green.",
  "This is a big and green lime.",
  "I have a goose.",
  "I have seven geese.",
  "I have a plan.",
  "I have a banana.",
  "I have a dog and a cat.",
  "I have a big bedroom.",
  "This bedroom is big.",
  "This is a big room.",
  "I need a lime.",
  "This kite is green.",
  "I need it.",
  "This is a green kite.",
  "I have a kite. It is green and big.",
  "This bedroom is pink.",
  "I have a bedroom. It is big and pink.",
  "I have a pink and green pen.",
  "I have nine bananas and five apples.",
  "I need a red and pink pen.",
];
const _6Sentences = [
  "I have a pet. It is a rat.",
  "This is a rat. The rat is little.",
  "I have a pet. It is a cat. It is fat.",
  "This is a cat. The cat is little.",
  "This is a rat. This is a mat. The rat sat on the mat.",
  "Look, a rat ran. The rat is little.",
  "I have a green mat. The mat is big.",
  "Look, a cat ran. The cat sat on the mat.",
  "Look, the rat sat on the hat.",
  "Look, the cat sat on the hat.",
  "This coffee is too hot.",
  "This is green tea. The tea is too hot.",
  "I like this coffee.",
  "I like green tea.",
  "I like this tea.",
  "I like to eat apples.",
  "I like to eat bananas.",
  "I like green tea too.",
  "I like bananas too.",
  "I like apples too.",
  "I see a tree. The tree is big.",
  "This is a dog. This is a cat. The dog ran to the cat.",
];
const _7Sentences = [
  "John has a jeep.",
  "John and Jan have a van and a jeep.",
  "This is a big jeep.",
  "Jan likes to eat jam.",
  "I like to eat jam.",
  "John likes to eat apple jam.",
  "A trash bin.",
  "A trash can.",
  "I have a pet. It is a sheep.",
  "He has a pet. It is a cat.",
  "She has a big cat.",
  "He has a fat cat.",
  "The tree has big roots.",
  "She has a pet. It is a rat.",
  "I like to go to the shop.",
  "He has a little cat.",
  "She has a big dog.",
  "He has a big green mat.",
  "We have a goose.",
  "She likes to go to the park.",
  "This man has a big dog.",
  "Dan has a fish.",
  "He likes to eat bananas.",
  "The tree has nine big roots.",
  "She likes to eat apples.",
  "We have nine geese.",
  "We like to go to the park.",
  "She has a cat. It likes to climb a tree.",
  "Cats like to climb a tree.",
  "Dan has a pet. It is a rat.",
  "We need to do it.",
  "She is in the park.",
  "She has a pet. It is a fish.",
  "Look. A cat climbs a tree.",
  "He needs a red pen.",
  "She is in the park.",
  "He likes green tea.",
  "I am in the park.",
  "She is in the shop.",
  "She needs to do it.",
  "Kate has a pet. It is a hamster.",
  "This is an apple tree.",
  "I am in the shop.",
  "She likes coffee.",
  "The man has a fan.",
  "He is in the shop.",
  "She and he like apples.",
  "She has a big bag.",
  "He is in the park.",
  "I have a little hamster. It likes to eat apples.",
  "She has a lamb.",
  "We are in the shop.",
  "This man has a kite.",
  "He needs a green pen and a red pen.",
  "He has five lambs.",
  "I like to be in the park.",
  "She likes hot tea.",
  "He needs to do it.",
  "He likes to be in the park.",
  "We are in the park.",
  "She has a pink bag.",
  "He likes hot coffee.",
  "He likes to eat apples and bananas.",
  "She has nine pens.",
];
const _8Sentences = [
  "I have a box.",
  "I don't have a box. I have no box.",
  "I have five boxes.",
  "I have a box of apples.",
  "This is a box of pineapples.",
  "This is a box of limes.",
  "I have a box in the van.",
  "I have a box in the jeep.",
  "This is a big box.",
  "This is a tea box.",
  "This is a coffee box.",
  "This is a box of tea.",
  "This is a box of coffee.",
  "I have six apples.",
  "I want to see a fox.",
  "Look. A fox ran.",
  "Look. A fox in a tree.",
  "Look. A fox climbs a tree.",
  "A fox is an animal.",
  "She has six foxes.",
  "I need to mix it.",
  "I need a mixer to mix it.",
  "I can read this text.",
  "I need to read this text.",
  "I need a taxi.",
  "This box is big.",
  "This text is too big.",
  "This box is big.",
  "No problem.",
  "I don't have time. I have no time.",
  "I do not have a pen. I have no pen.",
  "I do not have an apple. I have no apple.",
  "We do not have time. We have no time.",
  "They do not have a van. They have no van.",
  "We do not have a jeep. We have no jeep.",
  "Cats and dogs are animals. Sheep and geese are animals too.",
  "I like to run in the park.",
  "I want a cup of tea.",
  "I understand you.",
  "I want a cup of coffee.",
  "She understands me.",
  "He understands me.",
  "This cup of coffee is too hot.",
  "We understand you.",
  "You understand me.",
  "This cup of coffee is too big.",
  "She likes to walk in the park.",
  "He needs to wash the dishes.",
  "I have water in the bag.",
  "I want an apple.",
  "This cup of tea is too hot.",
  "I want a glass of water.",
  "She wants to see a swan.",
  "This glass of water is too cold.",
  "I have a cold.",
];
const _9Sentences = [
  "I eat apples every day.",
  "I play tennis every day.",
  "I sleep every day.",
  "I go to the store every day.",
  "We go to the zoo today.",
  "I need to do it today.",
  "We go to the park today.",
  "They have apples and pineapples.",
  "I want to see a hare in the zoo.",
  "I play with a dog every day.",
  "This boy has a dog.",
  "The boy plays with a dog.",
  "I like to play with a dog.",
];
const _10Sentences = [
  "I can run fast.",
  "He can run fast.",
  "A hare can run fast.",
  "A hare is a fast animal.",
  "This dog is slow.",
  "A hamster is a slow animal.",
  "He runs every day. He is very fast.",
  "A cat can jump high.",
  "The sun is low.",
  "The sun is high.",
  "I can jump high.",
  "The plane is high.",
  "A cat can climb high.",
  "A cat can climb a high tree.",
  "The kite is high.",
  "The tree is high.",
  "The star is high.",
  "She runs in the park every day.",
  "I have a little bell. I can ring it fast and slow.",
  "She has a little bell. She can ring it high and low.",
  "Sit over here.",
  "Look over there.",
  "Stars are everywhere.",
  "This bell can ring.",
  "I run in the park every day.",
];
const _11Sentences = [
  "I want to go to the park today.",
  "He went to the park.",
  "Mary went to school.",
  "He was there. She was there too.",
  "This tea is too hot.",
  "This child has a cat.",
  "She is there.",
  "Children play in the yard.",
  "She is at home.",
  "I am sure that she is there.",
  "I am sure that he is at home.",
  "Mary had a little lamb. Its fleece was white as snow.",
  "You can't do that. It against the rule.",
  "Yes, I am at school today.",
  "My dog likes to follow me to the school.",
  "You made me laugh.",
];
const _12Sentences = [
  "Baby! Where is your mommy?",
  "Baby! Where is your daddy?",
  "Where is your pen?",
  "How are you?",
  "How many fingers do you have?",
  "I have ten fingers",
  "How many pens do you have?",
  "I have five pens.",
  "I have a red pen.",
  "Do you have a sister?",
  "Do you go with me?",
  "I want to go with you to the park.",
  "Do you have a red pen?",
  "Do you have a brother?",
  "Do you have a cat?",
  "Where is your sister?",
  "Where is your brother?",
  "Do you have a bell?",
  "Where is a baby?",
  "How many books do you have?",
  "Do you have a notebook?",
  "Do you like to eat soup?",
  "Do you like apples?",
  "Do you see a cat?",
  "Do you see a boy?",
];
const _13Sentences = [
  "I have a pet. It is a fish.",
  "Do you have a pet?",
  "She is a vet.",
  "Does she have a pet?",
  "Does he have a cat?",
  "A fish needs water.",
  "He went to the vet.",
  "I see a fish in the water.",
  "Her dog is wet.",
  "I need to get my pet.",
  "Don't worry, let it go.",
  "Don't worry, be happy.",
  "She let the fish go.",
  "I don't have a pet.",
  "I don't have a dog.",
  "She doesn't have a cat.",
  "Do you see a cat? - No, I don't see.",
  "Do you have a pet? - Yes, I have a dog.",
  "I want to fly on a jet plane.",
];
const _14Sentences = [
  "What do you want?",
  "Do you want hot cross buns?",
  "Do you eat hot buns?",
  "Do you like hot buns?",
  "Does your sister like buns?",
  "I like to eat hot cross buns.",
  "How many buns do you have?",
  "Do you like to cook?",
  "Do you have apples? How many apples do you have?",
  "What do you like to cook?",
  "Don't cross here.",
  "Do you have bananas? How many bananas do you have?",
  "Don't cross the street.",
  "Do you like to bake hot cross buns?",
  "Does your sister like to bake?",
  "Does your brother like to eat hot cross buns?",
  "I like to cook soup.",
  "I like to eat soup with buns.",
  "I have hot cross buns. Let's give them to the people",
  "Give me two cross buns, please.",
  "Can you give me a pen?",
  "Can you do it?",
  "Can she do it?",
  "She has cross buns.",
];
const _15Sentences = [
  "This is our cat.",
  "This is our big dog.",
  "Let's make a circle.",
  "Sit down, please.",
  "Stand up, please.",
  "This circle is round.",
  "Make a circle with your arms.",
  "This circle is small and that circle is big.",
  "This circle is too small.",
  "This cup is too small. Please give me a big one.",
  "This circle is small. We need a big one.",
  "This bun is round.",
  "How many arms do you have? I have two arms.",
  "How many fingers do you have? I have ten fingers.",
  "The children jump in the circle.",
  "The children made a circle.",
  "This stone is round.",
  "Mary has a round hat.",
  "Look up. Do you see a cat?",
  "Look down. What do you see?",
];
const _16Sentences = [
  "Come here!",
  "Come again another day.",
  "Go away, I don't want to see you.",
  "I can't go to the park today. Let's go another day.",
  "I love all my family.",
  "Go away from there.",
  "Come home.",
  "I need to go home.",
  "Go away from here!",
  "Come tomorrow.",
  "Go away from the room!",
  "I love all people.",
  "Where are you from?",
  "I am from Ukraine.",
  "She wants the rain to go away.",
  "All my family is here.",
  "All the children are here.",
  "All the children play there.",
  "I go to school tomorrow.",
  "I don't go to school tomorrow.",
];
const _17Sentences = [
  "Where is the mop?",
  "I need a mop.",
  "Can you mop here?",
  "The mop is wet.",
  "The mop is blue.",
  "The rabbit can hop fast.",
  "Make a small hop.",
  "The mop is brown.",
  "What color is the mop?",
  "What color is your dog?",
  "My dog is brown.",
  "Stop. Don't do that!",
  "I have a big brown bag.",
  "I like to cook soup in a big pot.",
  "I like to eat popcorn.",
  "Do you like to eat corn?",
  "She likes to read.",
  "He reads a lot of books. / He reads many books.",
  "He works a lot.",
  "He has a lot of books. / He has many books.",
];

const _18Sentences = [
  "Can you spin the ball?",
  "Sit down. Stand up. Spin around.",
  "Can you do it fast? Can you do it faster?",
  "Let's spin around.",
  "Look around.",
  "Let's try it.",
  "Let's try to do it faster.",
  "Can you read fast?",
  "Can you read this text faster?",
  "I am really happy. I am very happy.",
  "This dog is really fast. This dog is very fast.",
  "I really like it. I like it very much.",
  "This dog is really big. This dog is very big.",
  "Thank you very much. You are welcome.",
  "How much water do we need?",
  "How much time do we have?",
  "Let's try again.",
  "Bless you.",
  "Do you really want to try it? Yes, I really want it!",
  "Try to go faster. Go faster!",
  "Let's try again tomorrow.",
  "How much is this book?",
  "I don't have much time today.",
  "I don’t have much time to play.",
  "This it too much.",
  "Let's do it now.",
  "Please do it now.",
  "Can you do it now?",
];
const _19Sentences = [
  "Do you like to jog?",
  "I jog with my dog every day in the morning.",
  "I jog with my dog every morning.",
  "I like it when it is foggy.",
  "Do you have a van?",
  "The dog can't jump over the log. The log is too big.",
  "This is a big dog. It can jump over the big log.",
  "Look. The dog jumps over the log.",
  "You can do a lot.",
  "What can you do?",
  "I go to school by van.",
  "We go to the park by car.",
  "I like to jog in the fog.",
];
const _20Sentences = [
  "Look to the right. Look to the left.",
  "Stand on your left foot.",
  "Stand on your right foot.",
  "This is my left hand.",
  "This is my right hand.",
  "It is in your left hand.",
  "It is in your right hand.",
  "Let's walk to the left.",
  "Let's walk to the right.",
  "Where is my pen? Your pen is on the right.",
  "Where is my cup? Your cup is on the left.",
  "Where are my pens? They are in the box.",
  "Yes, it is right here.",
  "That's right.",
  "All right.",
  "Right now.",
  "Am I right?",
  "You're right.",
  "I am all right.",
  "Go left.",
  "Go right.",
  "Good night.",
  "Good morning.",
  "It is right there.",
  "Can you stomp to the right? Can you stomp to the left?",
  "Can you hop to the right? Can you hop to the left?",
  "Clap your hands.",
  "Clap to the left. Clap to the right.",
  "A baby likes to wiggle its hands.",
];
const _21Sentences = [
  "Where are you? - Here I am.",
  "Where is he? - He is in the library.",
  "Where is she? - She is in the park.",
  "Where is he? - He is in the hospital.",
  "Where are they? - They are in the park.",
  "Are you okay? - Yes, I'm okay.",
  "Are you all right? - Yes, I'm all right.",
  "Where is it? - It is on the left.",
  "Where is it? - It is on the right.",
  "Where is the library? - The library is over there.",
  "Where is the hospital? - It is over there.",
  "Where is the dog? - It is over there.",
  "My dog likes to jump over the log.",
  "I like to walk at night.",
  "I like to do it in the morning.",
  "We are here.",
];
const _22Sentences = [
  "I have a red jacket.",
  "Hurry up. Put on your jacket.",
  "Let’s go outside. Put on your shoes.",
  "This is my jacket.",
  "I like your blue jacket.",
  "Where is my jacket?",
  "Where are my shoes?",
  "Where is my scarf?",
  "This is her scarf.",
  "This is my scarf.",
  "This is your scarf.",
  "Please hurry up.",
  "Hurry up to school.",
  "Don't hurry up too much.",
  "Can you hurry up?",
  "Hurry up, it's cold.",
  "It's cold outside.",
  "Put on your hat.",
  "It's on the left side of the street.",
  "Put is on the left side.",
  "Look at the left side.",
  "Stand on the right side.",
  "Put is on the right side.",
  "It's on the right side of the street.",
  "Look at the right side.",
  "Put on your mask.",
  "Stand on the left side.",
];
// This tree has big roots.
const config: Record<string, { text: string[] }> = {
  "5.4": {
    text: [..._5and4Sentences],
  },
  "6": {
    text: [..._6Sentences, ..._5and4Sentences],
  },
  "7": {
    text: [..._7Sentences],
  },
  "8": {
    text: [..._8Sentences],
  },
  "9": {
    text: [..._9Sentences],
  },
  "10": {
    text: [..._10Sentences],
  },
  "11": {
    text: [..._11Sentences],
  },
  "12": {
    text: [..._12Sentences],
  },
  "13": {
    text: [..._13Sentences],
  },
  "14": {
    text: [..._14Sentences],
  },
  "15": {
    text: [..._15Sentences],
  },
  "16": {
    text: [..._16Sentences],
  },
  "17": {
    text: [..._17Sentences],
  },
  "18": {
    text: [..._18Sentences],
  },
  "19": {
    text: [..._19Sentences],
  },
  "20": {
    text: [..._20Sentences],
  },
  "21": {
    text: [..._21Sentences],
  },
  "22": {
    text: [..._22Sentences],
  },
};

const Reading: React.FC<{}> = ({}) => {
  const { lessonId } = useParams();
  const [currentIndex, setCurrentIndex] = useState(0);
  const isTeacherLesson = useLessonsStore((state) => state.isTeacherLesson);
  const authUser = useLessonsStore((state) => state.authUser);
  const adminControlReadingSentenceIndex = useLessonsStore(
    (state) => state.adminControlReadingSentenceIndex,
  );

  const words = useMemo(() => {
    if (!lessonId || !config[lessonId]) return [];
    return getSentenceWords(config[lessonId].text.join(" "));
  }, [lessonId]);
  const [sentences, setSentences] = useState<string[]>([]);

  useEffect(() => {
    if (lessonId && config[lessonId]?.text) {
      setSentences(config[lessonId].text.sort((a, b) => a.localeCompare(b)));
    }
  }, [lessonId]);

  const currentSentence = useMemo(() => {
    return sentences[currentIndex];
  }, [sentences, currentIndex]);

  const curerntModuleWordsMap = useGlobalWordsMap({ words });

  const [isOpen, setIsOpen] = useState(false);

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  const updateSentences = async (period: number, isStudent?: boolean) => {
    if (period + 1 < sentences.length) {
      const newSentences = sentences.slice(1).reduce<string[]>((prev, sentence, index) => {
        const updated = [...prev];
        if (index + 1 === period) updated.push(currentSentence);
        updated.push(sentence);
        return updated;
      }, []);
      setSentences(newSentences);
    } else {
      setSentences([...sentences.slice(1), sentences[0]]);
    }

    if (!isStudent) {
      const randomNumber = Math.floor(Math.random() * Math.random() * 10000000) / 10000000;
      const [, randomNumberDecimal] = randomNumber.toString().split(".");

      await supabaseClient
        .from("admin_control")
        .update({
          // user: studentUserUuid,
          new_reading_sentence_index: `${period}.${randomNumberDecimal}`,
        })
        .eq("id", 0);
    }
  };

  const prevAdminControlReadingSentenceIndex = usePrevious(adminControlReadingSentenceIndex);
  useMountedWaitAsyncEffect(
    () => {
      if (
        authUser &&
        adminControlReadingSentenceIndex &&
        prevAdminControlReadingSentenceIndex !== adminControlReadingSentenceIndex &&
        !isAdmin(authUser?.id)
      ) {
        const period = Math.floor(adminControlReadingSentenceIndex);
        if (period) updateSentences(period, true);
      }
    },
    [adminControlReadingSentenceIndex, prevAdminControlReadingSentenceIndex, authUser],
    0,
  );

  if (!lessonId || !config[lessonId]) return null;

  return (
    <>
      <Button variant="outlined" onClick={() => setIsOpen(true)}>
        Читати
      </Button>
      <Drawer
        open={isOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 999,
          // opacity: 0,
          // backgroundColor: "rgba(0, 0, 0, 1) !important",
        }}
      >
        <Box sx={drawerContentStyle}>
          <LinearProgress elementsCount={sentences.length - 1} currentValue={currentIndex} />
          <ModalCloseButton onClose={handleDrawerClose} />
          <VStack>
            <Box maxWidth={500} margin="1 auto" px={4} pt={15} pb={5}>
              <HStack sx={{ flexWrap: "nowrap", width: "100%" }}>
                <HoverDetails
                  // isCenter
                  fontSize={25}
                  words={
                    currentSentence?.split(" ")?.map((w: any, index: number) => {
                      const cleanWord = removePunctuationFromString(w).replace("__", " ");

                      const word =
                        curerntModuleWordsMap[cleanWord] ||
                        curerntModuleWordsMap[cleanWord.toLowerCase()];
                      // console.log("🚀 ~ cleanWord:", w, cleanWord, word);

                      return {
                        ...(word ? word : {}),
                        word: word?.name || cleanWord,
                        label: w.replace("__", " "),
                      };
                    }) as any
                  }
                />
              </HStack>
            </Box>
            <Box py={3}>
              {isAdmin(authUser?.id) && (
                <VStack py={5}>
                  <RepetitionButtons
                    isVisible
                    onlyCurrentDay
                    wordsCount={sentences.length}
                    goToNextSlide={async (period) => {
                      updateSentences(+period);
                    }}
                  />
                </VStack>
              )}
              {!isTeacherLesson && !isAdmin(authUser?.id) && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (currentIndex + 1 >= sentences.length) {
                      setCurrentIndex(0);
                    } else {
                      setCurrentIndex((prev) => prev + 1);
                    }
                  }}
                >
                  далі
                </Button>
              )}
            </Box>
            {(isTeacherLesson || isAdmin(authUser?.id)) && (
              <Box>
                <ControllingStudentPage isNested />
              </Box>
            )}
          </VStack>
        </Box>
      </Drawer>
    </>
  );
};

export default Reading;
