import {
  PaletteColorOptions,
  PaletteOptions,
  StyledEngineProvider,
  ThemeProvider,
  alpha,
  createTheme,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import {
  AuthProvider,
  DatabaseProvider,
  FirestoreProvider,
  StorageProvider,
  useFirebaseApp,
} from "reactfire";
import IntlProvider from "../IntlProvider";
import Root from "../Root";
import { UIContextProvider } from "../UIContext";

const defaultTheme = createTheme({
  spacing: 5,
});

const colors = {
  grayLighter: "#f6f6f8", //"#fafafa",
  grayLight: "#919096",
  blue: "#536DFE",
  black: "#060325",
  white: "#fff",
  dark: "#010231",
  orangeDark: "#a77301",
  purpleLight: "#75718F",
  // bgPrimary: "#f5f5f5",
  // bgPrimary: "#1cb0f6",
  primaryMain: "#4aa805",
  primaryDark: "#2272cd",
  shadow: "#130833",
  greenLight: "#58cc05",
};

const shadows = {
  cardTypeA: `0px 2px 9px 0px ${alpha(colors.shadow, 0.03)}`,
  menu: `0px 2px 9px 0px ${alpha(colors.shadow, 0.25)}`,
  filter: `0px 2px 9px 0px ${alpha(colors.shadow, 0.07)}`,
};

const theme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: "Rubik",
      },
    },
    MuiChip: {
      styleOverrides: {
        colorSecondary: {
          backgroundColor: colors.black,
        },
        outlined: {
          borderColor: colors.grayLight,
          color: colors.grayLight,
        },
        sizeSmall: {
          minHeight: 32,
        },
        labelSmall: {
          fontSize: 16,
          fontWeight: 600,
        },
        icon: {
          fontSize: 16,
          minHeight: 26,
          fontWeight: 600,
          borderRadius: 20,
          color: colors.white,
          backgroundColor: colors.grayLight,
          paddingLeft: defaultTheme.spacing(1.5),
          paddingRight: defaultTheme.spacing(1.5),
        },
        iconColorPrimary: {
          color: colors.blue,
          backgroundColor: colors.white,
        },
        iconColorSecondary: {
          color: colors.grayLight,
          backgroundColor: colors.white,
        },
      },
    },
    MuiGrid: {
      defaultProps: {
        spacing: 2,
      },
    },
  },
  spacing: 5,
  palette: {
    primary: {
      main: colors.primaryMain,
      // main: colors.blue,
      dark: colors.primaryDark,
    },
    secondary: {
      main: colors.greenLight,
      dark: colors.dark,
      600: colors.orangeDark,
    },
    text: {
      // main: colors.black,
      secondary: colors.grayLight,
      // dark: colors.dark,
      // purpleLight: colors.purpleLight,
    },
    background: {
      default: colors.grayLighter,
      // primary: colors.bgPrimary,
      paper: colors.white,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1690,
    },
  },
  typography: {
    h2: {
      fontSize: 34,
      fontWeight: 600,
    },
    h3: {
      fontSize: 20,
      fontWeight: 700,
    },
    h4: {
      fontSize: 16,
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 600,
    },
    button: {
      fontSize: 16,
      fontWeight: 600,
      textTransform: "none",
    },
    caption: {
      color: colors.grayLight,
    },
  },
  shadows: [
    "none",
    shadows.cardTypeA,
    shadows.menu,
    shadows.filter,
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
  ],
});

const App: React.FC = () => {
  const firestoreInstance = getFirestore(useFirebaseApp());
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const storage = getStorage(app);
  const database = getDatabase(app);

  // return <DrawingBoard />;

  return (
    <ThemeProvider theme={theme}>
      <StorageProvider sdk={storage}>
        <DatabaseProvider sdk={database}>
          <StyledEngineProvider injectFirst>
            <IntlProvider>
              <BrowserRouter>
                <CssBaseline />
                <UIContextProvider>
                  {/* <FirestoreProvider sdk={firestoreInstance}> */}
                  <AuthProvider sdk={auth}>
                    <Root />
                  </AuthProvider>
                  {/* </FirestoreProvider> */}
                </UIContextProvider>
              </BrowserRouter>
            </IntlProvider>
          </StyledEngineProvider>
        </DatabaseProvider>
      </StorageProvider>
    </ThemeProvider>
  );
};

export default App;
