import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Link as MuiLink, Tooltip, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import checkNewWords from "../../../common/checkNewWords";
import { skipWords } from "../../../common/constants";
import isAdmin from "../../../common/isAdmin";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../store/lessonsStore";
import { EnglishStory } from "../../../types/supabase";
import CopyNewWordsForGemini from "../../Admin/CopyNewWordsForGemini";
import { Story } from "../../AdminCheckUserStories";
import WordsCarousel from "../../Grammar/GrammarLesson/tabs/WordsCarousel";
import Stories from "../../Grammar/Stories";
import MenuLayout from "../../Layout/MenuLayout";
import ItalicTitle from "../../Title/ItalicTitle";
import HStack from "../../Unknown/HStack";
import PdfModal from "../../Unknown/PdfModal";
import CheckSentence from "../../Unknown/Root/CheckSentences";
import ControllingStudentPage, {
  createUserLesson,
  getUserLesson,
  updateUserLesson,
} from "../../Unknown/Root/ControllingStudentPage";
import VStack from "../../Unknown/VStack";
import CourseProgram, { Rule, lessons } from "./CourseProgram";
import AdminLearnToRead from "./AdminLearnToRead";
import LearnToReadCarousel from "./LearnToReadCarousel";
import StudentReadCarousel from "./LearnToReadCarousel/StudentReadCarousel";
import AddWordsToCurrentUserLesson from "./AddWordsToCurrentUserLesson";

// -- valmiki 19c7ac10-110d-43e3-ad86-5e425aef49a5
// -- dayanvita 86523831-4881-4b89-91b5-def185deba6e
// -- grammar-user 7dd6fd1b-a333-4fbf-98a7-245491416070
// -- readding-user 53ef5828-71ce-4fdb-bfa1-80d8c4ebd57b
// -- ira 592d3941-7376-4c08-83c7-a374b2da849c

const LoadVideos: React.FC = () => {
  console.log("LoadVideos");
  const studentUser = useLessonsStore((state) => state.studentUser);
  const videosOrderIds = useLessonsStore((state) => state.videosOrderIds);
  const setVideosOrderIds = useLessonsStore((state) => state.setVideosOrderIds);
  const studentWords = useLessonsStore((state) => state.studentWords);
  const setStudentWords = useLessonsStore((state) => state.setStudentWords);
  const stories = useLessonsStore((state) => state.stories);
  const setStories = useLessonsStore((state) => state.setStories);
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [loading10Videos, setLoading10Videos] = useState(false);
  const [loadingUserWords, setLoadingUserWords] = useState(false);

  const handleLoadVideos = async () => {
    if (!studentUser) {
      alert("No student found");
      return;
    }

    setLoadingVideos(true);

    const { error, data } = await supabaseClient.rpc("get_english_videos_order", {
      input_user: studentUser.uuid,
    });

    if (error) alert((error as any)?.message);

    if (data) {
      const { data: spUser } = await supabaseClient
        .from("users")
        .select("ignore_video_ids, prev_video_ids")
        .eq("id", studentUser.id)
        .single();

      const ignoreIds = [...(spUser?.ignore_video_ids || []), ...(spUser?.prev_video_ids || [])];

      setVideosOrderIds(data.filter((id: number) => !ignoreIds.includes(id)));
    }
    setLoadingVideos(false);
  };

  const handleLoadNextVideo = async () => {
    setLoading10Videos(true);
    const videoIdsToFind = videosOrderIds.slice(0, 3);

    const { data } = await supabaseClient.from("english_verses").select().in("id", videoIdsToFind);

    if (data) {
      setStories(
        data
          .map((v) => {
            const newWords = v.words.filter((w: string) => !studentWords.includes(w));

            return {
              ...v,
              new_words: newWords,
              new_words_count: newWords.length,
              word_count: v.words.length,
              story_id: v.id,
              id: v.verse,
            };
          })
          .sort((a, b) => a.words.length - b.words.length)
          .sort((a, b) => a.new_words_count - b.new_words_count),
      );
    }
    setLoading10Videos(false);
    setVideosOrderIds(videosOrderIds.slice(3));
  };

  const authUser = useLessonsStore((state) => state.authUser);

  useEffect(() => {
    let channel: any = null;
    if (isAdmin(authUser?.id)) {
      const channel = supabaseClient
        .channel("room11")
        .on(
          "postgres_changes",
          { event: "*", schema: "public", table: "english_verses" },
          async (payload: any) => {
            console.log("🚀 ~ payload:", payload);
            const newVideos = stories.map((v: any) => {
              console.log("v.story_id === payload.new?.id", v.story_id === payload.new?.id);
              if (v.story_id === payload.new?.id) {
                const newWords = v.words.filter((w: string) => !studentWords.includes(w));

                return {
                  ...payload.new,
                  new_words: newWords,
                  new_words_count: newWords.length,
                  word_count: payload.new.words.length,
                  story_id: payload.new.id,
                  id: payload.new.verse,
                };
              }
              return v;
            });
            setStories(newVideos);
          },
        )
        .subscribe();
    }
    return () => {
      if (channel) channel.unsubscribe();
    };
  }, [authUser, stories, studentWords]);

  const handleLoadUserWords = async () => {
    if (!studentUser) {
      alert("student not found");
      return;
    }

    setLoadingUserWords(true);

    const { data: userWords } = await supabaseClient
      .from("user-words")
      .select()
      .eq("user", studentUser.uuid);

    if (userWords) setStudentWords(userWords.map((w) => w.word));
    setLoadingUserWords(false);
  };

  return (
    <Box>
      <VStack>
        <LoadingButton
          loading={loadingVideos}
          color="error"
          variant="contained"
          onClick={handleLoadVideos}
        >
          Load videos
        </LoadingButton>
        {Boolean(videosOrderIds.length) && (
          <>
            <LoadingButton
              loading={loading10Videos}
              color="error"
              variant="contained"
              onClick={handleLoadNextVideo}
            >
              Load next video
            </LoadingButton>
          </>
        )}
        {studentWords.length === 0 && (
          <>
            <LoadingButton
              loading={loadingUserWords}
              color="error"
              variant="contained"
              onClick={handleLoadUserWords}
            >
              Load user words
            </LoadingButton>
          </>
        )}

        {stories.map((v) => (
          <Story
            key={v.id}
            story={v}
            lazyLoading
            endless
            onUpdateStories={() => {
              // setStories(stories.filter((q: any) => q.id != story.id));
            }}
          />
        ))}
      </VStack>
    </Box>
  );
};

const AddToUser: React.FC<{ storyId: number; type: "prev" | "ignore" }> = ({ storyId, type }) => {
  const [isLoading, setIsLoading] = useState(false);
  const studentUser = useLessonsStore((state) => state.studentUser);
  const newUserLesson = useLessonsStore((state) => state.newUserLesson);
  const setNewUserLesson = useLessonsStore((state) => state.setNewUserLesson);
  // const [done, setDone] = useState(false)

  // if (done) return null;
  if (newUserLesson?.video_ids?.includes(Number(storyId))) return null;

  return (
    <LoadingButton
      loading={isLoading}
      variant="contained"
      color={type === "prev" ? "primary" : "error"}
      onClick={async () => {
        if (!studentUser) {
          alert("no user found");
          return;
        }

        console.log("type", type);
        setIsLoading(true);
        if (type === "prev") {
          const { data: studentDbUser } = await supabaseClient
            .from("users")
            .select()
            .eq("id", studentUser.id)
            .single();
          console.log("storyId", storyId);
          if (!studentDbUser) return;
          const prevVideosIds = [...(studentDbUser.prev_video_ids || []), storyId];

          const resultUpdateUserWihPrevVideoIds = await supabaseClient
            .from("users")
            .update({
              prev_video_ids: prevVideosIds,
            })
            .eq("id", studentUser.id);

          console.log("resultUpdateUserWihPrevVideoIds", resultUpdateUserWihPrevVideoIds);

          // today user lesson
          const userLesson = await getUserLesson(studentUser.id, "english_user_lessons");
          console.log("🚀 ~ userLesson:", userLesson);
          if (userLesson) {
            await updateUserLesson(
              {
                user: studentUser.id,
                video_ids: [...(userLesson.video_ids || []), storyId].map((id) => Number(id)),
                words: [...(userLesson?.words || [])],
              },
              "english_user_lessons",
            );
          } else {
            await createUserLesson(
              { user: studentUser.id, video_ids: [storyId] },
              "english_user_lessons",
            );
          }

          let updatedOrCreatedUserLesson = await getUserLesson(studentUser.id);
          setNewUserLesson(updatedOrCreatedUserLesson);
        } else if (type === "ignore") {
          const { data: studentDbUser } = await supabaseClient
            .from("users")
            .select()
            .eq("id", studentUser.id)
            .single();
          console.log("🚀 ~ studentDbUser:", studentDbUser);
          if (!studentDbUser) return;
          const ignoreVideoIds = [...(studentDbUser.ignore_video_ids || []), storyId].map((id) =>
            Number(id),
          );
          console.log("🚀 ~ prevVideosIds:", ignoreVideoIds);
          const updateResult = await supabaseClient
            .from("users")
            .update({
              ignore_video_ids: ignoreVideoIds,
            })
            .eq("id", studentUser.id);

          console.log("updateResult", updateResult);
        }
        setIsLoading(false);
      }}
    >
      {type === "prev" && (
        <Tooltip title="Add to user video ids">
          <PersonAddIcon />
        </Tooltip>
      )}
      {type !== "prev" && (
        <Tooltip title="Add to ignore video ids">
          <PersonRemoveIcon />
        </Tooltip>
      )}
      {/* {type === "prev" ? "Add to user video ids" : "Add to ignore video ids"} */}
    </LoadingButton>
  );
};

const EnglishKidsLessonsScreen: React.FC = () => {
  const activeVideoId = useLessonsStore((state) => state.activeVideoId);
  const [story, setStory] = useState<EnglishStory>();
  const [pdfStory, setPdfStory] = useState<EnglishStory>();
  const authUser = useLessonsStore((state) => state.authUser);
  const setActiveVideoId = useLessonsStore((state) => state.setActiveVideoId);
  const [isLoading, setIsLoading] = useState(false);

  useMountedWaitAsyncEffect(async () => {
    const { data } = await supabaseClient.from("admin_control").select().eq("id", 0).single();
    if (data) setActiveVideoId(data.active_video_id);
  }, []);

  const isTeacherLesson = useLessonsStore((state) => state.isTeacherLesson);
  // const studentUser = useLessonsStore((state) => state.studentUser);

  useMountedWaitAsyncEffect(async () => {
    if (!activeVideoId || !isTeacherLesson) return;
    // console.log('0---', studentUser)

    const { data } = await supabaseClient
      .from("english_verses")
      .select()
      .in("id", [activeVideoId])
      .maybeSingle();

    if (data && data.type !== "pdf") setStory(data);
    if (data && data.type === "pdf") setPdfStory(data);
  }, [activeVideoId, isTeacherLesson]);

  const englishLessons = useLessonsStore((state) => state.englishKidsLessons);
  const setEnglishLessons = useLessonsStore((state) => state.setEnglishKidsLessons);

  useMountedWaitAsyncEffect(async () => {
    if (!authUser) return;

    const { data } = await supabaseClient
      .from("english_user_lessons")
      .select()
      .eq("user", authUser.id);

    if (data) setEnglishLessons(data);
  }, [authUser]);

  const studentUser = useLessonsStore((state) => state.studentUser);
  const setStudentUser = useLessonsStore((state) => state.setStudentUser);
  // english_lesson

  useMountedWaitAsyncEffect(async () => {
    if (studentUser && !studentUser.english_lesson) {
      await supabaseClient
        .from("users")
        .update({
          english_lesson: 1,
        })
        .eq("id", studentUser.id);

      const { data } = await supabaseClient
        .from("users")
        .select()
        .eq("id", studentUser.id)
        .single();

      setStudentUser(data?.user);
    }
  }, [studentUser]);

  const newUserLesson = useLessonsStore((state) => state.newUserLesson);
  const setNewUserLesson = useLessonsStore((state) => state.setNewUserLesson);

  useMountedWaitAsyncEffect(async () => {
    if (!studentUser || !isAdmin(authUser?.id)) return;

    let userLesson = await getUserLesson(studentUser.id, "english_user_lessons");
    // new means current, lessen that was created today
    setNewUserLesson(userLesson);
  }, [studentUser, authUser]);

  const [adminRules, setAdminRules] = useState<Rule[]>([]);

  useMountedWaitAsyncEffect(async () => {
    if (!studentUser || !studentUser.english_lesson || !isAdmin(authUser?.id)) return;

    const { data } = await supabaseClient
      .from("english_rules")
      .select()
      .lte("lesson", studentUser.english_lesson);

    if (data) setAdminRules(data);
  }, [studentUser]);

  const lessonWords: string[] = useMemo(() => {
    if (!studentUser?.english_lesson) return [];
    return lessons[studentUser.english_lesson.toString()]?.words || [];
  }, [studentUser]);

  const [newWords, setNewWords] = useState<string[]>([]);

  useMountedWaitAsyncEffect(async () => {
    if (!isAdmin(authUser?.id) || !lessonWords.length) return;
    {
      const { words: returnWords, newWords } = await checkNewWords(lessonWords);

      setNewWords(newWords.filter((w) => !skipWords.includes(w)));
    }
  }, [lessonWords, authUser]);

  if (!isAdmin(authUser?.id)) {
    return (
      <MenuLayout isFluid>
        <VStack p={5}>
          {/* {isTeacherLesson && (
            <Box>
              <Button
                variant="contained"
                onClick={() => {
                  window.open(
                    `https://excalidraw.com/#room=141e581c97856b4f791b,VEL8E0gLp0DerASDr9InaQ`,
                    "_blank",
                  );
                }}
              >
                Доска для малювання
              </Button>
            </Box>
          )} */}
          <ControllingStudentPage isNested />
          {/* <StudentReadCarousel /> */}
          {pdfStory && <PdfModal story={pdfStory} />}
          {story && (
            <VStack>
              <Stories
                stories={[story]}
                buttonLabel="відео"
                buttonSize="small"
                withoutDrawer
                withoutLink
                // isReadingVideo
              />
            </VStack>
          )}

          {Boolean(englishLessons.length) && (
            <Box>
              <ItalicTitle size={30} text="Уроки" />
              <br />
              {englishLessons.map((lesson) => {
                return (
                  <MuiLink
                    key={lesson.id}
                    color="secondary.dark"
                    sx={{
                      // textDecoration: "none",
                      width: "100%",
                      fontSize: 22,
                    }}
                    component={Link}
                    display="inline-block"
                    // to={`/grammar/grammar-lesson/${lesson.number}/build-words-list`}
                    to={`/english-lessons/${lesson.date}`}
                  >
                    Урок за <strong>{lesson.date}</strong>
                  </MuiLink>
                );
              })}
            </Box>
          )}
        </VStack>
      </MenuLayout>
    );
  }

  return (
    <MenuLayout isFluid>
      <Box>
        <Box display="flex" p={5}>
          <Box width="500px" pr={5} height="calc(100vh - 50px)" overflow="auto">
            <Box>
              <ControllingStudentPage isNested />
              {!!newWords.length && <CopyNewWordsForGemini words={newWords} />}
              <WordsCarousel words={lessonWords} isReadToLearn />
              <LearnToReadCarousel />
              <AddWordsToCurrentUserLesson words={lessonWords} />

              {/* {newUserLesson && (
                <Box mb={5}>
                  <ItalicTitle text="New user lesson" />
                  <br />
                  {Boolean(newUserLesson?.words?.length) && (
                    <ItalicTitle text={`Words count: ${newUserLesson.words.length}`} />
                  )}
                  {Boolean(newUserLesson?.words?.length) && (
                    <>
                      <br />
                      <ItalicTitle text={`Words:`} />
                      <Typography>{newUserLesson?.words?.join(", ")}</Typography>
                    </>
                  )}
                  <br />
                  {Boolean(newUserLesson.video_ids?.length) && (
                    <>
                      <ItalicTitle text={`Video ids:`} />
                      <Typography>{newUserLesson.video_ids?.join(", ")}</Typography>
                    </>
                  )}
                  {Boolean(newUserLesson.sentence_ids?.length) && (
                    <>
                      <ItalicTitle text={`Sentence ids:`} />
                      <Typography>{newUserLesson.sentence_ids?.join(", ")}</Typography>
                    </>
                  )}
                </Box>
              )} */}
              <Box py={3}>
                {Object.values(lessons)
                  .filter(
                    (l) =>
                      studentUser &&
                      studentUser.english_lesson &&
                      // l.lesson >= studentUser.english_lesson &&
                      l.lesson < studentUser.english_lesson + 5,
                  )
                  .map((l) => {
                    return (
                      <VStack alignItems="flex-start">
                        {l.storiesIds.map(({ id, title }) => {
                          return (
                            <HStack>
                              <LoadingButton
                                loading={isLoading}
                                variant="contained"
                                color="info"
                                onClick={async () => {
                                  if (!studentUser) {
                                    alert("no user found");
                                    return;
                                  }

                                  setIsLoading(true);

                                  await supabaseClient
                                    .from("admin_control")
                                    .update({ active_video_id: id })
                                    .eq("id", 0);

                                  setIsLoading(false);
                                }}
                              >
                                <Tooltip title="Make it active for user">
                                  <AirplanemodeActiveIcon />
                                </Tooltip>
                              </LoadingButton>
                              <AddToUser type="prev" storyId={id} />
                              <AddToUser type="ignore" storyId={id} />
                              <Typography>{title}</Typography>
                            </HStack>
                          );
                        })}
                      </VStack>
                    );
                  })}
              </Box>
              <CheckSentence />
            </Box>
          </Box>
          <Box width="600px" pr={5} height="calc(100vh - 50px)" overflow="auto">
            <CourseProgram rules={adminRules} />
          </Box>
          <Box
            width="0px"
            pr={5}
            height="calc(100vh - 50px)"
            sx={{
              // overflowY: "auto",
              overflowX: "visible",
            }}
          >
            <AdminLearnToRead />
          </Box>
          {/* <Box width="35%" height="calc(100vh - 50px)" overflow="auto" py={5}>
            <LoadVideos />
          </Box> */}
        </Box>
      </Box>
    </MenuLayout>
  );
};

export default EnglishKidsLessonsScreen;
