import LoadingButton from "@mui/lab/LoadingButton";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Button, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";
import { addSeconds } from "date-fns";
import HStack from "../Unknown/HStack";
import useCreateAudio from "../../common/useCreateAudio";
import useMountedWaitAsyncEffect from "../../hooks/useMountedWaitAsyncEffect";
import supabaseClient from "../../common/supabaseClient";
import Loader from "../Unknown/Loader";
import { Sentence } from "../../types/supabase";
import getSentenceWords from "../../common/getSentenceWords";
import checkNewWords from "../../common/checkNewWords";
import { skipWords } from "../../common/constants";
import CopyNewWordsForGemini from "../Admin/CopyNewWordsForGemini";

export type Question = {
  en: string;
  ua: string;
};
export type Answer = {
  en: string;
  ua: string;
};

const RenderPossibleAnswer: React.FC<{
  ua: string;
  en: string;
  onRemove: () => void;
  onUpdate: (en: string, ua: string) => void;
}> = ({ ua, en, onUpdate, onRemove }) => {
  const [localUa, setLocalUa] = useState("");
  const [localEn, setLocalEn] = useState("");

  useEffect(() => {
    setLocalUa(ua);
    setLocalEn(en);
  }, [ua, en]);

  useEffect(() => {
    const timer = setTimeout(() => {
      onUpdate(localEn, localUa);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [localUa, localEn]);

  return (
    <Box position="relative">
      <Box position="absolute" left={-50} top={0}>
        <IconButton
          onClick={() => {
            onRemove();
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      <TextField
        // value={en}
        // defaultValue={en}
        multiline
        size="small"
        value={localEn}
        InputProps={{
          sx: {
            fontWeight: 700,
          },
        }}
        fullWidth
        onChange={(event) => {
          // setAEn(event.target.value);
          setLocalEn(event.target.value);
          // onUpdate(event.target.value, ua);
        }}
      />
      <TextField
        // value={ua}
        // defaultValue={ua}
        multiline
        size="small"
        value={localUa}
        fullWidth
        onChange={(event) => {
          // setAUa(event.target.value);
          setLocalUa(event.target.value);
          // onUpdate(en, event.target.value);
        }}
      />
      {/* <Box>
        {possibleAnswers.map((pA) => {
          return (
            <Box pl={20}>
              <Typography>{pA.en}</Typography>
              <Typography variant="caption" sx={{ display: "block" }}>
                {pA.ua}
              </Typography>
            </Box>
          );
        })}
      </Box> */}
    </Box>
  );
};

const RenderQuestion: React.FC<{
  question: Question;
  possibleAnswers: Answer[];
  chapterId: string;
  onUpdate: (newQuestionObject: { question: Question; possibleAnswers: Answer[] }) => void;
  onRemove: () => void;
}> = ({ question, chapterId, possibleAnswers: inputPossibleAnswers }) => {
  const [questionEn, setQuestionEn] = useState(question.en);
  const [questionUa, setQuestionUa] = useState(question.ua);
  const [isLoading, setIsLoading] = useState(false);
  const [isExists, setIsExists] = useState(false);
  const [possibleAnswers, setPossibleAnswers] = useState(inputPossibleAnswers);

  const { createAudio } = useCreateAudio();

  useMountedWaitAsyncEffect(
    async () => {
      const { data: existingSentence } = await supabaseClient
        .from("sentences")
        .select()
        .eq("en", question.en.trim())
        .maybeSingle();

      if (existingSentence) setIsExists(true);
    },
    [, questionEn, questionUa],
    2000,
  );

  if (!questionEn) return null;

  return (
    <Box mb={isExists ? 1 : 4} position="relative">
      {!isExists && (
        <Box position="absolute" left={-50} top={0}>
          <IconButton
            sx={{ color: "red" }}
            onClick={() => {
              setQuestionEn("");
            }}
          >
            <DeleteIcon />
          </IconButton>
          {isLoading && <Loader />}
          <IconButton
            disabled={isLoading}
            sx={{
              top: 50,
              left: 0,
              color: "green",
              position: "absolute",
            }}
            // loading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true);

                const { data: existingSentence } = await supabaseClient
                  .from("sentences")
                  .select()
                  .eq("en", questionEn.trim())
                  .maybeSingle();
                console.log("🚀 ~ existingSentence:", existingSentence);

                if (existingSentence) {
                  console.error("Sentence exists");
                  // setIsExists(true);
                  setIsLoading(false);
                  return;
                }

                const { data: lastId } = await supabaseClient
                  .from("sentences")
                  .select("id")
                  .order("id", { ascending: false })
                  .limit(1)
                  .single();
                console.log("🚀 ~ lastId:", lastId);

                if (!lastId) throw new Error("Last is is not found");

                const audio = await createAudio(questionEn);
                console.log("🚀 ~ audio:", audio);
                if (!audio) throw new Error("Audio not found");

                const newSentence = {
                  en: questionEn.trim(),
                  ua: questionUa.trim(),
                  id: lastId.id + 1,
                  en_audio_url: audio,
                  ua_audio_url: "",
                  answers: possibleAnswers,
                  // clip_id: chapterId,
                  video_id: chapterId,
                  is_question: true,
                } as Sentence;
                console.log("🚀 ~ newSentence:", newSentence);
                console.log("🚀 ~ chapterId:", chapterId);

                await supabaseClient.from("sentences").insert(newSentence);
                await supabaseClient.from("user-sentences").insert({
                  id: `19c7ac10-110d-43e3-ad86-5e425aef49a5-${questionEn.trim()}`,
                  user: "19c7ac10-110d-43e3-ad86-5e425aef49a5",
                  en: questionEn.trim(),
                  repeat: addSeconds(new Date(), 2).toISOString(),
                });
                setIsExists(true);
              } catch (err: any) {
                console.log("🚀 ~ err:", err.message);
              }

              setIsLoading(false);
            }}
          >
            <UploadIcon />
          </IconButton>
        </Box>
      )}
      <TextField
        value={questionEn}
        multiline
        size="small"
        InputProps={{
          sx: {
            fontWeight: 700,
            color: isExists ? "green" : "black",
          },
        }}
        fullWidth
        onChange={(event) => {
          setQuestionEn(event.target.value);
        }}
      />
      {!isExists && (
        <TextField
          value={questionUa}
          multiline
          size="small"
          fullWidth
          onChange={(event) => {
            setQuestionUa(event.target.value);
          }}
        />
      )}
      {!isExists && (
        <Box pl={10}>
          {possibleAnswers.map((pA, index) => {
            return (
              <RenderPossibleAnswer
                key={pA.en}
                en={pA.en}
                ua={pA.ua}
                onRemove={() => {
                  const newPossibleAnswers = possibleAnswers.filter(
                    (a, iIndex) => iIndex !== index,
                  );
                  setPossibleAnswers(newPossibleAnswers);
                }}
                onUpdate={(en, ua) => {
                  console.log("on update", en, pA.en);
                  setPossibleAnswers(
                    possibleAnswers.map((a, prevIndex) => {
                      if (index === prevIndex) return { en, ua };
                      return a;
                    }),
                  );
                }}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
};

const AiQuestionAnswer: React.FC<{
  chapterText: string;
  chapterId: string;
  isVerified: null | boolean;
}> = ({ chapterText, chapterId, isVerified }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [sentencesIds, setSentencesIds] = useState<number[]>([]);
  const { createAudio } = useCreateAudio();
  const [questions, setQuestions] = useState<{ question: Question; possibleAnswers: Answer[] }[]>(
    [],
  );
  const [tquestions, setTQuestions] = useState<{ question: Question; possibleAnswers: Answer[] }[]>(
    [],
  );
  const [newWords, setNewWords] = useState<string[]>([]);

  useEffect(() => {
    if (tquestions.length) {
      setQuestions(tquestions);
      setTQuestions([]);
    }
  }, [tquestions]);

  if (isVerified) return null;

  return (
    <HStack>
      <Button
        variant="contained"
        color="info"
        onClick={() => {
          navigator.clipboard.writeText(`
          I have a clip transcription from some video.
          I learn English by learning small video clips.

          Analyze the clip text and give me as many as possible question and possible 
          answers to the those questions to train my English.
          Questions about the clip and about the vocabulary. I want at least 10+ sentences but would be better as many as possible.

          All sentences must be natural and casual.
          Use punctuation correctly. Each sentence must end with some punctuation.
          Questions level: A1 - B1

          Give me the response in JSON format. (Important!!! Don't put double quotes into string because it will broke the JSON!!! Use single quotes inside the string instead of double ones!!! Each string must be in double quotes!!!!!!!!!!!! because it is a JSON!!!!!)

          Here as example of the response:
          [
            {
              "question": {
                en: "An english question",
                ua: "A ukrainian translation of en question"
              },
              "possibleAnswers": [
                {
                  en: "An english possible answer",
                  ua: "A ukrainian translation of en answer"
                },
                ...
                // 2-3 ones at least
              ]
            },
            ....
          ]

          Here is a video clip text: ${chapterText}
  `);
        }}
      >
        Copy text for GEMINI: AI questions/answers
      </Button>
      <Button
        variant="contained"
        color="error"
        onClick={async () => {
          await supabaseClient
            .from("english_verses")
            .update({
              verified: true,
            })
            .eq("id", chapterId);
        }}
      >
        Mark as verified
      </Button>
      {Boolean(questions.length) && (
        <Button
          variant="contained"
          color="info"
          onClick={async () => {
            const wordsText = questions.map(({ question, possibleAnswers }) => {
              return [question.en, ...possibleAnswers.map((a) => a.en)].join(", ");
            });

            const { words: returnWords, newWords } = await checkNewWords(wordsText);
            console.log("🚀 ~ newWords:", newWords);
            setNewWords(newWords.filter((w) => !skipWords.includes(w)));
          }}
        >
          Check new words
        </Button>
      )}
      {!!newWords.length && <CopyNewWordsForGemini words={newWords} />}
      <Box width="100%" pl={7}>
        {questions.map(({ question, possibleAnswers }, index) => {
          return (
            <RenderQuestion
              key={index}
              chapterId={chapterId}
              question={question}
              possibleAnswers={possibleAnswers}
              onRemove={() => {
                setQuestions(
                  questions.filter(({ question: q }) => {
                    return q.en !== question.en;
                  }),
                );
              }}
              onUpdate={(newQuestions) => {
                setQuestions(
                  questions.map(({ question: q, possibleAnswers: pA }, qIndex) => {
                    if (index === qIndex) return newQuestions;
                    return { question: q, possibleAnswers: pA };
                  }),
                );
              }}
            />
          );
        })}
      </Box>

      {sentencesIds.join(", ")}
      {/* <LoadingButton
        loading={isLoading}
        loadingPosition="start"
        variant="contained"
        onClick={async () => {
          try {
            setIsLoading(true);

            const { data: existingSentences } = await supabase
              .from("sentences")
              .select()
              .in(
                "en",
                questions.map((q) => q.question.en.trim()),
              );

            const ids = existingSentences?.map((s) => s.id) || [];
            setSentencesIds(ids);
          } catch (err: any) {
            console.log("🚀 ~ err:", err.message);
          }
          setIsLoading(false);
        }}
      >
        Get sentences ids
      </LoadingButton> */}

      <Box width="100%">
        <IconButton
          color="error"
          onClick={() => {
            setQuestions([]);
          }}
        >
          <RemoveIcon />
        </IconButton>
        <TextField
          value={JSON.stringify(tquestions, null, 2)}
          fullWidth
          placeholder="questions"
          sx={{
            height: 100,
            overflow: "auto",
          }}
          multiline
          onChange={(event) => {
            console.log("🚀 ~ event:", event);
            try {
              setTQuestions(
                JSON.parse(event.target.value.replace("```json", "").replace("```", "")).map(
                  ({ question, possibleAnswers }: any) => {
                    const uniquePossibleAnswers = possibleAnswers.reduce((acm: any, curr: any) => {
                      return {
                        ...acm,
                        [curr.en]: curr,
                      };
                    }, {});

                    console.log(
                      "uniquePossibleAnswers",
                      Object.values(uniquePossibleAnswers).map((i: any) => i.en),
                    );

                    return {
                      question,
                      possibleAnswers: Object.values(uniquePossibleAnswers),
                    };
                  },
                ),
              );
            } catch (err: any) {
              console.log("🚀 ~ err:", err.message);
            }
          }}
        />
      </Box>
    </HStack>
  );
};

export default AiQuestionAnswer;
