import { Box, Button, Link as MuiLink } from "@mui/material";
import ControllingStudentPage from "../../Unknown/Root/ControllingStudentPage";
import VStack from "../../Unknown/VStack";
import { useLessonsStore } from "../../../store/lessonsStore";
import supabaseClient from "../../../common/supabaseClient";
import { useEffect, useState } from "react";
import { RPCUser } from "../../../common/fetchCurrentUser";
import { EnglishStory, Word } from "../../../types/supabase";
import { Story } from "../../AdminCheckUserStories";
import LoadingButton from "@mui/lab/LoadingButton";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import Stories from "../../Grammar/Stories";
import isAdmin from "../../../common/isAdmin";
import HStack from "../../Unknown/HStack";
import CheckSentence from "../../Unknown/Root/CheckSentences";
import ItalicTitle from "../../Title/ItalicTitle";
import { Link } from "react-router-dom";
import PdfModal from "../../Unknown/PdfModal";
import MenuLayout from "../../Layout/MenuLayout";

// -- valmiki 19c7ac10-110d-43e3-ad86-5e425aef49a5
// -- dayanvita 86523831-4881-4b89-91b5-def185deba6e
// -- grammar-user 7dd6fd1b-a333-4fbf-98a7-245491416070
// -- readding-user 53ef5828-71ce-4fdb-bfa1-80d8c4ebd57b
// -- ira 592d3941-7376-4c08-83c7-a374b2da849c

const LoadVideos: React.FC = () => {
  console.log("LoadVideos");
  const studentUser = useLessonsStore((state) => state.studentUser);
  const videosOrderIds = useLessonsStore((state) => state.videosOrderIds);
  const setVideosOrderIds = useLessonsStore((state) => state.setVideosOrderIds);
  const studentWords = useLessonsStore((state) => state.studentWords);
  const setStudentWords = useLessonsStore((state) => state.setStudentWords);
  const stories = useLessonsStore((state) => state.stories);
  const setStories = useLessonsStore((state) => state.setStories);
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [loading10Videos, setLoading10Videos] = useState(false);
  const [loadingUserWords, setLoadingUserWords] = useState(false);

  const handleLoadVideos = async () => {
    if (!studentUser) {
      alert("No student found");
      return;
    }

    setLoadingVideos(true);

    const { error, data } = await supabaseClient.rpc("get_english_videos_order", {
      input_user: studentUser.uuid,
    });

    if (error) alert((error as any)?.message);

    if (data) {
      const { data: spUser } = await supabaseClient
        .from("users")
        .select("ignore_video_ids, prev_video_ids")
        .eq("id", studentUser.id)
        .single();
      console.log("🚀 ~ spUser:", spUser);

      const ignoreIds = [...(spUser?.ignore_video_ids || []), ...(spUser?.prev_video_ids || [])];
      console.log("🚀 ~ ignoreIds:", ignoreIds);

      setVideosOrderIds(data.filter((id: number) => !ignoreIds.includes(id)));
    }
    setLoadingVideos(false);
  };

  const handleLoadNextVideo = async () => {
    setLoading10Videos(true);
    const videoIdsToFind = videosOrderIds.slice(0, 3);

    const { data } = await supabaseClient.from("english_verses").select().in("id", videoIdsToFind);

    if (data) {
      setStories(
        data
          .map((v) => {
            const newWords = v.words.filter((w: string) => !studentWords.includes(w));

            return {
              ...v,
              new_words: newWords,
              new_words_count: newWords.length,
              word_count: v.words.length,
              story_id: v.id,
              id: v.verse,
            };
          })
          .sort((a, b) => a.words.length - b.words.length)
          .sort((a, b) => a.new_words_count - b.new_words_count),
      );
    }
    setLoading10Videos(false);
    setVideosOrderIds(videosOrderIds.slice(3));
  };

  const authUser = useLessonsStore((state) => state.authUser);

  useEffect(() => {
    let channel: any = null;
    if (isAdmin(authUser?.id)) {
      const channel = supabaseClient
        .channel("room11")
        .on(
          "postgres_changes",
          { event: "*", schema: "public", table: "english_verses" },
          async (payload: any) => {
            console.log("🚀 ~ payload:", payload);
            const newVideos = stories.map((v: any) => {
              console.log("v.story_id === payload.new?.id", v.story_id === payload.new?.id);
              if (v.story_id === payload.new?.id) {
                const newWords = v.words.filter((w: string) => !studentWords.includes(w));

                return {
                  ...payload.new,
                  new_words: newWords,
                  new_words_count: newWords.length,
                  word_count: payload.new.words.length,
                  story_id: payload.new.id,
                  id: payload.new.verse,
                };
              }
              return v;
            });
            setStories(newVideos);
          },
        )
        .subscribe();
    }
    return () => {
      if (channel) channel.unsubscribe();
    };
  }, [authUser, stories, studentWords]);

  const handleLoadUserWords = async () => {
    if (!studentUser) {
      alert("student not found");
      return;
    }

    setLoadingUserWords(true);

    const { data: userWords } = await supabaseClient
      .from("user-words")
      .select()
      .eq("user", studentUser.uuid);

    if (userWords) setStudentWords(userWords.map((w) => w.word));
    setLoadingUserWords(false);
  };

  return (
    <Box>
      <VStack>
        <LoadingButton
          loading={loadingVideos}
          color="error"
          variant="contained"
          onClick={handleLoadVideos}
        >
          Load videos
        </LoadingButton>
        {Boolean(videosOrderIds.length) && (
          <>
            <LoadingButton
              loading={loading10Videos}
              color="error"
              variant="contained"
              onClick={handleLoadNextVideo}
            >
              Load next video
            </LoadingButton>
          </>
        )}
        {studentWords.length === 0 && (
          <>
            <LoadingButton
              loading={loadingUserWords}
              color="error"
              variant="contained"
              onClick={handleLoadUserWords}
            >
              Load user words
            </LoadingButton>
          </>
        )}

        {stories.map((v) => (
          <Story
            key={v.id}
            story={v}
            lazyLoading
            endless
            onUpdateStories={() => {
              // setStories(stories.filter((q: any) => q.id != story.id));
            }}
          />
        ))}
      </VStack>
    </Box>
  );
};

const EnglishLessonsScreen: React.FC = () => {
  const activeVideoId = useLessonsStore((state) => state.activeVideoId);
  const [story, setStory] = useState<EnglishStory>();
  const [pdfStory, setPdfStory] = useState<EnglishStory>();
  console.log("🚀 ~ pdfStory:", pdfStory);
  const authUser = useLessonsStore((state) => state.authUser);
  const setActiveVideoId = useLessonsStore((state) => state.setActiveVideoId);

  useMountedWaitAsyncEffect(async () => {
    const { data } = await supabaseClient.from("admin_control").select().eq("id", 0).single();
    if (data) setActiveVideoId(data.active_video_id);
  }, []);

  const isTeacherLesson = useLessonsStore((state) => state.isTeacherLesson);
  console.log("🚀 ~ isTeacherLesson:", isTeacherLesson);
  // const studentUser = useLessonsStore((state) => state.studentUser);

  useMountedWaitAsyncEffect(async () => {
    if (!activeVideoId || !isTeacherLesson) return;
    // console.log('0---', studentUser)

    const { data } = await supabaseClient
      .from("english_verses")
      .select()
      .in("id", [activeVideoId])
      .maybeSingle();

    if (data && data.type !== "pdf") setStory(data);
    if (data && data.type === "pdf") setPdfStory(data);
  }, [activeVideoId, isTeacherLesson]);

  const englishLessons = useLessonsStore((state) => state.englishLessons);
  const setEnglishLessons = useLessonsStore((state) => state.setEnglishLessons);

  useMountedWaitAsyncEffect(async () => {
    if (!authUser) return;

    const { data } = await supabaseClient.from("user_lessons").select().eq("user", authUser.id);

    if (data) setEnglishLessons(data);
  }, [authUser]);

  if (!isAdmin(authUser?.id)) {
    return (
      <MenuLayout isFluid>
        <VStack p={5}>
          <ControllingStudentPage isNested />
          {pdfStory && <PdfModal story={pdfStory} />}
          {story && (
            <VStack>
              <Stories
                stories={[story]}
                buttonLabel="відео"
                buttonSize="small"
                withoutDrawer
                withoutLink
                // isReadingVideo
              />
            </VStack>
          )}

          {Boolean(englishLessons.length) && (
            <Box>
              <ItalicTitle size={30} text="Уроки" />
              <br />
              {englishLessons.map((lesson) => {
                return (
                  <MuiLink
                    key={lesson.id}
                    color="secondary.dark"
                    sx={{
                      // textDecoration: "none",
                      width: "100%",
                      fontSize: 22,
                    }}
                    component={Link}
                    display="inline-block"
                    // to={`/grammar/grammar-lesson/${lesson.number}/build-words-list`}
                    to={`/lessons/${lesson.date}`}
                  >
                    Урок за <strong>{lesson.date}</strong>
                  </MuiLink>
                );
              })}
            </Box>
          )}
        </VStack>
      </MenuLayout>
    );
  }

  return (
    <MenuLayout isFluid>
      <Box>
        <Box display="flex" p={5}>
          <Box width="35%" pr={5} height="calc(100vh - 50px)" overflow="auto">
            <Box>
              <ControllingStudentPage isNested />
            </Box>
          </Box>
          <Box width="30%" pr={5} height="calc(100vh - 50px)" overflow="auto">
            <CheckSentence />
          </Box>
          <Box width="35%" height="calc(100vh - 50px)" overflow="auto" py={5}>
            <LoadVideos />
          </Box>
        </Box>
      </Box>
    </MenuLayout>
  );
};

export default EnglishLessonsScreen;
