import supabaseClient from "../../../../../common/supabaseClient";
import { useLessonsStore } from "../../../../../store/lessonsStore";
import Checkbox from "../../../Checkbox";
import Typography from "../../../Typography";

/**
 *
 * if checked: allow to show popup over the other components
 *
 */
const StudentAccentPopupSwitch = () => {
  const isBoardTextAllowedByAdmin = useLessonsStore((state) => state.isBoardTextAllowedByAdmin);
  const setIsBoardTextAllowedByAdmin = useLessonsStore(
    (state) => state.setIsBoardTextAllowedByAdmin,
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsBoardTextAllowedByAdmin(event.target.checked);
  };

  return (
    <Typography sx={{ width: "100%" }}>
      Open accent popup
      <Checkbox checked={isBoardTextAllowedByAdmin} onChange={handleChange} />
    </Typography>
  );
};

export default StudentAccentPopupSwitch;
