import supabaseClient from "../../../common/supabaseClient";
import wait from "../../../common/wait";
import Button from "../../Unknown/Button";

/**
 *
 * get video pronunciation data from collins dictionary (max 1000 words) by clicking on the button
 * add youtube ids to words table in collins_pronunciation prop
 *
 */

async function fetchAndExtractVideos(url: string) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const htmlContent = await response.text();
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    const videos = Array.from(doc.querySelectorAll(".video")).map((video: any) => {
      if (!video) return null;
      const title = video.querySelector(".entry_title")?.textContent.trim();
      const id = video.querySelector(".youtube-video")?.getAttribute("data-embed");
      return { title, id };
    });

    return videos.filter(Boolean);
    // console.log(JSON.stringify(videos, null, 2));
  } catch (error) {
    console.error("Error fetching or parsing data:", error);
    return null;
  }
}

const CheckCollinsVideoPronunciation = () => {
  return (
    <Button
      variant="contained"
      onClick={async () => {
        const { data: wordsToCheck } = await supabaseClient
          .from("words")
          .select()
          .limit(1000)
          .is("collins_pronunciation", null);

        console.log("🚀 ~ wordsToCheck pronunciation:", wordsToCheck);

        if (!wordsToCheck) return;

        for (let i = 0; i < wordsToCheck.length; i++) {
          await wait(1000);
          const word = wordsToCheck[i];
          const videosIds = await fetchAndExtractVideos(
            `https://www.collinsdictionary.com/dictionary/english-word/${word.name}`,
          );

          console.log("🚀 ~ videosIds:", videosIds);

          await supabaseClient
            .from("words")
            .update({
              collins_pronunciation: videosIds || [],
            })
            .eq("name", word.name);
          // console.log("🚀 ~ cambridgeData:", cambridgeData);
        }
      }}
    >
      Check for Collins video pronunciation
    </Button>
  );
};

export default CheckCollinsVideoPronunciation;
