import { Typography } from "@mui/material";
import supabaseClient from "../../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../../store/lessonsStore";
import Box from "../../../Unknown/Box";
import { useMemo, useState } from "react";
import ItalicTitle from "../../../Title/ItalicTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import { format } from "date-fns";

export type Rule = {
  id: number;
  question: string;
  answer: string;
  lesson: number;
};
const getRules = (rules: Omit<Rule, "id">[]): Rule[] => {
  return rules.map((rule, index) => {
    return {
      id: index,
      ...rule,
    };
  });
};

function splitByQuotes(str: string) {
  const result = [];
  let currentWord = "";
  let beforeOrLastWords = "";
  let insideQuotes = false;

  for (let i = 0; i < str.length; i++) {
    const char = str[i];

    if (char === "'") {
      insideQuotes = !insideQuotes;
      // currentWord += char; // Add the quote to currentWord
      if (beforeOrLastWords) {
        result.push(beforeOrLastWords);
        beforeOrLastWords = "";
      }
    } else if (!insideQuotes) {
      beforeOrLastWords += char;
      if (currentWord) {
        result.push(currentWord); // Push the word to the result array
        currentWord = ""; // Reset currentWord
      }
    } else {
      currentWord += char; // Add the character to currentWord
    }
  }

  // Add the last word if it exists
  if (currentWord) {
    result.push(currentWord);
  }
  if (beforeOrLastWords) {
    result.push(beforeOrLastWords);
  }

  return result;
}

const highlight = (text: string, fontWeight?: number, color?: string) => {
  return splitByQuotes(text).map((part, index) => {
    if (index % 2 !== 0) {
      return (
        <ItalicTitle
          key={index}
          // fontWeight={fontWeight}
          text={part}
          color="green"
          size={18}
          isItalic={false}
          fontFamily="Roboto"
        />
      );
    }
    return (
      <ItalicTitle
        key={index}
        size={18}
        color={color || "black"}
        // fontWeight={fontWeight}
        isItalic={false}
        text={part}
      />
    );
  });
};

export const rules = getRules([]);

export type Lesson = {
  lesson: number;
  letters: string[];
  storiesIds: { id: number; title: string }[];
  words: string[];
  adultWords: string[];
  syllables: string[];
};

export const learnToRead: Record<string, string[][]> = {
  1: [
    ["bet", "heh", "ref", "let", "set"],
    ["test", "best", "felt", "fell", "less"],
    ["lab", "tab", "fat", "hat"],
    ["flat", "last", "mask", "task", "bask", "fast"],
    ["art", "far", "tar", "star"],
    ["tart", "part", "mart", "dart", "bark", "lark", "start"],
    ["fall", "ball", "tall", "hall"],
    ["short", "rash", "hash", "brash", "fresh", "flesh", "shark", "fork"],
    ["he", "be", "she"],
    ["lift", "filter", "sister"],
    ["market", "basket", "basketball"],
    ["book", "roof", "foot", "tool", "football"],
    ["Bill", "Bob"],
    ["darts", "tabs", "labs", "fats", "balls", "stall", "halls", "is"],
    ["he is tall", "she is tall", "it is short", "Bob is short", "Bill is short"],
    ["it is fresh", "test is short", "(моя) sister is short", "(моя) sister is tall"],
    ["is he tall?", "is she tall?", "is it short?", "is Bob short?", "is Bill short?"],
    ["is it fresh?", "is (цей) test short?", "is (моя) sister short?", "is (моя) sister tall?"],
  ],
};
// "ok",
// "rocket",
// "hello",

export const lessons: Record<string, Lesson> = {
  1: {
    lesson: 1,
    letters: ["i", "s", "b", "o", "t", "a", "l", "r", "h", "e", "k", "f"],
    // rules: getRules(),
    storiesIds: [
      {
        id: 970,
        title: "Is Bob short or tall?",
      },
    ],
    syllables: [],
    words: [
      "hello",
      "start",
      "far",
      "ball",
      "tall",
      "short",
      "fresh",
      "hat",
      "lift",
      "mask",
      "football",
      "basket",
      "basketball",
      "ok",
      "test",
      "filter",
      // "rocket",
      "market",
      "is",
      "Bob",
      "Bill",
      "sister",
      "he",
      "she",
      // "roll",
      // "task",
      // "tests",
      // "baseball",
      // "laser",
      // "base",
      // "hotel",
      // "hate",
      // "like",
      // "bottle",
      // "site",
      // "hobbies",
      // "filters",
      // "robot",
      // "tennis racket"
      // "space rocket"
    ],
    adultWords: [
      "trailer",
      "risk",
      "fit",
      "boss",
      "stress",
      "battle",
      "starter",
      "socket",
      "ticket",
      // "sir",
      // "risks",
    ],
  },
};

const CourseProgram: React.FC<{ rules: Rule[] }> = ({ rules: lessonRules }) => {
  const [loading, setLoading] = useState(false);
  const studentUser = useLessonsStore((state) => state.studentUser);
  const setStudentUser = useLessonsStore((state) => state.setStudentUser);
  // const userRules = useLessonsStore((state) => state.setStudentUser);

  const [studentRules, setStudentRules] = useState<string[]>([]);
  console.log("🚀 ~ studentRules:", studentRules);

  const getStudentRules = async () => {
    const [rule] = lessonRules;
    if (!studentUser) return;

    const { data: studentRules } = await supabaseClient
      .from("user_english_rules")
      .select()
      .in(
        "rule",
        lessonRules.map((r) => r.question),
      )
      .eq("user", studentUser.id);

    console.log("🚀 ~ studentRules:", studentRules);
    if (studentRules) setStudentRules(studentRules.map((r) => r.rule));
  };

  useMountedWaitAsyncEffect(async () => {
    await getStudentRules();
  }, []);

  return (
    <Box>
      {Boolean(lessonRules.length) && (
        <Box>
          <Typography>
            {" "}
            Lesson ({studentUser?.english_lesson}) rules ({lessonRules.length})
          </Typography>
          <Box>
            {lessonRules.map((r, index) => {
              return (
                <Box key={index} mb={4}>
                  {!studentRules.includes(r.question) && (
                    <LoadingButton
                      loading={loading}
                      variant="outlined"
                      onClick={async () => {
                        if (!studentUser) return;
                        setLoading(true);

                        const addNewUserRuleResult = await supabaseClient
                          .from("user_english_rules")
                          .insert({
                            id: `${studentUser.id}-${r.question}`,
                            repeat: new Date().toISOString(),
                            rule: r.question,
                            date: format(new Date(), "yyyy-MM-dd"),
                            user: studentUser.id,
                          });
                        console.log("🚀 ~ addNewUserRuleResult:", addNewUserRuleResult);
                        await getStudentRules();

                        setLoading(false);
                      }}
                    >
                      Add
                    </LoadingButton>
                  )}
                  <Typography
                    sx={{
                      display: "block",
                      borderLeft: !studentRules.includes(r.question) ? "none" : "3px green solid",
                      paddingLeft: !studentRules.includes(r.question) ? "0px" : "30px",
                    }}
                  >
                    {highlight(r.question)}
                  </Typography>
                  {!studentRules.includes(r.question) && (
                    <Typography
                      sx={{
                        pl: 4,
                        borderLeft: "3px solid",
                      }}
                    >
                      {highlight(r.answer, 400, "#3e2863")}
                    </Typography>
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CourseProgram;
