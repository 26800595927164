import DOMPurify from "dompurify";

const getTextFromEditorState = (editorState: string): string => {
  const intermediate = editorState.replace(/<\/?[^>]+(>|$)/g, " ");
  const clean = DOMPurify.sanitize(intermediate);

  return clean;
};

export default getTextFromEditorState;
