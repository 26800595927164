import React, { useState, useEffect, useCallback, memo, useMemo, useRef } from "react";
import cloneDeep from "lodash/cloneDeep";
import { Excalidraw } from "@excalidraw/excalidraw";
import supabaseClient from "../../../common/supabaseDrawingClient";
import { useLessonsStore } from "../../../store/lessonsStore";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { usePrevious } from "react-use";
import { Stroke } from "../../../store/utils/functions";
import useIsTeacherLesson from "./hooks/useIsTeacherLesson";
import isAdmin from "../../../common/isAdmin";
import deepClone from "lodash/cloneDeep";
import supabaseDrawingClient from "../../../common/supabaseDrawingClient";

function throttle<T extends (...args: any[]) => any>(
  func: T,
  delay: number,
): (...args: Parameters<T>) => void {
  let lastCall = 0;

  return (...args: Parameters<T>): void => {
    const now = Date.now();
    if (now - lastCall >= delay) {
      lastCall = now;
      func(...args);
    }
  };
}

const DrawingBoard = () => {
  // const [excalidrawAPI, setExcalidrawAPI] = useState<any>(null);
  const excalidrawAPI = useLessonsStore((state) => state.excalidrawAPI);
  const setExcalidrawAPI = useLessonsStore((state) => state.setExcalidrawAPI);
  const isTeacherLesson = useLessonsStore((state) => state.isTeacherLesson);
  const containerRef = useRef<HTMLDivElement>(null);

  const historyRef = useRef({
    undoStack: [],
    redoStack: [],
  });

  // Функція для збереження нового стану в історії
  const pushToHistory = useCallback(() => {
    if (!excalidrawAPI) return;
    const currentScene: any = excalidrawAPI.getSceneElementsIncludingDeleted();
    // Додаємо копію поточного стану
    if (typeof currentScene === "object") {
      historyRef.current.undoStack.push(JSON.parse(JSON.stringify(currentScene)) as never);
    }
    // При новій зміні скидаємо redoStack
    historyRef.current.redoStack = [];
  }, [excalidrawAPI]);

  // Функція undo
  const undo = useCallback(() => {
    if (!excalidrawAPI || historyRef.current.undoStack.length === 0) return;

    // Забираємо останній стан
    const previousState = historyRef.current.undoStack.pop();
    // Зберігаємо поточний стан для можливого redo
    const currentState: any = excalidrawAPI.getSceneElementsIncludingDeleted();
    if (typeof currentState === "object") {
      historyRef.current.redoStack.push(JSON.parse(JSON.stringify(currentState)) as never);
    }

    // Відновлюємо попередній стан
    excalidrawAPI.updateScene({ elements: previousState });
  }, [excalidrawAPI]);

  // Функція redo
  const redo = useCallback(() => {
    if (!excalidrawAPI || historyRef.current.redoStack.length === 0) return;

    const nextState = historyRef.current.redoStack.pop();
    const currentState: any = excalidrawAPI.getSceneElementsIncludingDeleted();
    if (typeof currentState === "object") {
      historyRef.current.undoStack.push(JSON.parse(JSON.stringify(currentState)) as never);
    }

    excalidrawAPI.updateScene({ elements: nextState });
  }, [excalidrawAPI]);

  const authUser = useLessonsStore((state) => state.authUser);
  const drawingRealtimeElements = useLessonsStore((state) => state.drawingRealtimeElements);
  const prevDrawingRealtimeElements = usePrevious(drawingRealtimeElements);

  const setDrawingRealtimeElements = useLessonsStore((state) => state.setDrawingRealtimeElements);
  // Save drawing data periodically via your custom async hook.
  useEffect(() => {
    console.log("drawingRealtimeElements", drawingRealtimeElements);
    if (drawingRealtimeElements.length !== prevDrawingRealtimeElements?.length && authUser) {
      (async () => {
        const { data: existingStrokes } = await supabaseClient
          .from("drawing_strokes")
          .select("id")
          .in(
            "id",
            drawingRealtimeElements.map((s) => s.id),
          );
        const existingRowStrokes = existingStrokes?.map((s) => s.id);
        console.log("🚀 ~ existingRowStrokes:", existingRowStrokes);

        const toInsert = drawingRealtimeElements
          .filter((s: any) => !existingRowStrokes?.includes(s.id))
          .map((s: any) => {
            if (s.type === "freedraw") {
              return {
                id: s.id,
                data: {
                  // ...s,
                  id: s.id,
                  x: s.x,
                  y: s.y,
                  height: s.height,
                  width: s.width,
                  strokeColor: s.strokeColor,
                  fillStyle: "solid",
                  strokeWidth: 2,
                  isDeleted: false,
                  lastCommittedPoint: s.lastCommittedPoint,
                  strokeStyle: "solid",
                  type: "freedraw",
                  points: s.points,
                  userId: s.userId || authUser.id,
                  angle: 0,
                  // backgroundColor: "transparent",
                  // roughness: 1,
                  // opacity: 100,
                  // groupIds: [],
                  // frameId: null,
                  // roundness: null,
                  // seed: 1288218484,
                  // version: 21,
                  // versionNonce: 718857548,
                  // boundElements: null,
                  // updated: 1739533589216,
                  // link: null,
                  // locked: false,
                  // pressures: [],
                  // simulatePressure: true,
                },
              };
            }

            return {
              id: s.id,
              data: {
                ...s,
                userId: s.userId || authUser.id,
              },
            };
          })
          .filter((s: any) => {
            if (isAdmin(authUser?.id)) return true;
            if (s.data.userId !== authUser?.id) return false;
            return true;
          });
        // console.log("🚀 ~ toInsert:", toInsert);

        if (toInsert.length > 0) {
          console.log("🚀 ~ toInsert:", toInsert);
          const { error, data } = await supabaseClient.from("drawing_strokes").insert(toInsert);
          console.log("🚀 ~ error:", error);
        }
      })();
    }
  }, [drawingRealtimeElements, prevDrawingRealtimeElements, authUser]);

  const throttledSaveData = useMemo(
    () =>
      throttle(async (changeProps: any) => {
        if (excalidrawAPI) {
          const deletedDrawingStrokes = excalidrawAPI
            .getSceneElementsIncludingDeleted()
            .filter(Boolean)
            .filter((s: any) => {
              if (s.isDeleted) {
                const existing = drawingRealtimeElements.find((e) => e.id === s.id);
                if (existing && !existing.isDeleted) return true;
              }
              return false;
            });

          console.log("🚀 ~ deletedDrawingStrokes:", deletedDrawingStrokes);
          if (deletedDrawingStrokes.length) {
            const result = await Promise.all(
              deletedDrawingStrokes.map((s: any) => {
                return supabaseClient
                  .from("drawing_strokes")
                  .update({
                    data: s,
                  })
                  .eq("id", s.id);
              }),
            );
            const { error } = result[0];

            if (!error) {
              const ids: string[] = deletedDrawingStrokes.map((s: any) => s.id);

              setDrawingRealtimeElements(
                drawingRealtimeElements.map((e) => {
                  if (ids.includes(e.id))
                    return {
                      ...e,
                      isDeleted: true,
                    };
                  return e;
                }),
              );
            }
          }

          const updatedDrawingStrokes = excalidrawAPI
            .getSceneElements()
            .filter(Boolean)
            .filter((s: any) => {
              const existingOne = drawingRealtimeElements.find((e) => e.id === s.id);
              if (
                JSON.stringify(existingOne) !== JSON.stringify(s) &&
                existingOne?.userId === authUser?.id
              ) {
                return true;
              }
              return false;
            });

          if (updatedDrawingStrokes.length) {
            await Promise.all(
              updatedDrawingStrokes.map((el: any) => {
                return supabaseDrawingClient
                  .from("drawing_strokes")
                  .update({
                    id: el.id,
                    data: { ...el, userId: el.userId || authUser?.id },
                  })
                  .eq("id", el.id);
              }),
            );

            setDrawingRealtimeElements(
              drawingRealtimeElements.map((el) => {
                const updatedOne = updatedDrawingStrokes.find((s: any) => el.id === s.id);
                if (updatedOne) {
                  return { ...updatedOne, userId: updatedOne.userId || authUser?.id };
                }
                return el;
              }),
            );
          }

          const newDrawingStrokes = excalidrawAPI
            .getSceneElementsIncludingDeleted()
            .filter(Boolean);

          const newStrokes = JSON.parse(
            JSON.stringify(
              Object.values(
                [
                  ...drawingRealtimeElements,
                  ...newDrawingStrokes.map((e: any) => ({
                    ...e,
                    userId: e.userId || authUser?.id,
                  })),
                ].reduce((acm, curr) => {
                  return {
                    ...acm,
                    [curr.id]: curr,
                  };
                }, {}),
              ),
            ),
          );

          if (newStrokes.length !== drawingRealtimeElements.length) {
            setDrawingRealtimeElements(newStrokes);
          }
        }
      }, 500),
    [excalidrawAPI, drawingRealtimeElements, setDrawingRealtimeElements],
  );

  useEffect(() => {
    if (excalidrawAPI) {
      // const visibleElements = excalidrawAPI
      //   .getSceneElementsIncludingDeleted()
      //   .map((s: any) => s.id);

      const appState = excalidrawAPI.getAppState();

      if (!appState.draggingElement && !appState.editingElement) {
        console.log("🚀 ~ visibleElements:", drawingRealtimeElements);
        const mutableNewStrokes = JSON.parse(JSON.stringify(drawingRealtimeElements));
        const sceneData = {
          elements: mutableNewStrokes,
          // appState: { ...excalidrawAPI.getAppState() },
        };
        console.log("🚀 ~ sceneData:", sceneData);
        if (excalidrawAPI.updateScene) {
          excalidrawAPI.updateScene(sceneData);
        }
      }
    }
  }, [drawingRealtimeElements, excalidrawAPI]);

  useEffect(() => {
    if (isTeacherLesson && !isAdmin(authUser?.id)) {
    }
  }, [authUser, isTeacherLesson]);

  const handleChange = useMemo(
    () =>
      throttle((changeProps: any) => {
        const appState = excalidrawAPI?.getAppState();

        // const newDrawingStrokes = excalidrawAPI.getSceneElementsIncludingDeleted().filter(Boolean);

        if (appState && !appState?.draggingElement && !appState?.editingElement) {
          pushToHistory();
          throttledSaveData(changeProps);
        }
      }, 500),
    [throttledSaveData],
  );

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleKeyDown = async (event: KeyboardEvent) => {
      // console.log(
      //   'event.metaKey && event.key.toLowerCase() === "z"',
      //   event.metaKey && event.key.toLowerCase() === "z",
      // );
      // console.log(
      //   'event.metaKey &&(event.key.toLowerCase() === "y" || (event.shiftKey && event.key.toLowerCase() === "z"))',
      //   event.metaKey &&
      //     (event.key.toLowerCase() === "y" || (event.shiftKey && event.key.toLowerCase() === "z")),
      // );
      // Redo: cmd+shift+z або cmd+y
      if (event.metaKey && event.shiftKey && event.key.toLowerCase() === "z") {
        event.preventDefault();
        redo();
      }
      // Undo: cmd+z
      else if (event.metaKey && event.key.toLowerCase() === "z") {
        event.preventDefault();
        // undo();

        const newElements = [...drawingRealtimeElements];
        const lastElement = newElements.pop();
        console.log("🚀 ~ lastElement:", lastElement);
        setDrawingRealtimeElements(newElements);
        excalidrawAPI.updateScene({ elements: newElements });

        if (lastElement) {
          const result = await supabaseDrawingClient
            .from("drawing_strokes")
            .delete()
            .eq("id", lastElement.id);
          console.log("🚀 ~ 089080988:", result);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [excalidrawAPI, drawingRealtimeElements]);

  useEffect(() => {
    if (excalidrawAPI) {
      excalidrawAPI.updateScene({
        appState: { viewBackgroundColor: "rgba(0, 0, 0, 0)" },
      });
    }
  }, [excalidrawAPI]);

  return (
    <div
      ref={containerRef}
      style={{ width: "100%", height: "100vh" }}
      tabIndex={0}
      onClick={(e) => e.currentTarget.focus()}
    >
      {/* Render Excalidraw only once initialDrawing is available */}
      <Excalidraw
        excalidrawAPI={(api: any) => setExcalidrawAPI(api)}
        viewModeEnabled={!isAdmin(authUser?.id)}
        onChange={handleChange}
        initialData={{
          appState: { viewBackgroundColor: "transparent" }, // Set any color
        }}
        UIOptions={{
          canvasActions: {
            changeViewBackgroundColor: false,
            clearCanvas: false,
            saveToActiveFile: false,
            // changeViewBackground: false, // Забороняє зміну фону
            // zoom: false, // Вимикає масштабування
            // pan: false, // Забороняє панорамування
          },
        }}
      />
      {/* {initialDrawing && (
      )} */}
    </div>
  );
};

export default memo(DrawingBoard);
