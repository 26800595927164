import GTranslateIcon from "@mui/icons-material/GTranslate";
import { useState } from "react";
import isAdmin from "../../../common/isAdmin";
import { useLessonsStore } from "../../../store/lessonsStore";
import Box from "../Box";
import PageTranslationWithInputText from "../PageTranslationWithInputText";

const AdminTranslateWithWoordhunter: React.FC<{ title: string }> = ({ title }) => {
  const authUser = useLessonsStore((state) => state.authUser);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  if (!isAdmin(authUser?.id)) return null;

  return (
    <Box
      sx={{
        cursor: "pointer",
      }}
      onClick={() => {
        setIsPopupOpen(true);
      }}
    >
      <PageTranslationWithInputText
        text={title}
        isPopupOpen={isPopupOpen}
        setIsPopupOpen={setIsPopupOpen}
      />
      <GTranslateIcon color="primary" />
    </Box>
  );
};

export default AdminTranslateWithWoordhunter;
